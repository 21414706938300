import React, { useEffect, useRef, useState } from "react";
import { eye, funnerBigLogo, insertLink, mailIcon } from "../../../libs/images";
import applicationConfig from "../../../config";
import { UseFetchGetAnonymos } from "../../../hooks/fetchFunctions";
import { phoneRegex } from "../../../libs/regexes";
import { applicationType } from "../../../applicationType";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import {
  ErrorOutlineOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useLocalization } from "../../Context/LocalizationContext";

function Login({
  isPowerLinkPage,
  loginData,
  handleLoginParams,
  handleAuthPopupWindow,
  handleLoginStep,
  openChatAuthControl,
  handleToggleChatFromAuth,
  token,
  handleSetNotif,
  loginProcessing,
  loginErrors,
  handleLoginErrors,
}) {
  const { currentLocale, languageDirection } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.auth.login,
    [currentLocale],
  );
  const loginDataRef = useRef(loginData);

  const [showPassword, setShowPassword] = useState(false);

  function toggleShowPassword(e) {
    e.stopPropagation();
    const prevValue = showPassword;
    setShowPassword(!prevValue);
  }

  const submitOnPressEnter = (e) => {
    if (e.keyCode === 13) {
      handleLoginStep(
        loginDataRef.current.phone,
        loginDataRef.current.password,
      );
    }
  };

  async function handleSendPassToWhatsapp(phone) {
    if (!phoneRegex.test(phone))
      return handleSetNotif("Invalid phone", "error");
    try {
      const res = await UseFetchGetAnonymos(
        "/api/auth/resetFunnerUserPassword",
        {
          phoneNumber: phone,
        },
        null,
        undefined,
        "messages",
      );

      if (res?.data?.result) {
        handleSetNotif("Sent!", "success");
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", submitOnPressEnter);
    return () => {
      document.removeEventListener("keydown", submitOnPressEnter);
    };
  }, []);

  useEffect(() => {
    loginDataRef.current = loginData;
  }, [loginData]);

  return (
    <Box
      sx={{
        pt: 2,
        px: 2,
        textAlign: "center",
        width: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        spacing={7.5}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              maxWidth: 308,
              width: "100%",
              mx: "auto",
            }}
          >
            <img
              src={funnerBigLogo}
              alt=""
              style={{
                display: "block",
                height: "auto",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              maxWidth: 210,
              mx: "auto",
              mb: 1.25,
            }}
          >
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <TextField
                  label={localization.phoneTitle}
                  value={loginData.phone}
                  onChange={(e) => {
                    const value = e.target.value?.trim();
                    handleLoginParams({ phone: value });
                    if (!/^\d+$/.test(value) && value !== "")
                      handleLoginErrors({
                        phone: localization.errors.onlyDigits,
                      });
                    else {
                      handleLoginErrors({
                        phone: null,
                      });
                    }
                  }}
                  fullWidth
                  type="tel"
                  size="small"
                  InputProps={{
                    sx: {
                      fontSize: 14,
                      "& input": {
                        py: 1.5,
                        textAlign:
                          languageDirection === "rtl" ? "right" : "left",
                      },
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(loginErrors.phone)}
                  helperText={
                    loginErrors.phone ? (
                      <Grid
                        container
                        spacing={0.5}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                      >
                        <Grid item>
                          <Box>{loginErrors.phone}</Box>
                        </Grid>
                        <Grid item>
                          <ErrorOutlineOutlined
                            sx={{
                              width: 12,
                              height: 12,
                              display: "block",
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : null
                  }
                  sx={{
                    direction: "rtl",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={localization.passwordTitle}
                  value={loginData.password}
                  onChange={(e) => {
                    handleLoginParams({ password: e.target.value?.trim() });
                    handleLoginErrors({
                      password: null,
                    });
                  }}
                  type={`${showPassword ? "text" : "password"}`}
                  fullWidth
                  size="small"
                  InputProps={{
                    sx: {
                      fontSize: 14,
                      "& input": {
                        py: 1.5,
                        textAlign:
                          languageDirection === "rtl" ? "right" : "left",
                      },
                    },
                    endAdornment:
                      languageDirection === "rtl" ? (
                        <InputAdornment
                          position={
                            languageDirection === "rtl" ? "end" : "start"
                          }
                        >
                          <IconButton size="small" onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    startAdornment:
                      languageDirection === "ltr" ? (
                        <InputAdornment
                          position={
                            languageDirection === "rtl" ? "end" : "start"
                          }
                        >
                          <IconButton size="small" onClick={toggleShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    direction: "rtl",
                  }}
                  error={Boolean(loginErrors.password)}
                  helperText={
                    loginErrors.password ? (
                      <Grid
                        container
                        spacing={0.5}
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                      >
                        <Grid item>
                          <Box>{loginErrors.password}</Box>
                        </Grid>
                        <Grid item>
                          <ErrorOutlineOutlined
                            sx={{
                              width: 12,
                              height: 12,
                              display: "block",
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : null
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={1.25} justifyContent={"center"}>
            <Grid item xs={12}>
              <Box sx={{ pt: 2 }}>
                <Button
                  variant="contained"
                  endIcon={
                    loginProcessing && (
                      <CircularProgress color="customWhite" size={20} />
                    )
                  }
                  onClick={() =>
                    handleLoginStep(loginData.phone, loginData.password)
                  }
                  disabled={loginData.password === ""}
                  sx={{
                    minWidth: 170,
                    pointerEvents: loginProcessing ? "none" : "initial",
                    opacity: loginProcessing ? 0.75 : 1,

                    "& .MuiButton-endIcon": {
                      mr: -3.5,
                    },
                  }}
                >
                  {localization.login}
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                  maxWidth: 400,
                  whiteSpace: "nowrap",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  rowGap: 10,
                  columnGap: 2,
                  pt: 1.25,
                  fontSize: 14,
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    color: "secondary.main",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  onClick={() => handleAuthPopupWindow("resetPassword")}
                >
                  {localization.resetPasswordLink}
                </Box>
                {applicationType === "web" && (
                  <Box
                    sx={{
                      cursor: "pointer",
                      color: "secondary.main",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    onClick={() => handleAuthPopupWindow("register")}
                  >
                    {localization.registerLink}
                  </Box>
                )}
                {/* <div
              className="text"
              onClick={() => handleAuthPopupWindow("register")}
            >
              {localization.registerLink}
            </div> */}
                {/* {applicationType === "web" && (
              <div
                className="text"
                onClick={() => handleSendPassToWhatsapp(loginData.phone)}
              >
                {localization.sendPassToWhatsapp}
              </div>
            )} */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className="get-token-popup__links-footer">
        <div className="get-token-popup__links-footer-col">
          <p className="get-token-popup__links-footer-text">
            {currentLocale.auth.footer.version} {applicationConfig.version}
          </p>
          <p className="get-token-popup__links-footer-text">
            {currentLocale.auth.footer.text}
          </p>
        </div>
        <div className="get-token-popup__links-footer-col">
          <a
            href={`https://${currentLocale.auth.footer.funnerSupportLink}`}
            className="get-token-popup__link-footer-link get-token-popup__icon-link"
            target="_blank"
            rel="noreferrer"
          >
            {currentLocale.auth.footer.funnerSupportLink}
            <img src={insertLink} alt="" />
          </a>
          <a
            href={`mailto:${currentLocale.auth.funnerSupportEmail}`}
            className="get-token-popup__link-footer-link get-token-popup__icon-link"
          >
            {currentLocale.auth.footer.funnerSupportEmail}
            <img src={mailIcon} alt="" />
          </a>
        </div>
      </div>
    </Box>
    // <div className="get-token-popup__window get-token-popup__window_6">
    //   <div className="get-token-popup__big-logo">
    //     <img src={funnerBigLogo} alt="" />
    //   </div>

    //   <div className="get-token-popup__form form-get-token-popup">
    //     <div className="form-get-token-popup__block form-get-token-popup__block_border-top">
    //       <label
    //         htmlFor="inp-9"
    //         className="form-get-token-popup__input-wrapper"
    //       >
    //         <span className="input-title">{localization.phoneTitle}</span>
    //         <input
    //           type="text"
    //           id="inp-9"
    //           value={loginData.phone}
    //           onChange={(e) =>
    //             handleLoginParams({ phone: e.target.value?.trim() })
    //           }
    //           className="form-get-token-popup__input"
    //         />
    //       </label>

    //       <label
    //         htmlFor="inp-10"
    //         className="form-get-token-popup__input-wrapper"
    //       >
    //         <span className="input-title">{localization.passwordTitle}</span>
    //         <input
    //           value={loginData.password}
    //           onChange={(e) =>
    //             handleLoginParams({ password: e.target.value?.trim() })
    //           }
    //           type={`${showPassword ? "text" : "password"}`}
    //           id="inp-10"
    //           className="form-get-token-popup__input"
    //           style={{ textAlign: "left" }}
    //         />
    //         <button
    //           type="button"
    //           onClick={toggleShowPassword}
    //           className={`show-hide-password-btn ${
    //             showPassword ? "green" : ""
    //           }`}
    //         >
    //           <img src={eye} alt="" />
    //         </button>
    //       </label>
    //     </div>
    //     <div className="form-get-token-popup__button-wrap">
    //       {/* <button
    //         onClick={() => handleLoginStep(loginData.phone, loginData.password)}
    //         type="button"
    //         disabled={loginData.password === ""}
    //         className={`get-token-popup__main-btn popup__blue-btn ${
    //           loginData.password === "" ? "disabled" : ""
    //         }`}
    //       >
    //         התחבר
    //       </button> */}
    //       <Button
    //         variant="contained"
    //         endIcon={
    //           loginProcessing && (
    //             // true && (
    //             <CircularProgress color="customWhite" size={24} />
    //           )
    //         }
    //         onClick={() => handleLoginStep(loginData.phone, loginData.password)}
    //         disabled={loginData.password === ""}
    //         sx={{
    //           minWidth: 170,
    //           pointerEvents: loginProcessing ? "none" : "initial",
    //           opacity: loginProcessing ? 0.75 : 1,

    //           "& .MuiButton-endIcon": {
    //             mr: -4,
    //           },
    //         }}
    //       >
    //         התחבר
    //       </Button>
    //       <div
    //         className="btns-wrapper"
    //         style={{
    //           maxWidth: 400,
    //           whiteSpace: "nowrap",
    //           flexWrap: "wrap",
    //           justifyContent: "center",
    //           rowGap: 10,
    //         }}
    //       >
    //         <div
    //           className="text"
    //           onClick={() => handleAuthPopupWindow("resetPassword")}
    //         >
    //           {localization.resetPasswordLink}
    //         </div>
    //         {/* <div
    //           className="text"
    //           onClick={() => handleAuthPopupWindow("register")}
    //         >
    //           {localization.registerLink}
    //         </div> */}
    //         {/* {applicationType === "web" && (
    //           <div
    //             className="text"
    //             onClick={() => handleSendPassToWhatsapp(loginData.phone)}
    //           >
    //             {localization.sendPassToWhatsapp}
    //           </div>
    //         )} */}
    //       </div>
    //     </div>
    //   </div>

    //   <div className="get-token-popup__links-footer">
    //     <div className="get-token-popup__links-footer-col">
    //       <p className="get-token-popup__links-footer-text">
    //         {currentLocale.auth.footer.version}{" "}
    //         {applicationConfig.version}
    //       </p>
    //       <p className="get-token-popup__links-footer-text">
    //         {currentLocale.auth.footer.text}
    //       </p>
    //     </div>
    //     <div className="get-token-popup__links-footer-col">
    //       <a
    //         href={`https://${currentLocale.auth.footer.funnerSupportLink}`}
    //         className="get-token-popup__link-footer-link get-token-popup__icon-link"
    //       >
    //         {currentLocale.auth.footer.funnerSupportLink}
    //         <img src={insertLink} alt="" />
    //       </a>
    //       <a
    //         href={`mailto:${currentLocale.auth.funnerSupportEmail}`}
    //         className="get-token-popup__link-footer-link get-token-popup__icon-link"
    //       >
    //         {currentLocale.auth.footer.funnerSupportEmail}
    //         <img src={mailIcon} alt="" />
    //       </a>
    //     </div>
    //   </div>
    // </div>
  );
}

export default Login;
