import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Box,
  DialogActions,
  Button,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TemplatesMenu from "../Templates/TemplatesMenu";
import { phoneRegex } from "../../libs/regexes";
import { UseFetchPost } from "../../hooks/fetchFunctions";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useLocalization } from "../Context/LocalizationContext";

function SendTemplateForm({
  open,
  onClose,
  templatesList,
  userData,
  handleSetNotif,
  fetchWhatsappTemplates,
  chatUsersGlobalRef,
  getCompanyChatUsers,
  getCompanyChatUsersGlobal,
}) {
  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.chat.chatHeader.sendTemplateForm,
    [currentLocale],
  );

  const [sendTemplatePhone, setSendTemplatePhone] = useState("");
  const [sendTemplateName, setSendTemplateName] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateSending, setTemplateSending] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);
  const [phoneError, setPhoneError] = useState(null);

  const selectedTemplateRef = useRef(null);
  const dialogRef = useRef(null);
  const wrongTextNotifRef = useRef(null);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setSendTemplatePhone("");
      setSendTemplateName("");
      setIsNewContact(false);
      handleSelectedTemplate(null);
    }, 300);
  };

  const handlePhoneNumber = (value) => {
    // if (value !== "" && !/^\d+$/.test(value) && !wrongTextNotifRef.current) {
    //   wrongTextNotifRef.current = enqueueSnackbar("ניתן להזין ספרות בלבד", {
    //     autoHideDuration: 2000,
    //     variant: "error",
    //     onClose: () => {
    //       wrongTextNotifRef.current = null;
    //     },
    //   });
    // }
    if (value !== "" && !/^\d+$/.test(value))
      setPhoneError("ניתן להזין ספרות בלבד");
    else setPhoneError(null);
    setSendTemplatePhone(value.replaceAll(/\D/g, ""));
  };

  const handleSelectedTemplate = (template) => {
    setSelectedTemplate(template);
    selectedTemplateRef.current = template;
  };

  const checkTemplateValid = () => {
    const inputs = dialogRef.current.querySelectorAll(
      ".funner-templates-menu .form-get-token-popup__input",
    );
    const mediaInput = dialogRef.current.querySelector(
      ".funner-templates-menu .preview-content-manage-templates__header-media input",
    );
    const container = dialogRef.current.querySelector(
      ".funner-templates-menu .template-chat-sidebar",
    );
    let allInputsValid = true;
    for (let i = 0; i < inputs.length; i++) {
      if (!inputs[i].value || inputs[i].value === "") {
        allInputsValid = false;
        break;
      }
    }
    if (mediaInput) {
      allInputsValid = allInputsValid && mediaInput.files.length > 0;
    }
    if (allInputsValid) {
      if (container.classList.contains("error"))
        container.classList.remove("error");
      return true;
    } else {
      if (!container.classList.contains("error"))
        container.classList.add("error");
      return false;
    }
  };
  const onSubmitForm = async () => {
    try {
      const isValid = checkTemplateValid();
      if (isValid) {
        // setTemplateSending(true);
        const sendTemplateObject = { ...selectedTemplateRef.current };

        let targetPhone = phoneRegex.test(sendTemplatePhone)
          ? "972" + sendTemplatePhone.slice(1)
          : sendTemplatePhone;

        handleClose();
        const infoAlert = enqueueSnackbar(localization.sending, {
          variant: "info",
        });

        const existingUser = chatUsersGlobalRef.current.notMyChats?.find(
          (user) => user.UserDisplayPhoneNumber === targetPhone,
        );

        if (!existingUser) {
          const createUserParams = {
            firstName: isNewContact ? sendTemplateName.split(" ")[0] : "",
            lastName:
              isNewContact && sendTemplateName.split(" ").length > 1
                ? sendTemplateName.split(" ")[1]
                : "",
            phone: targetPhone,
            isFireberry: false,
            // phoneType: selectedPhoneNumberType,
          };

          const result = await UseFetchPost(
            "/api/services/CreateChatUser",
            createUserParams,
            "config",
          );
          if (result?.data?.Id) {
            await getCompanyChatUsers();
            await getCompanyChatUsersGlobal();
          } else {
            return handleSetNotif(localization.userCreateErr, "error");
          }
        }

        sendTemplateObject.to = targetPhone;
        sendTemplateObject.frontTemplateObj = JSON.stringify({
          ...JSON.parse(sendTemplateObject.frontTemplateObj),
          to: targetPhone,
        });

        console.log("send template phone: ", sendTemplatePhone);
        console.log("selected template: ", sendTemplateObject);

        const res = await UseFetchPost(
          "/api/whatsapp/SendWhatsappTemplateMessage",
          sendTemplateObject,
          "messages",
        );
        if (res?.data?.result) {
          closeSnackbar(infoAlert);
          handleSetNotif(localization.sendSuccess, "success");
          // handleClose();
        } else {
          closeSnackbar(infoAlert);
          handleSetNotif(localization.sendErr, "error");
        }
      }
    } catch (error) {
      console.log("submit send template error: ", error);
    } finally {
      // setTemplateSending(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      ref={dialogRef}
    >
      <DialogTitle>{localization.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} flexDirection={"column"} sx={{ pt: 1 }}>
          <Grid item sx={{ width: "100%" }}>
            <TextField
              value={sendTemplatePhone}
              onChange={(e) => handlePhoneNumber(e.target.value)}
              type="text"
              fullWidth
              label={localization.phoneLabel}
              variant="outlined"
              error={Boolean(phoneError)}
              helperText={phoneError}
            />
          </Grid>
          {isNewContact && (
            <Grid item sx={{ width: "100%" }}>
              <TextField
                value={sendTemplateName}
                onChange={(e) => setSendTemplateName(e.target.value)}
                type="text"
                fullWidth
                label={localization.nameLabel}
                variant="outlined"
              />
            </Grid>
          )}
          <Grid item sx={{ width: "100%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNewContact}
                  onChange={(e) => setIsNewContact(e.target.checked)}
                />
              }
              label={localization.addNewContact}
            />
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Typography
              fontSize={12}
              color="text.secondary"
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              {localization.nameNote}
            </Typography>
          </Grid>
          <Grid item flexGrow={1} sx={{ width: "100%" }}>
            <Box sx={{ pt: 2, maxHeight: "48.5vh", overflow: "auto" }}>
              <TemplatesMenu
                templatesLoading={false}
                templatesList={templatesList.templates}
                whatsappTemplatesList={templatesList.whatsappTemplates}
                languages={templatesList.languages}
                closeAccordion={() => {}}
                chatUserName={""}
                currentContact={""}
                globalID={userData.Udid}
                handleSetNotif={handleSetNotif}
                setTextareaValue={() => {}}
                textareaRef={null}
                resetTextArea={() => {}}
                templatesWithSend={false}
                noSendHandleFunc={(templatesObj) => {
                  handleSelectedTemplate(templatesObj.sendObject);
                }}
                fetchWhatsappTemplates={fetchWhatsappTemplates}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleClose}>
          {localization.close}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!sendTemplatePhone.length || !selectedTemplate}
          onClick={() => onSubmitForm()}
          sx={{
            position: "relative",
            pointerEvents: templateSending ? "none" : "all",
            opacity: templateSending ? 0.75 : 1,
          }}
          endIcon={
            templateSending ? (
              <CircularProgress size={14} color="customWhite" />
            ) : null
          }
        >
          {localization.send}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendTemplateForm;
