import React, { useState } from "react";
import { useLocalization } from "../Context/LocalizationContext";

function Header({ openChatAuthControl, handleToggleChatFromAuth, token }) {
  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.auth.header,
    [currentLocale],
  );

  return (
    <div className="get-token-popup__form-links-header">
      {token && (
        <div className="get-token-popup__switch">
          <label className="switch-container about-tab__switch-container">
            <span>{localization.activeStatusLabel}</span>
            <div className="switch">
              <input
                onChange={handleToggleChatFromAuth}
                type="checkbox"
                className="inside-page"
                checked={openChatAuthControl}
              />
              <span className="slider round"></span>
            </div>
          </label>
        </div>
      )}
    </div>
  );
}

export default Header;
