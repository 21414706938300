import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import React, { Fragment, memo, useMemo } from "react";
import {
  powerlinkIcon,
  priorityDocLogo,
  priorityLetterLogo,
} from "../../../libs/images";
import { LinkOutlined } from "@mui/icons-material";
import { IsJsonString } from "../../../libs/helpers";
import { UseFetchGet } from "../../../hooks/fetchFunctions";
import { useLocalization } from "../../Context/LocalizationContext";

const availableUserParams = [
  {
    value: "UserDisplayPhoneNumber",
    label: "Phone number",
  },
  {
    value: "FullName",
    label: "User Full Name",
  },
];

const ChatInputAreaIcons = memo(
  ({
    businessInfo,
    toggleAccordion,
    selectedChatUser,
    sendToPlatformsStatus,
    isActive,
    chatType,
    handleUpload,
    toggleSmilesPanelBottom,
    setChooseFromGallery,
    handlePrioritySendDocsWindow,
  }) => {
    const { currentLocale } = useLocalization();
    const localization = React.useMemo(
      () => currentLocale.chat.inputArea,
      [currentLocale],
    );
    const [userMetaBtn, setUserMetaBtn] = React.useState(false);
    const [sendDocsMenuVisible, setSendDocsMenuVisible] = React.useState(false);
    const [uploadMenuAnchor, setUploadMenuAnchor] = React.useState(null);

    const handleSendDocsMenuVisible = (state) => {
      setSendDocsMenuVisible(state);
    };

    const sendDocsMenuRef = React.useRef(null);
    const sendDocsBtnRef = React.useRef(null);
    const uploadLabelRef = React.useRef(null);

    //* Upload
    const handleUploadMenu = (e) => {
      if (uploadLabelRef.current.contains(e.target)) return;
      setUploadMenuAnchor(e.currentTarget);
    };
    const uploadFromMediaGallery = () => {
      setUploadMenuAnchor(null);
      setChooseFromGallery("image");
    };
    const uploadFromComputer = () => {
      setUploadMenuAnchor(null);
      uploadLabelRef.current.click();
    };

    //! On user changed
    function onUserChangedActions() {
      if (!selectedChatUser?.UserDisplayPhoneNumber) return;
      getChatUserMetaByPhoneNumber(selectedChatUser.UserDisplayPhoneNumber);
    }
    async function getChatUserMetaByPhoneNumber(phone) {
      try {
        const res = await UseFetchGet(
          `/api/services/getChatUserMetaByPhoneNumber/${phone}`,
          undefined,
          null,
          false,
          "history",
        );

        if (res?.status === 200) {
          setUserMetaBtn(res.data);
        } else {
          setUserMetaBtn(null);
        }
      } catch (error) {
        console.error("getChatUserMetaByPhoneNumber error: ", error);
        setUserMetaBtn(null);
      }
    }

    React.useEffect(() => {
      onUserChangedActions();
    }, [selectedChatUser?.UserDisplayPhoneNumber]);

    // const additionalLink = useMemo(() => {
    //   let additionalLink = {
    //     enabled: false,
    //     url: "",
    //     icon: null,
    //   };
    //   if (IsJsonString(businessInfo?.CompanyPlatforms)) {
    //     const platformsObject = JSON.parse(businessInfo.CompanyPlatforms);
    //     const additionalLinkObj = platformsObject.additionalLink;
    //     if (additionalLinkObj) {
    //       additionalLink = platformsObject.additionalLink;
    //     }
    //   }
    //   return additionalLink;
    // }, [businessInfo?.CompanyPlatforms]);
    const additionalLinks = useMemo(() => {
      let links = [
        {
          id: 0,
          order: 1,
          enabled: false,
          url: "https://example.com/",
          icon: null,
          type: "link",
          name: "פתיחת קריאה",
        },
        {
          id: 1,
          order: 2,
          enabled: false,
          url: "https://example.com/{phoneNumber}",
          icon: null,
          type: "linkParams",
          name: "ליד חדש",
        },
        {
          id: 2,
          order: 3,
          enabled: false,
          url: null,
          icon: null,
          type: "popup",
          name: "יצירת משימה",
          action: {
            html: "",
            js: "",
          },
        },
      ];

      if (IsJsonString(businessInfo?.CompanyPlatforms)) {
        const platformsObject = JSON.parse(businessInfo.CompanyPlatforms);

        if (platformsObject.additionalLinks) {
          // setAdditionalLinks(platformsObject.additionalLinks);
          const mergedLinks = links.map((link) => {
            const updatedLink = platformsObject.additionalLinks.find(
              (updated) => updated.type === link.type,
            );
            return updatedLink ? { ...link, ...updatedLink } : link;
          });
          links = mergedLinks;
        } else if (platformsObject.additionalLink) {
          // setAdditionalLink({
          //   ...platformsObject.additionalLink,
          // });
          links = links.map((link) =>
            link.type === "linkParams"
              ? { ...link, ...platformsObject.additionalLink }
              : link,
          );
        }
      }
      return links;
    }, [businessInfo?.CompanyPlatforms]);
    const [additionalLinksPopup, setAdditionalLinksPopup] = React.useState({
      show: false,
      link: null,
    });
    const handleAdditionalLinksPopup = (params) =>
      setAdditionalLinksPopup((prevState) => ({ ...prevState, ...params }));
    const closeAdditionalLinksPopup = () => {
      handleAdditionalLinksPopup({ show: false });
      setTimeout(() => {
        handleAdditionalLinksPopup({ link: null });
      }, 300);
    };

    const onOutsideElementsClick = (e) => {
      const targetClicked = e.target;

      if (
        sendDocsMenuRef.current &&
        sendDocsBtnRef.current &&
        !sendDocsMenuRef.current.contains(targetClicked) &&
        !sendDocsBtnRef.current.contains(targetClicked)
      ) {
        handleSendDocsMenuVisible(false);
      }
    };
    React.useEffect(() => {
      document.addEventListener("click", onOutsideElementsClick);
      return () => {
        document.removeEventListener("click", onOutsideElementsClick);
      };
    }, []);

    return (
      <div
        className="chat-input-actions__bottom-right-buttons"
        id="funner-global__buttons-interaction-wrapper"
      >
        <Divider
          orientation="vertical"
          flexItem={true}
          sx={{ mr: 0.5, my: 0.5 }}
        />
        <Tooltip
          title={localization.iconButtons.templates.tooltip}
          placement="top"
        >
          <span>
            <IconButton
              onClick={() => toggleAccordion()}
              size={"small"}
              color="primary"
              sx={{
                width: 34,
                height: 34,
                transition: "color 0.15s ease 0s",
              }}
              disabled={sendToPlatformsStatus === "note" || !selectedChatUser}
            >
              <SvgIcon sx={{ width: 20, height: 20 }}>
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5.83335 2.49992H3.33335V4.99992H1.66669V0.833252H5.83335V2.49992ZM18.3334 4.99992V0.833252H14.1667V2.49992H16.6667V4.99992H18.3334ZM5.83335 17.4999H3.33335V14.9999H1.66669V19.1666H5.83335V17.4999ZM16.6667 14.9999V17.4999H14.1667V19.1666H18.3334V14.9999H16.6667ZM14.1667 4.99992H5.83335V14.9999H14.1667V4.99992ZM15.8334 14.9999C15.8334 15.9166 15.0834 16.6666 14.1667 16.6666H5.83335C4.91669 16.6666 4.16669 15.9166 4.16669 14.9999V4.99992C4.16669 4.08325 4.91669 3.33325 5.83335 3.33325H14.1667C15.0834 3.33325 15.8334 4.08325 15.8334 4.99992V14.9999ZM12.5 6.66658H7.50002V8.33325H12.5V6.66658ZM12.5 9.16658H7.50002V10.8333H12.5V9.16658ZM12.5 11.6666H7.50002V13.3333H12.5V11.6666Z" />
                </svg>
              </SvgIcon>
            </IconButton>
          </span>
        </Tooltip>

        <Divider
          orientation="vertical"
          flexItem={true}
          sx={{ mx: 0.5, my: 0.5 }}
        />
        <IconButton
          // component="label"
          // htmlFor={`${chatType}-contained-button-file`}
          size="small"
          color="primary"
          sx={{
            width: 34,
            height: 34,
            transition: "color 0.15s ease 0s",
          }}
          disabled={
            // sendToPlatformsStatus === "note" ||
            // (sendToPlatformsStatus !== "note" && !isActive)
            !isActive
          }
          onClick={handleUploadMenu}
        >
          <SvgIcon sx={{ width: 24, height: 24 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="currentColor"
            >
              <g id="FilePresentOutlined">
                <path
                  id="Vector"
                  d="M15.1666 2.16675H6.49998C5.30831 2.16675 4.33331 3.14175 4.33331 4.33341V21.6667C4.33331 22.8584 5.30831 23.8334 6.49998 23.8334H19.5C20.6916 23.8334 21.6666 22.8584 21.6666 21.6667V8.66675L15.1666 2.16675ZM19.5 21.6667H6.49998V4.33341H15.1666V8.66675H19.5V21.6667ZM13 18.4167C11.8083 18.4167 10.8333 17.4417 10.8333 16.2501V10.2917C10.8333 9.98841 11.0716 9.75008 11.375 9.75008C11.6783 9.75008 11.9166 9.98841 11.9166 10.2917V16.2501H14.0833V10.2917C14.0833 8.79675 12.87 7.58342 11.375 7.58342C9.87998 7.58342 8.66665 8.79675 8.66665 10.2917V16.2501C8.66665 18.6442 10.6058 20.5834 13 20.5834C15.3941 20.5834 17.3333 18.6442 17.3333 16.2501V11.9167H15.1666V16.2501C15.1666 17.4417 14.1916 18.4167 13 18.4167Z"
                />
              </g>
            </svg>
          </SvgIcon>
          <label
            htmlFor={`${chatType}-contained-button-file`}
            style={{ display: "none" }}
            ref={uploadLabelRef}
          >
            <input
              type="file"
              id={`${chatType}-contained-button-file`}
              multiple={true}
              onChange={(e) => {
                handleUpload(e.target.files);
                e.target.value = null;
              }}
            />
          </label>
        </IconButton>
        <Divider
          orientation="vertical"
          flexItem={true}
          sx={{ mx: 0.5, my: 0.5 }}
        />
        <IconButton
          onClick={toggleSmilesPanelBottom}
          size={"small"}
          color="primary"
          sx={{
            width: 34,
            height: 34,
            transition: "color 0.15s ease 0s",
          }}
          disabled={!isActive}
        >
          <SvgIcon sx={{ width: 24, height: 24 }}>
            <svg
              viewBox="0 0 26 26"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.9892 2.16675C7.00919 2.16675 2.16669 7.02008 2.16669 13.0001C2.16669 18.9801 7.00919 23.8334 12.9892 23.8334C18.98 23.8334 23.8334 18.9801 23.8334 13.0001C23.8334 7.02008 18.98 2.16675 12.9892 2.16675ZM13 21.6667C8.21169 21.6667 4.33335 17.7884 4.33335 13.0001C4.33335 8.21175 8.21169 4.33341 13 4.33341C17.7884 4.33341 21.6667 8.21175 21.6667 13.0001C21.6667 17.7884 17.7884 21.6667 13 21.6667Z" />
              <path d="M13 19.5001C15.47 19.5001 17.5717 17.7017 18.4167 15.1667H7.58335C8.42835 17.7017 10.53 19.5001 13 19.5001Z" />
              <path d="M9.20835 11.9167C10.1058 11.9167 10.8334 11.1892 10.8334 10.2917C10.8334 9.39429 10.1058 8.66675 9.20835 8.66675C8.31089 8.66675 7.58335 9.39429 7.58335 10.2917C7.58335 11.1892 8.31089 11.9167 9.20835 11.9167Z" />
              <path d="M16.7917 11.9167C17.6891 11.9167 18.4167 11.1892 18.4167 10.2917C18.4167 9.39429 17.6891 8.66675 16.7917 8.66675C15.8942 8.66675 15.1667 9.39429 15.1667 10.2917C15.1667 11.1892 15.8942 11.9167 16.7917 11.9167Z" />
            </svg>
          </SvgIcon>
        </IconButton>
        <Divider
          orientation="vertical"
          flexItem={true}
          sx={{ mx: 0.5, my: 0.5 }}
        />
        {businessInfo?.CompanyType === "fireberry" && (
          <Fragment>
            <IconButton
              size={"small"}
              color="primary"
              sx={{ width: 34, height: 34 }}
              LinkComponent={"a"}
              href={
                selectedChatUser?.fireberryAccountLink
                  ? selectedChatUser.fireberryAccountLink
                  : `https://app.powerlink.co.il/app/views/${
                      businessInfo?.LinkToObjectNum === "second" ? "2" : "1"
                    }?q=${selectedChatUser?.UserDisplayPhoneNumber?.replace(
                      "972",
                      "0",
                    )}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {/* {selectedChatUser?.fireberryAccountLink &&
                          !selectedChatUser.fireberryAccountLink.includes(
                            "?q=",
                          ) ? (
                            <img
                              src={powerlinkIcon}
                              style={{ width: 22, height: 22 }}
                              alt=""
                            />
                          ) : (
                            <img
                              src={powerlinkIconOrange}
                              style={{ width: 22, height: 22 }}
                              alt=""
                            />
                          )} */}
              <img
                src={powerlinkIcon}
                style={{ width: 22, height: 22 }}
                alt=""
              />
            </IconButton>
            <Divider
              orientation="vertical"
              flexItem={true}
              sx={{ ml: 0.5, my: 0.5 }}
            />
          </Fragment>
        )}

        {businessInfo.CompanyType === "priority" && (
          // <div
          //   className={`chat-input-actions__docs-menu-wrapper material-dropdown material-dropdown-common ${
          //     sendDocsMenuVisible ? "active" : ""
          //   }`}
          //   ref={sendDocsMenuRef}
          // >
          <React.Fragment>
            <IconButton
              // onClick={() => handleSendDocsMenuVisible(!sendDocsMenuVisible)}
              onClick={() => {
                handlePrioritySendDocsWindow(true);
                handleSendDocsMenuVisible(false);
              }}
              size={"small"}
              color="primary"
              ref={sendDocsBtnRef}
              sx={{
                width: 34,
                height: 34,
                transition: "color 0.15s ease 0s",
                p: 0.75,
              }}
            >
              {/* <img
                src={priorityDocLogo}
                alt=""
                style={{
                  width: 20,
                  height: 20,
                  objectFit: "contain",
                }}
              /> */}
              <img
                src={priorityLetterLogo}
                alt=""
                style={{
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
              />
            </IconButton>
            <Divider
              orientation="vertical"
              flexItem={true}
              sx={{ mx: 0.5, my: 0.5 }}
            />

            {/* <ul
              className="material-dropdown__body"
              style={{
                bottom: "calc(100% + 3px)",
                top: "auto",
                width: 220,
                right: "-24px",
                borderRadius: 4,
                boxShadow:
                  "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)",
              }}
            >
              {businessInfo?.CompanyType === "priority" && (
                <li
                  style={{
                    display: "flex",
                    alignItems: "center, gap: 9px",
                  }}
                  className="_priority-send-docs"
                  onClick={() => {
                    handlePrioritySendDocsWindow(true);
                    handleSendDocsMenuVisible(false);
                  }}
                >
                  <div
                    className="funner-icon"
                    style={{
                      width: 23,
                      flex: "0 0 23px",
                      height: 20,
                      marginLeft: 8,
                    }}
                  >
                    <img src={priorityLetterLogo} alt="" />
                  </div>
                  מסמך מפריוריטי
                </li>
              )}
            </ul> */}
            {/* </div> */}
          </React.Fragment>
        )}

        {/* {additionalLink.enabled && additionalLink.url && (
          <Fragment>
            <IconButton
              size={"small"}
              color="primary"
              sx={{ width: 34, height: 34 }}
              LinkComponent={"a"}
              href={additionalLink.url.replaceAll(
                "{phoneNumber}",
                selectedChatUser?.UserDisplayPhoneNumber,
              )}
              target="_blank"
              rel="noreferrer"
            >
              {additionalLink.icon ? (
                <img
                  style={{
                    width: 24,
                    height: 24,
                    borderRadius: "50%",
                    display: "block",
                  }}
                  src={additionalLink.icon}
                  alt=""
                />
              ) : (
                <LinkOutlined />
              )}
            </IconButton>
            <Divider
              orientation="vertical"
              flexItem={true}
              sx={{ ml: 0.5, my: 0.5 }}
            />
          </Fragment>
        )} */}
        {additionalLinks
          .filter((additionalLink) => additionalLink.enabled)
          .sort((a, b) => (a.order || 0) - (b.order || 0))
          .map((additionalLink) => (
            <Fragment key={additionalLink.id}>
              <IconButton
                size={"small"}
                color="primary"
                sx={{ width: 34, height: 34 }}
                LinkComponent={additionalLink.type !== "popup" ? "a" : "button"}
                href={
                  additionalLink.type !== "popup"
                    ? additionalLink.url.replace(
                        /{(\w+)}/g,
                        (match, param) =>
                          selectedChatUser?.[
                            param === "phoneNumber"
                              ? "UserDisplayPhoneNumber"
                              : param
                          ] || match,
                      )
                    : null
                }
                target={additionalLink.type !== "popup" ? "_blank" : null}
                rel={additionalLink.type !== "popup" ? "noreferrer" : null}
                onClick={() =>
                  additionalLink.type === "popup"
                    ? handleAdditionalLinksPopup({
                        show: true,
                        link: additionalLink,
                      })
                    : null
                }
              >
                {additionalLink.icon ? (
                  <img
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                      display: "block",
                    }}
                    src={additionalLink.icon}
                    alt=""
                  />
                ) : (
                  <LinkOutlined />
                )}
              </IconButton>
              <Divider
                orientation="vertical"
                flexItem={true}
                sx={{ ml: 0.5, my: 0.5 }}
              />
            </Fragment>
          ))}

        {userMetaBtn && (
          <Fragment>
            <Tooltip title={userMetaBtn.Title} placement="top">
              <IconButton
                size={"small"}
                color="primary"
                sx={{ width: 34, height: 34 }}
                LinkComponent={"a"}
                href={userMetaBtn.Link}
                target="_blank"
                rel="noreferrer"
              >
                {userMetaBtn.IconLink ? (
                  <img
                    style={{
                      width: 24,
                      height: 24,
                      borderRadius: "50%",
                      display: "block",
                    }}
                    src={userMetaBtn.IconLink}
                    alt=""
                  />
                ) : (
                  <LinkOutlined />
                )}
              </IconButton>
            </Tooltip>

            <Divider
              orientation="vertical"
              flexItem={true}
              sx={{ ml: 0.5, my: 0.5 }}
            />
          </Fragment>
        )}

        <Menu
          open={Boolean(uploadMenuAnchor)}
          anchorEl={uploadMenuAnchor}
          onClose={() => setUploadMenuAnchor(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={() => uploadFromMediaGallery()}>
            Via gallery
          </MenuItem>
          <MenuItem onClick={() => uploadFromComputer()}>
            Upload from computer
          </MenuItem>
        </Menu>

        {additionalLinks.some(
          (link) => link.type === "popup" && link.enabled,
        ) && (
          <Dialog
            open={additionalLinksPopup.show}
            onClose={() => closeAdditionalLinksPopup()}
          >
            <DialogContent>
              <PopupContentRenderer
                htmlContent={additionalLinksPopup.link?.action?.html || ""}
                jsContent={additionalLinksPopup.link?.action?.js || ""}
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={closeAdditionalLinksPopup}>
                CLose
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  },
);

const PopupContentRenderer = ({ htmlContent, jsContent }) => {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const existingScripts = containerRef.current?.querySelectorAll("script");
    if (existingScripts) {
      existingScripts.forEach((script) => script.remove());
    }

    const scriptElement = document.createElement("script");
    scriptElement.textContent = jsContent;

    containerRef.current?.appendChild(scriptElement);
  }, [jsContent]);

  return (
    <div ref={containerRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};

export default ChatInputAreaIcons;
