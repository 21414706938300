import React, { useEffect, useMemo, useRef, useState } from "react";
import { applicationType } from "../../applicationType";
import {
  UseFetch,
  UseFetchGet,
  UseFetchPost,
} from "../../hooks/fetchFunctions";
import ChatUserCard from "./ChatUserCard";
import Chat from "../Chat/Chat";
import AddUsersChat from "./AddUsersChat";
import FunnerHeader from "./FunnerHeader";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Fab,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  Zoom,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
} from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import { phoneRegex } from "../../libs/regexes";
import { IsJsonString, syncTimeout } from "../../libs/helpers";
import { enqueueSnackbar } from "notistack";
import ChatUsersList from "./ChatUsersList";
import { useLocalization } from "../Context/LocalizationContext";

let reconnectListenerSet = false;
const FunnerGlobalMessanger = React.memo(
  React.forwardRef(
    (
      {
        iframeMode,
        openUnreadMessagesPopup,
        chatUsersFunner,
        chatUsersFunnerRef,
        chatUsersGlobal,
        chatUsersGlobalRef,
        getContactsQueryParams,
        getContactsQueryParamsRef,
        handleGetContactsParams,
        getCompanyChatUsers,
        isContactsLoading,
        getCompanyChatUsersGlobal,
        selectedFunnerChatUser,
        selectedFunnerChatUserRef,
        handleSelectedFunnerChatUser,
        updateChatUser,
        isNavOpen,
        userData,
        userDataRef,
        userConfig,
        userConfigRef,
        businessInfo,
        funnerUsers,
        chatUsersLabelsList,
        updateUserConfig,
        updateUserConfigWithObject,
        handleSetNotif,
        toggleUnreadMessagesPopup,
        templatesList,
        templatesLoading,
        fetchWhatsappTemplates,
        connection,
        isConnection,
        setTotalUnredMessages,
        setTotalUnreadChats,
        handleUsersFireberrySettingsWindow,
        setSelectedUsersFireberrySync,
        handleUsersPrioritySettingsWindow,
        setSelectedUsersPrioritySync,
        tabActiveRef,
        handlePrioritySendDocsWindow,
        sendUploadPriority,
        resetSendUploadPriority,
        chatUsersMessagesRef,
        userInSettingsCreationState,
        handleChatUserMessages,
        disableChatGoBack,
        clearChatUsersMessages,
        getChatMissingHistory,
        isMyUsersChats,
        setMyUsersChat,
        goToFavouriteMessageState,
        goToFavouriteMessageStateRef,
        handleGoToFavouriteMessageState,
      },
      ref,
    ) => {
      const { currentLocale } = useLocalization();

      let isChatOpenLocalStorage = JSON.parse(
        localStorage.getItem("globalFunnerMessangerOpenStatus"),
      );
      const theme = useTheme();
      const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
      // const matchesBigMobile = useMediaQuery(theme.breakpoints.down(768));
      // const matchesMobile = useMediaQuery(theme.breakpoints.down(480));

      //! User info
      // const [selectedUserId, setSelectedUserId] = useState(null);
      // const [selectedChatUser, setSelectedChatUser] = useState(null);

      //! Config
      // const [isMyUsersChats, setMyUsersChat] = useState(false);
      // const [chatUsers, setChatUsers] = useState({
      //   myChats: [],
      //   notMyChats: [],
      // });
      React.useImperativeHandle(ref, () => ({
        loadFunnerChatUsers: (
          stopLoadingAfterFirstRequest = false,
          loadingFunc,
          type = null,
        ) =>
          loadFunnerChatUsers(stopLoadingAfterFirstRequest, loadingFunc, type),
        setContactsLoaded: (value) => setContactsLoaded(value),
      }));
      const [unreadChatUsers, setUnreadChatUsers] = useState([]);
      const [selectedChatUsersList, setSelectedChatUsersList] = useState([]);
      const [isContactsLoaded, setContactsLoaded] = useState(false);

      const {
        chatUsersCustomizationSettings,
        chatUsersCustomizationAdditionalSettings,
      } = useMemo(() => {
        const platformsObject = IsJsonString(businessInfo.CompanyPlatforms)
          ? JSON.parse(businessInfo.CompanyPlatforms)
          : businessInfo.CompanyPlatforms;

        return {
          chatUsersCustomizationSettings:
            platformsObject?.chatUsersCustomizationSettings || [],
          chatUsersCustomizationAdditionalSettings:
            platformsObject?.chatUsersCustomizationAdditionalSettings || {},
        };
      }, [businessInfo?.CompanyPlatforms]);

      //! Filters and hints
      // const [getContactsQueryParams, setGetContactsQueryParams] = useState({
      //   pageOffset: 1,
      //   searchQuery: "",
      //   label: "",
      //   open: "all",
      //   isOnlyUnread: false,
      //   assigneeFilter: [],
      // });
      const [unreadHint, setUnreadHint] = useState({
        enabled: false,
        count: 0,
      });

      //! Views
      const [showUsersCreateChat, setShowUsersCreateChat] = useState(false);
      const [chatLoading, setChatLoading] = useState(false);
      const [additionalMenuAnchorEl, setAdditionalMenuAnchorEl] = useState({
        anchor: null,
        chatUser: null,
      });
      const [deleteUsersConfirmation, setDeleteUsersConfirmation] = useState({
        show: false,
        users: [],
      });

      //! Refs
      const chatUsersListRef = useRef(null);
      // const getContactsQueryParamsRef = useRef({
      //   pageOffset: 1,
      //   searchQuery: "",
      //   label: "",
      //   open: "all",
      //   isOnlyUnread: false,
      //   assigneeFilter: [],
      // });
      // const chatUsersRef = useRef({
      //   myChats: [],
      //   notMyChats: [],
      // });
      const chatUserContextRef = useRef(null);
      const unreadHintRef = useRef({
        enabled: false,
        count: 0,
      });
      const selectedChatUsersListRef = useRef([]);
      const lastSelectedUserIdRef = useRef(null);
      const textareaRef = useRef(null);
      const funnerHeaderRef = useRef(null);

      //! Functions
      //* Filters
      // const handleGetContactsParams = (params) => {
      //   getContactsQueryParamsRef.current = {
      //     ...getContactsQueryParamsRef.current,
      //     ...params,
      //   };
      //   setGetContactsQueryParams((prevObj) => ({ ...prevObj, ...params }));
      // };

      const showAvatar = React.useMemo(
        () => userConfig.isAvatarActive ?? true,
        [userConfig.isAvatarActive],
      );

      //* Chat Users List
      const goToTopTrigger = useScrollTrigger({
        target: chatUsersListRef.current ? chatUsersListRef.current : undefined,
        disableHysteresis: true,
        threshold: 100,
      });
      const scrollChatUsersTop = React.useCallback(() => {
        chatUsersListRef.current.scrollTop = 0;
      }, []);
      const updateUnreadChatsIndicator = React.useCallback(() => {
        let unreadCount = 0;
        unreadChatUsers.forEach((chatUser) => {
          if (
            chatUsersListRef.current &&
            100 * chatUser.index < chatUsersListRef.current.scrollTop
          )
            unreadCount += chatUser.user.UnreadMessagesNumber;
        });

        if (unreadCount !== unreadHintRef.current.count) {
          //   console.log("set new unread hint state");
          setUnreadHint({
            enabled: unreadCount > 0,
            count: unreadCount,
          });
          unreadHintRef.current = {
            enabled: unreadCount > 0,
            count: unreadCount,
          };
        }
      }, [unreadChatUsers]);

      const handleLoadContactsOnScroll = React.useCallback(
        async ({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
          const scrollContainer = chatUsersListRef.current;
          const isAtBottom =
            scrollOffset + scrollContainer.clientHeight >=
            scrollContainer.scrollHeight - 1500;

          updateUnreadChatsIndicator();
          // handleChatUsersGoTopBtn();

          if (
            !isContactsLoading &&
            isAtBottom &&
            chatUsersFunner.notMyChats.length /
              getContactsQueryParamsRef.current.pageOffset >=
              30
          ) {
            console.log("query on scroll");

            handleGetContactsParams({
              pageOffset: getContactsQueryParamsRef.current.pageOffset + 1,
            });
            funnerHeaderRef.current.setIsLoading(true);
            await getCompanyChatUsers();
            funnerHeaderRef.current.setIsLoading(false);
          }
        },
        [
          chatUsersFunner.notMyChats.length,
          getCompanyChatUsers,
          getContactsQueryParamsRef,
          handleGetContactsParams,
          isContactsLoading,
          updateUnreadChatsIndicator,
        ],
      );

      const handleAdditionalMenuClick = React.useCallback((e, chatUser) => {
        setAdditionalMenuAnchorEl({
          anchor: e.currentTarget,
          chatUser: chatUser,
        });
      }, []);
      const handleAdditionalMenuClose = () => {
        setAdditionalMenuAnchorEl({
          anchor: null,
          chatUser: null,
        });
      };
      async function makeChatUserUnread(user) {
        handleAdditionalMenuClose();

        let lastIncomingMessageId = null;
        if (user.incomingLastMessageId) {
          lastIncomingMessageId = user.incomingLastMessageId;
        } else {
          const whatsappPhoneNumber = user.UserDisplayPhoneNumber;
          const res = await UseFetchGet(
            `/api/whatsapp/getChatUserMsgHistoryActvStatus/${whatsappPhoneNumber}/1/50`,
            undefined,
            null,
            undefined,
            "history",
          );

          if (res.data) {
            const lastIncomingMessage = res.data.messages.find(
              (msg) => msg.Type === "incoming",
            );
            lastIncomingMessageId =
              lastIncomingMessage && lastIncomingMessage.Id;
          }
        }

        if (lastIncomingMessageId) {
          try {
            const params = {
              bulkActionName: "setUnread",
              chatUsersList: [user.UserDisplayPhoneNumber],
              messageList: [Number(lastIncomingMessageId)],
            };

            const res = await UseFetchPost(
              "/api/services/bulkChatUserActions",
              params,
              "history",
            );

            // const params = {
            //   id: Number(lastIncomingMessageId),
            // };
            // const response = await UseFetchPost(
            //   "/api/whatsapp/SetMessageUnread",
            //   params,
            //   "history",
            // );
            // console.log("unreadMessage response:", response);
            // updateChatUser(user.UserDisplayPhoneNumber, {
            //   UnreadMessagesNumber: user.UnreadMessagesNumber + 1,
            // });

            // setTotalUnredMessages((prevValue) => prevValue + 1);
            // setTotalUnreadChats((prevValue) => prevValue + 1);
          } catch (error) {
            console.log("Failed unread message", error);
          }
        }
      }
      const makeSingleChatUserRead = async (id) => {
        try {
          handleAdditionalMenuClose();
          // const params = {
          //   bulkActionName: "setRead",
          //   chatUsersList: [id],
          // };
          // const res = await UseFetchPost(
          //   "/api/services/bulkChatUserActions",
          //   params,
          //   "history",
          // );

          const res = await UseFetch(
            "put",
            "/api/v1/contacts/setRead",
            {
              id: id,
            },
            "history",
          );
          console.log(res);
        } catch (error) {}
      };
      const openFireberrySyncUser = (chatUser) => {
        handleAdditionalMenuClose();

        handleUsersFireberrySettingsWindow(true);
        setSelectedUsersFireberrySync([chatUser]);
      };
      const openPrioritySyncUser = async (chatUser) => {
        handleAdditionalMenuClose();

        handleUsersPrioritySettingsWindow(true);
        setSelectedUsersPrioritySync([chatUser]);
      };
      const setDontDisturbChatUserState = async (phoneNumber, state) => {
        try {
          handleAdditionalMenuClose();

          const res = await UseFetchPost(
            "/api/services/SetChatUserDontDisturb",
            {
              phone: phoneNumber,
              dontDisturb: state,
            },
            "history",
          );

          if (res?.data?.result) {
            enqueueSnackbar("Success", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(res?.data?.message || "", {
              variant: "error",
            });
          }
        } catch (error) {
          enqueueSnackbar("Some error occured, please try later", {
            variant: "error",
          });
        }
      };
      const handleAddUserToSelectedList = (user) => {
        setSelectedChatUsersList((prevList) => [...prevList, user]);
        selectedChatUsersListRef.current.push(user);
        lastSelectedUserIdRef.current = user.UserDisplayPhoneNumber;
      };
      const handleRemoveUserFromSelectedList = (user, source) => {
        setSelectedChatUsersList((prevList) =>
          prevList.filter((item) => item.Id !== user.Id),
        );
        selectedChatUsersListRef.current =
          selectedChatUsersListRef.current.filter(
            (item) => item.Id !== user.Id,
          );

        if (source === "ctrl") {
          lastSelectedUserIdRef.current =
            selectedChatUsersListRef.current[
              selectedChatUsersListRef.current.length - 1
            ]?.UserDisplayPhoneNumber || null;
        }
      };
      const handleSetSelectedUsersList = React.useCallback((list) => {
        setSelectedChatUsersList(list);
        selectedChatUsersListRef.current = list;
        lastSelectedUserIdRef.current = list.length
          ? list[list.length - 1].UserDisplayPhoneNumber
          : null;
      }, []);

      //* Chat functions
      const manageSelectedChatUsersWithKeys = (e) => {
        const keyPressedCode = e.keyCode;
        if (
          selectedChatUsersListRef.current.length > 0 &&
          e.shiftKey &&
          (keyPressedCode === 40 || keyPressedCode === 38)
        ) {
          console.log(e);
          const isUp = keyPressedCode === 38;
          const isDown = keyPressedCode === 40;
          const currentUsersList =
            chatUsersFunnerRef.current[
              isMyUsersChats ? "myChats" : "notMyChats"
            ];
          const lastSelectedUserIndex = currentUsersList.findIndex(
            (user) =>
              user.UserDisplayPhoneNumber === lastSelectedUserIdRef.current,
          );
          const selectedUsersIndexesInList =
            selectedChatUsersListRef.current.map((user) =>
              currentUsersList.findIndex((item) => item.Id === user.Id),
            );

          console.log("lastSelectedUserIndex: ", lastSelectedUserIndex);
          console.log(
            "selectedUsersIndexesInList: ",
            selectedUsersIndexesInList,
          );

          let nextUser;
          let nextUserIndex = -1;
          if (isDown) {
            console.log("arrow down + shift");
            const isFirstElement =
              selectedChatUsersListRef.current.length !== 1 &&
              selectedUsersIndexesInList.some(
                (itemIndex) => itemIndex > lastSelectedUserIndex,
              );
            console.log("isFirstElement: ", isFirstElement);
            nextUserIndex = isFirstElement
              ? lastSelectedUserIndex
              : lastSelectedUserIndex + 1;
            nextUser = currentUsersList[nextUserIndex];
          } else if (isUp) {
            console.log("arrow up + shift");
            const isLastElement =
              selectedChatUsersListRef.current.length !== 1 &&
              selectedUsersIndexesInList.some(
                (itemIndex) => itemIndex < lastSelectedUserIndex,
              );
            console.log("isLastElement: ", isLastElement);
            nextUserIndex = isLastElement
              ? lastSelectedUserIndex
              : lastSelectedUserIndex - 1;
            nextUser = currentUsersList[nextUserIndex];
          }

          if (nextUser) {
            const isNextElemInList = selectedChatUsersListRef.current.some(
              (user) => nextUser.Id === user.Id,
            );

            if (isNextElemInList) {
              handleRemoveUserFromSelectedList(nextUser);
              const resultSortedUsers = selectedUsersIndexesInList
                .filter((item) => item !== nextUserIndex)
                .sort((a, b) => a - b);
              console.log("resultSortedUsers: ", resultSortedUsers);
              if (isDown) {
                lastSelectedUserIdRef.current =
                  currentUsersList[resultSortedUsers[0]].UserDisplayPhoneNumber;
              } else if (isUp) {
                lastSelectedUserIdRef.current =
                  currentUsersList[
                    resultSortedUsers[resultSortedUsers.length - 1]
                  ].UserDisplayPhoneNumber;
              }
            } else {
              handleAddUserToSelectedList(nextUser);
            }
          }
        }
      };
      const openChatByContact = React.useCallback(
        (user) => {
          // setChatLoading(true);
          try {
            const phone = user.UserDisplayPhoneNumber;
            handleSelectedFunnerChatUser(user);
            handleSetSelectedUsersList([user]);
          } catch (error) {
            console.log("Open Chat Contact error: ", error);
            handleSelectedFunnerChatUser(null);
            handleSetSelectedUsersList([]);
          } finally {
            // setChatLoading(false);
          }
        },
        [handleSelectedFunnerChatUser, handleSetSelectedUsersList],
      );
      const onUserCardClick = React.useCallback(
        (e, user) => {
          if (e.ctrlKey) {
            console.log("ctrlKey clicked");
            console.log(selectedChatUsersListRef.current);
            if (
              selectedChatUsersListRef.current.some(
                (item) => item.Id === user.Id,
              )
            ) {
              handleRemoveUserFromSelectedList(user, "ctrl");
            } else {
              handleAddUserToSelectedList(user);
            }
          } else if (e.shiftKey) {
            const currentUsersList =
              chatUsersFunnerRef.current[
                isMyUsersChats ? "myChats" : "notMyChats"
              ];
            const currentSelectedUserIndex = currentUsersList.findIndex(
              (findUser) =>
                findUser.UserDisplayPhoneNumber === user.UserDisplayPhoneNumber,
            );
            const lastSelectedUserIndex = currentUsersList.findIndex(
              (findUser) =>
                findUser.UserDisplayPhoneNumber ===
                lastSelectedUserIdRef.current,
            );
            const startIndex = Math.min(
              currentSelectedUserIndex,
              lastSelectedUserIndex,
            );
            const endIndex = Math.max(
              currentSelectedUserIndex,
              lastSelectedUserIndex,
            );
            const selectedUsers = currentUsersList.slice(
              startIndex,
              endIndex + 1,
            );

            const selectedPhoneNumbers = new Set(
              selectedChatUsersListRef.current.map(
                (user) => user.UserDisplayPhoneNumber,
              ),
            );
            const newListPart = selectedUsers.filter(
              (newUser) =>
                !selectedPhoneNumbers.has(newUser.UserDisplayPhoneNumber),
            );
            handleSetSelectedUsersList([
              ...selectedChatUsersListRef.current,
              ...newListPart,
            ]);
          } else {
            openChatByContact(user);
          }
        },
        [
          chatUsersFunnerRef,
          isMyUsersChats,
          openChatByContact,
          handleSetSelectedUsersList,
        ],
      );

      const closeChat = React.useCallback(() => {
        handleSelectedFunnerChatUser(null);
        handleSetSelectedUsersList([]);
      }, [handleSelectedFunnerChatUser, handleSetSelectedUsersList]);

      //! Async and fetch functions
      async function funnerOnLoadActions() {
        handleGetContactsParams({
          open: userConfigRef.current.openCloseFilter,
          isOnlyUnread: userConfigRef.current.unreadFilter,
        });
        loadFunnerChatUsers();
        const globalUsersList = await getCompanyChatUsersGlobal();

        if (applicationType === "extension") {
          if (isChatOpenLocalStorage?.userId) {
            const currentUser = globalUsersList.notMyChats.find(
              (contact) =>
                contact.UserDisplayPhoneNumber ===
                isChatOpenLocalStorage.userId,
            );
            if (currentUser) {
              openChatByContact(currentUser);
            }
          }
        } else if (applicationType === "web") {
          const getParams = new URLSearchParams(document.location.search);
          const phoneFromParams = getParams.get("phone");

          if (phoneFromParams) {
            const phone = phoneRegex.test(phoneFromParams)
              ? "972" + phoneFromParams.slice(1)
              : phoneFromParams;
            console.log(phone);
            const currentUser = globalUsersList.notMyChats.find(
              (contact) => contact.UserDisplayPhoneNumber === phone,
            );
            if (currentUser) {
              openChatByContact(currentUser);
            } else {
              openChatByContact(globalUsersList.notMyChats[0]);
            }
          } else if (getParams.get("withPhone")) {
            console.log("With phone!");
            const paramsPhone = getParams.get("withPhone");
            const phone = phoneRegex.test(paramsPhone)
              ? "972" + paramsPhone.slice(1)
              : paramsPhone;
            console.log(phone);
            const currentUser = globalUsersList.notMyChats.find(
              (contact) => contact.UserDisplayPhoneNumber === phone,
            );
            if (currentUser) {
              openChatByContact(currentUser);
            } else {
              handleSetNotif(
                currentLocale.chat.notifications.noUserByGetPhoneErr,
                "error",
              );
              openChatByContact(globalUsersList.notMyChats[0]);
            }
          } else if (isChatOpenLocalStorage?.userId) {
            const currentUser = globalUsersList.notMyChats.find(
              (contact) =>
                contact.UserDisplayPhoneNumber ===
                isChatOpenLocalStorage.userId,
            );
            if (currentUser) {
              openChatByContact(currentUser);
            } else {
              openChatByContact(globalUsersList.notMyChats[0]);
            }
          } else {
            openChatByContact(globalUsersList.notMyChats[0]);
          }
        }
      }
      const loadFunnerChatUsers = React.useCallback(
        async (
          stopLoadingAfterFirstRequest = false,
          loadingFunc,
          type = null,
        ) => {
          await getCompanyChatUsers(type);
          setContactsLoaded(true);
          if (stopLoadingAfterFirstRequest) {
            loadingFunc(false);
          }
          await syncTimeout(200);

          handleGetContactsParams({
            pageOffset: 5,
          });
          await getCompanyChatUsers(type);
        },
        [getCompanyChatUsers, handleGetContactsParams],
      );

      const handleDeleteUsersConfirmation = React.useCallback((params) => {
        setDeleteUsersConfirmation((prevObj) => ({ ...prevObj, ...params }));
      }, []);
      const clearDeleteUsersConfirmation = React.useCallback(
        () =>
          handleDeleteUsersConfirmation({
            show: false,
            users: [],
          }),
        [handleDeleteUsersConfirmation],
      );
      const handleDeleteChatUsers = React.useCallback(
        async (list) => {
          try {
            const res = await UseFetchPost(
              "/api/services/deleteChatUser",
              list,
              "history",
            );

            if (res.data?.result) {
              enqueueSnackbar("Success", {
                variant: "success",
              });
              getCompanyChatUsers();
              getCompanyChatUsersGlobal();
              if (
                list.includes(
                  selectedFunnerChatUserRef.current.UserDisplayPhoneNumber,
                )
              ) {
                openChatByContact(
                  chatUsersFunnerRef.current.notMyChats[0]
                    ? chatUsersFunnerRef.current.notMyChats[0]
                    : null,
                );
              }
            } else
              enqueueSnackbar("Error", {
                variant: "error",
              });

            return res.data?.result ?? false;
          } catch (error) {
            return false;
          }
        },
        [
          chatUsersFunnerRef,
          getCompanyChatUsers,
          getCompanyChatUsersGlobal,
          openChatByContact,
          selectedFunnerChatUserRef,
        ],
      );
      const confirmUsersDelete = React.useCallback(() => {
        handleDeleteChatUsers(deleteUsersConfirmation.users);
        clearDeleteUsersConfirmation();
      }, [
        handleDeleteChatUsers,
        deleteUsersConfirmation.users,
        clearDeleteUsersConfirmation,
      ]);

      const bulkChatUserActions = React.useCallback(
        async (action, value) => {
          try {
            const params = {
              bulkActionName: action,
              chatUsersList: selectedChatUsersListRef.current.map(
                (user) => user.UserDisplayPhoneNumber,
              ),
            };
            // if (["openCloseConvStatus", "setLabel", "setRead"].includes(action)) {
            //   params.chatUsersList = selectedChatUsersListRef.current.map(
            //     (user) => user.UserDisplayPhoneNumber,
            //   );
            // }
            if (action === "setUnread") {
              const messagesList = selectedChatUsersListRef.current
                .filter((user) => user.incomingLastMessageId)
                .map((user) => Number(user.incomingLastMessageId));
              params.messageList = messagesList;
            } else if (action === "openCloseConvStatus") {
              params.isOpen = value;
            } else if (action === "setLabel") {
              params.labelName = JSON.stringify(value);
            } else if (action === "changeAssignee") {
              params.assigneeId = value;
            }

            if (action === "delete") {
              // const result = await handleDeleteChatUsers(
              //   selectedChatUsersListRef.current.map(
              //     (user) => user.UserDisplayPhoneNumber,
              //   ),
              // );
              // if (result) {
              //   enqueueSnackbar("Success", {
              //     variant: "success",
              //   });
              // } else {
              //   enqueueSnackbar("Error", {
              //     variant: "error",
              //   });
              // }

              handleDeleteUsersConfirmation({
                show: true,
                users: selectedChatUsersListRef.current.map(
                  (user) => user.UserDisplayPhoneNumber,
                ),
              });
            } else {
              const res = await UseFetchPost(
                "/api/services/bulkChatUserActions",
                params,
                "history",
              );
            }
          } catch (error) {
            enqueueSnackbar("Error", {
              variant: "error",
            });
          }
        },
        [handleDeleteUsersConfirmation],
      );

      async function updateChatUserIcon(phoneNumber) {
        handleAdditionalMenuClose();
        if (!phoneNumber)
          return enqueueSnackbar("No phone number", { variant: "error" });
        try {
          const res = await UseFetchGet(
            "/api/services/getChatUserWhatsappUserPick",
            { whatsappPhoneNumberId: phoneNumber },
            null,
            false,
            "history",
          );

          if (res?.data?.result) {
            updateChatUser(phoneNumber, {
              UserPic: res.data.userPick,
            });
          } else {
            enqueueSnackbar(
              "תמונת הפרופיל של איש קשר זה אינה זמינה בשל הגדרות פרטיות ואבטחה של איש הקשר",
              {
                variant: "warning",
              },
            );
          }
        } catch (error) {
          console.error("updateChatUserIcon error: ", error);
        }
      }

      //! UseEffects
      useEffect(() => {
        funnerOnLoadActions();

        document.addEventListener("keydown", manageSelectedChatUsersWithKeys);

        return () => {
          document.removeEventListener(
            "keydown",
            manageSelectedChatUsersWithKeys,
          );
        };
      }, []);

      useEffect(() => {
        if (userInSettingsCreationState !== null) {
          getCompanyChatUsers();
        }
      }, [userInSettingsCreationState]);
      useEffect(() => {
        const unreadChats =
          userData?.IsShowAllChatUsers && !isMyUsersChats
            ? chatUsersFunnerRef.current.notMyChats
                .map((user, index) => {
                  return { user: user, index: index };
                })
                .filter((userObj) => userObj.user.UnreadMessagesNumber > 0)
            : chatUsersFunnerRef.current.myChats
                .map((user, index) => {
                  return { user: user, index: index };
                })
                .filter((userObj) => userObj.user.UnreadMessagesNumber > 0);
        const unreadChatsInDOM = unreadChats.map((chat) => {
          return {
            user: chat.user,
            index: chat.index + 1,
          };
        });
        setUnreadChatUsers(unreadChatsInDOM);
      }, [chatUsersFunner, isMyUsersChats]);
      useEffect(() => {
        updateUnreadChatsIndicator();
      }, [unreadChatUsers]);

      function onSocketReconnect(connectionId) {
        reconnectListenerSet = true;
        getCompanyChatUsers();
      }

      function initSocketListener() {
        connection.on("UpdateChatUserDontDisturb", async function (message) {
          const messageObject = JSON.parse(message);
          getCompanyChatUsers();
        });
      }

      useEffect(() => {
        if (isConnection && !reconnectListenerSet) {
          connection.onreconnected(onSocketReconnect);
        }

        if (isConnection) {
          initSocketListener();
        }
      }, [isConnection]);

      useEffect(() => {
        const isChatOpen = openUnreadMessagesPopup;

        if (!isChatOpen) {
          localStorage.setItem(
            "globalFunnerMessangerOpenStatus",
            JSON.stringify({ isChatOpen: false, userId: null }),
          );

          if (
            document.documentElement.classList.contains(
              "funner-chat-popup-open",
            )
          ) {
            document.documentElement.classList.remove("funner-chat-popup-open");
          }
        } else {
          const isUserChoosed = Boolean(
            selectedFunnerChatUser?.UserDisplayPhoneNumber,
          );
          if (!isUserChoosed) {
            localStorage.setItem(
              "globalFunnerMessangerOpenStatus",
              JSON.stringify({ isChatOpen: true, user: null }),
            );
          } else {
            localStorage.setItem(
              "globalFunnerMessangerOpenStatus",
              JSON.stringify({
                isChatOpen: true,
                userId: selectedFunnerChatUser.UserDisplayPhoneNumber,
              }),
            );

            // console.log("textarea must focus: ", textareaRef.current);
            setTimeout(() => {
              if (textareaRef.current) textareaRef.current.focus();
            }, 0);
          }

          if (
            !document.documentElement.classList.contains(
              "funner-chat-popup-open",
            )
          ) {
            document.documentElement.classList.add("funner-chat-popup-open");
          }
        }
      }, [
        openUnreadMessagesPopup,
        selectedFunnerChatUser?.UserDisplayPhoneNumber,
      ]);

      return (
        <>
          <Dialog
            open={showUsersCreateChat}
            onClose={() => setShowUsersCreateChat(false)}
            fullWidth={true}
            maxWidth="md"
            className={
              applicationType === "extension"
                ? "_funner-global-messanger-container"
                : null
            }
          >
            <DialogContent sx={{ p: 0 }}>
              <Box
                className={
                  applicationType === "extension" ? "root-whatsapp" : null
                }
              >
                <AddUsersChat
                  setShowUsersCreateChat={setShowUsersCreateChat}
                  handleSetNotif={handleSetNotif}
                  // setUserCreationState={setUserCreationState}
                  templatesList={templatesList}
                  globalID={userData.Udid}
                  fetchWhatsappTemplates={fetchWhatsappTemplates}
                  openChatByContact={openChatByContact}
                  getCompanyChatUsers={getCompanyChatUsers}
                  getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
                  initialAddType={"manually"}
                />
              </Box>
            </DialogContent>
          </Dialog>
          <Box
            className={`unread-messages-popup ${
              openUnreadMessagesPopup ? "visible" : ""
            } ${
              applicationType === "web"
                ? "funner-global-web-app root-whatsapp"
                : ""
            } ${applicationType === "web" && isNavOpen ? "nav-open" : ""} ${
              iframeMode ? "funner-global-web-app--iframe" : ""
            }`}
            sx={{
              bgcolor: "rgb(255, 255, 255)",
            }}
          >
            <div
              className={`unread-messages-popup__content ${
                selectedFunnerChatUser ? "chat-open" : ""
              }`}
            >
              {!(iframeMode && iframeMode === "conversation") && (
                <Box
                  sx={{
                    width:
                      applicationType === "extension" || matchesMD
                        ? "auto"
                        : 450,
                    flex:
                      applicationType === "extension" || matchesMD
                        ? "0 0 auto"
                        : "0 0 450px",
                    height: applicationType === "extension" ? "100%" : null,
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FunnerHeader
                      searchParams={getContactsQueryParams}
                      searchParamsRef={getContactsQueryParamsRef}
                      handleGetContactsParams={handleGetContactsParams}
                      getCompanyChatUsers={getCompanyChatUsers}
                      loadFunnerChatUsers={loadFunnerChatUsers}
                      getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
                      updateUserConfig={updateUserConfig}
                      updateUserConfigWithObject={updateUserConfigWithObject}
                      isMyUsersChats={isMyUsersChats}
                      setMyUsersChat={setMyUsersChat}
                      chatUsersLabelsList={chatUsersLabelsList}
                      userData={userData}
                      funnerUsers={funnerUsers}
                      setShowUsersCreateChat={setShowUsersCreateChat}
                      toggleUnreadMessagesPopup={toggleUnreadMessagesPopup}
                      selectedChatUsersList={selectedChatUsersList}
                      bulkChatUserActions={bulkChatUserActions}
                      templatesList={templatesList}
                      handleSetNotif={handleSetNotif}
                      fetchWhatsappTemplates={fetchWhatsappTemplates}
                      chatUsersGlobalRef={chatUsersGlobalRef}
                      ref={funnerHeaderRef}
                    />
                    <ChatUsersList
                      chatUsersListRef={chatUsersListRef}
                      handleLoadContactsOnScroll={handleLoadContactsOnScroll}
                      IsShowAllChatUsers={businessInfo?.IsShowAllChatUsers}
                      isMyUsersChats={isMyUsersChats}
                      chatUsersFunner={chatUsersFunner}
                      selectedFunnerChatUser={selectedFunnerChatUser}
                      selectedChatUsersList={selectedChatUsersList}
                      userConfig={userConfig}
                      handleAdditionalMenuClick={handleAdditionalMenuClick}
                      onUserCardClick={onUserCardClick}
                      funnerUsers={funnerUsers}
                      chatUsersCustomizationSettings={
                        chatUsersCustomizationSettings
                      }
                      chatUsersCustomizationAdditionalSettings={
                        chatUsersCustomizationAdditionalSettings
                      }
                      chatUsersLabelsList={chatUsersLabelsList}
                      isContactsLoaded={isContactsLoaded}
                      showAvatar={showAvatar}
                      unreadHint={unreadHint}
                      goToTopTrigger={goToTopTrigger}
                      scrollChatUsersTop={scrollChatUsersTop}
                    />

                    {/* Context menu */}
                    <Menu
                      anchorEl={additionalMenuAnchorEl.anchor}
                      open={Boolean(additionalMenuAnchorEl.anchor)}
                      onClose={handleAdditionalMenuClose}
                      disableScrollLock={true}
                    >
                      <MenuItem
                        onClick={() =>
                          makeChatUserUnread(additionalMenuAnchorEl.chatUser)
                        }
                      >
                        {
                          currentLocale.chat.chatUsers.userAdditionalMenu
                            .makeUnread
                        }
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          makeSingleChatUserRead(
                            additionalMenuAnchorEl.chatUser?.Id || "",
                          )
                        }
                      >
                        {
                          currentLocale.chat.chatUsers.userAdditionalMenu
                            .makeRead
                        }
                      </MenuItem>
                      {businessInfo?.CompanyType === "fireberry" && (
                        <MenuItem
                          onClick={() =>
                            openFireberrySyncUser(
                              additionalMenuAnchorEl.chatUser,
                            )
                          }
                        >
                          {
                            currentLocale.chat.chatUsers.userAdditionalMenu
                              .fireberrySync
                          }
                        </MenuItem>
                      )}
                      {businessInfo?.CompanyType === "priority" && (
                        <MenuItem
                          onClick={() =>
                            openPrioritySyncUser(
                              additionalMenuAnchorEl.chatUser,
                            )
                          }
                        >
                          {
                            currentLocale.chat.chatUsers.userAdditionalMenu
                              .prioritySync
                          }
                        </MenuItem>
                      )}
                      {showAvatar && (
                        <MenuItem
                          onClick={() =>
                            updateChatUserIcon(
                              additionalMenuAnchorEl.chatUser
                                ?.UserDisplayPhoneNumber,
                            )
                          }
                        >
                          {
                            currentLocale.chat.chatUsers.userAdditionalMenu
                              .getUserPic
                          }
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          setDontDisturbChatUserState(
                            additionalMenuAnchorEl.chatUser
                              .UserDisplayPhoneNumber,
                            !additionalMenuAnchorEl.chatUser?.DontDisturb,
                          );
                        }}
                      >
                        {additionalMenuAnchorEl.chatUser?.DontDisturb
                          ? currentLocale.chat.chatUsers.userAdditionalMenu
                              .removeDontDisturb
                          : currentLocale.chat.chatUsers.userAdditionalMenu
                              .dontDisturb}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleAdditionalMenuClose();
                          // handleDeleteChatUsers([
                          //   additionalMenuAnchorEl.chatUser
                          //     .UserDisplayPhoneNumber,
                          // ]);
                          handleDeleteUsersConfirmation({
                            show: true,
                            users: [
                              additionalMenuAnchorEl.chatUser
                                .UserDisplayPhoneNumber,
                            ],
                          });
                        }}
                      >
                        {currentLocale.chat.chatUsers.userAdditionalMenu.delete}
                      </MenuItem>
                    </Menu>

                    {/* Delete users confirmation */}
                    <Dialog
                      open={deleteUsersConfirmation.show}
                      onClose={clearDeleteUsersConfirmation}
                    >
                      <DialogContent>האם למחוק את איש הקשר?</DialogContent>
                      <DialogActions>
                        <Button
                          variant="outlined"
                          onClick={clearDeleteUsersConfirmation}
                        >
                          ביטול
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => confirmUsersDelete()}
                        >
                          אישור
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Box>
              )}

              <Chat
                chatType={React.useMemo(() => "funner", [])}
                selectedUserId={selectedFunnerChatUser?.UserDisplayPhoneNumber}
                selectedChatUser={selectedFunnerChatUser}
                // onUserCardClick={onUserCardClick}
                openChatByContact={openChatByContact}
                selectedUserRef={selectedFunnerChatUserRef}
                chatUsersFunnerRef={chatUsersFunnerRef}
                chatUsersGlobal={chatUsersGlobal}
                chatUsersGlobalRef={chatUsersGlobalRef}
                userData={userData}
                userDataRef={userDataRef}
                userConfig={userConfig}
                chatLoading={chatLoading}
                setChatLoading={setChatLoading}
                funnerUsers={funnerUsers}
                closeChat={closeChat}
                handleSetNotif={handleSetNotif}
                chatUsersLabelsList={chatUsersLabelsList}
                toggleUnreadMessagesPopup={toggleUnreadMessagesPopup}
                updateChatUser={updateChatUser}
                businessInfo={businessInfo}
                templatesList={templatesList}
                templatesLoading={templatesLoading}
                fetchWhatsappTemplates={fetchWhatsappTemplates}
                connection={connection}
                isConnection={isConnection}
                tabActiveRef={tabActiveRef}
                handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
                sendUploadPriority={sendUploadPriority}
                resetSendUploadPriority={resetSendUploadPriority}
                isNavOpen={isNavOpen}
                chatUsersMessagesRef={chatUsersMessagesRef}
                handleChatUserMessages={handleChatUserMessages}
                clearChatUsersMessages={clearChatUsersMessages}
                disableChatGoBack={disableChatGoBack}
                textareaRef={textareaRef}
                getChatMissingHistory={getChatMissingHistory}
                goToFavouriteMessageState={goToFavouriteMessageState}
                goToFavouriteMessageStateRef={goToFavouriteMessageStateRef}
                handleGoToFavouriteMessageState={
                  handleGoToFavouriteMessageState
                }
              />
            </div>
          </Box>
        </>
      );
    },
  ),
);

export default FunnerGlobalMessanger;
