import React, { useState } from "react";
import { UseFetchPostAnonymos } from "../../../hooks/fetchFunctions";
import { useLocalization } from "../../Context/LocalizationContext";

function VerifyOTP({
  otpData,
  handleOtpParams,
  isPowerLinkPage,
  handleSetNotif,
  handleLoginStep,
  handleAuthPopupWindow,
  sendOTPByPhone,
}) {
  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.auth.verifyOTP,
    [currentLocale],
  );

  async function handleVerifyOTPStandalone() {
    try {
      const params = {
        PhoneNumber: otpData.phone,
        OTP: otpData.otp,
      };

      const res = await UseFetchPostAnonymos(
        "/api/auth/VerifyOTPByPhoneNumber",
        params,
        "config",
      );
      if (res?.data?.success === false) {
        // alert("Wrong OTP code!");
        handleSetNotif(currentLocale.auth.notifications, "error");
        // setIsResendOTP(true);
        handleOtpParams({ otp: "" });
      } else {
        handleSetNotif(currentLocale.auth.notifications, "success");

        handleLoginStep(otpData.phone, otpData.password);
        handleAuthPopupWindow("");
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function handleResendVerifyOTPStandalone() {
    try {
      const res = await sendOTPByPhone(otpData.phone);
      if (res?.data) {
        const response = res.data;
        if (response.success) {
          handleSetNotif(currentLocale.auth.notifications, "success");
        }
      }
    } catch (error) {
      console.log("sendOTPByPhone error: ", error);
    }
  }

  return (
    <div
      className="form-get-token-popup__block"
      style={{
        paddingTop: "30px",
        paddingBottom: "30px",
        maxWidth: 600,
        width: "100%",
        margin: "auto",
      }}
    >
      {/*  <div className="form-get-token-popup__gray-check">
        <img src={grayCheck} className="check-icon" alt="" />
        מאשר את תנאי התקנון
      </div> */}
      <label htmlFor="inp-7" className="form-get-token-popup__input-wrapper">
        <span className="input-title">{localization.otpCodeTitle}</span>
        <input
          onChange={(e) => handleOtpParams({ otp: e.target.value })}
          value={otpData.otp}
          type="text"
          id="inp-7"
          className="form-get-token-popup__input"
        />
      </label>
      <div className="form-get-token-popup__buttons-col" style={{ padding: 0 }}>
        <button
          onClick={handleVerifyOTPStandalone}
          className="get-token-popup__main-btn popup__blue-btn h42"
        >
          {localization.submitBtnText}
        </button>
        {/* {isResendOTP && ( */}
        <button
          onClick={handleResendVerifyOTPStandalone}
          className="get-token-popup__main-btn popup__blue-btn h42"
        >
          {localization.resendBtnText}
        </button>
        {/* )} */}
      </div>
    </div>
  );
}

export default VerifyOTP;
