import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import {
  IsJsonString,
  displayMessageDate,
  getContrastYIQ,
  isHebrewString,
} from "../../libs/helpers";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  CopyAll,
  Description,
  Done,
  DoneAll,
  FolderZip,
  Image,
  Label,
  LocationOn,
  Mic,
  MoreVert,
  Person,
  StickyNote2,
  VideoCameraBack,
} from "@mui/icons-material";
import deepEqual from "fast-deep-equal";
import CopyToClipboard from "react-copy-to-clipboard";
import { applicationType } from "../../applicationType";
import { phoneGlobalRegex } from "../../libs/regexes";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useLocalization } from "../Context/LocalizationContext";

const fileTypes = ["pdf", "xlsx", "xls", "docx", "doc", "pdf"];

const ChatUserCard = memo(
  ({
    user,
    selected,
    showAvatar,
    handleAdditionalMenuClick,
    onUserCardClick,
    funnerUsers,
    chatUsersCustomizationSettings,
    chatUsersCustomizationAdditionalSettings,
    interactive = true,
    style = {},
  }) => {
    const { currentLocale } = useLocalization();

    const theme = useTheme();
    const matchesBigMobile = useMediaQuery(theme.breakpoints.down(768));
    const matchesMobile = useMediaQuery(theme.breakpoints.down(480));
    const assignee =
      funnerUsers?.find(
        (assigneeUser) => assigneeUser.Udid === user.NotificationContactUser,
      ) || null;

    const [isHovered, setIsHovered] = useState(false);

    //! Get user label if it exists
    const userLabel = IsJsonString(user.LableName)
      ? JSON.parse(user.LableName)
      : null;

    const moreBtnRef = useRef(null);

    //! Colors
    const defaultStyles =
      chatUsersCustomizationSettings?.find(
        (option) => option.type === "default",
      ) || null;
    const hoverStyles =
      chatUsersCustomizationSettings?.find(
        (option) => option.type === "hover",
      ) || null;
    const selectedStyles =
      chatUsersCustomizationSettings?.find(
        (option) => option.type === "selected",
      ) || null;
    const unreadStyles =
      chatUsersCustomizationSettings?.find(
        (option) => option.type === "unread",
      ) || null;

    const getElementColor = (type) => {
      const unreadMessagesNumber = user.UnreadMessagesNumber;

      const styles = selected
        ? selectedStyles
        : isHovered
        ? hoverStyles
        : unreadMessagesNumber > 0
        ? unreadStyles
        : defaultStyles;

      return styles?.value === "custom"
        ? styles.customColor[type] + " !important"
        : null;
    };

    //* Assignee color
    const assigneColor = getElementColor("assignee");

    const contactsMargin =
      chatUsersCustomizationAdditionalSettings?.contactsMargin ?? false;

    return user ? (
      <Box style={style}>
        <ListItemButton
          sx={{
            "&:not(.Mui-selected):hover": {
              bgcolor:
                hoverStyles?.value === "custom"
                  ? hoverStyles.customColor.background + " !important"
                  : "#F4F4F4 !important",
            },
            bgcolor: selected
              ? selectedStyles?.value === "custom"
                ? selectedStyles.customColor.background + " !important"
                : "#E5E5E5 !important"
              : user.UnreadMessagesNumber > 0
              ? unreadStyles?.value === "custom"
                ? unreadStyles.customColor.background + " !important"
                : "#F1F7FC"
              : defaultStyles?.value === "custom"
              ? defaultStyles.customColor.background + " !important"
              : "common.white",
            p: 1.5,
            mb: contactsMargin ? 1 : 0,
            boxShadow: contactsMargin
              ? "0px 4px 10px 0px rgba(0, 0, 0, 0.12);"
              : null,
            borderRadius: contactsMargin ? 1 : null,
            border: contactsMargin ? "1px solid #E6EBEF" : null,
            height: 84,
          }}
          // onContextMenu={(e) => chatsListUserContext(e, user)}
          onClick={(e) => {
            if (
              user.UserDisplayPhoneNumber !== selected &&
              // !chatLoading &&
              !moreBtnRef.current.contains(e.target) &&
              interactive
            )
              // openChatByContact(user);
              onUserCardClick(e, user);
          }}
          selected={selected}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {showAvatar && (
            <ListItemAvatar>
              <Avatar
                src={user.UserPic}
                sx={{
                  width:
                    applicationType === "extension" || matchesBigMobile
                      ? 40
                      : 55,
                  height:
                    applicationType === "extension" || matchesBigMobile
                      ? 40
                      : 55,
                  mr:
                    applicationType === "extension" || matchesBigMobile
                      ? 1
                      : 1.5,
                }}
              />
            </ListItemAvatar>
          )}

          <ListItemText
            primary={
              <Grid
                container
                columnSpacing={2}
                rowSpacing={0}
                flexWrap={"nowrap"}
              >
                <Grid item flexGrow={1}>
                  {/* <Grid container spacing={1}> */}
                  {/* <Grid item xs={12}> */}
                  <Box sx={{ mb: 1 }}>
                    <Grid
                      container
                      spacing={1.5}
                      alignItems={"center"}
                      flexWrap={"nowrap"}
                    >
                      <Grid item xs="auto">
                        <Box
                          sx={{
                            width: 6,
                            height: 6,
                            borderRadius: "50%",
                            bgcolor: user.isRestricted24
                              ? "success.light"
                              : "error.main",
                          }}
                        ></Box>
                      </Grid>
                      <Grid item xs="auto">
                        <Box
                          sx={{
                            width:
                              applicationType === "extension" && showAvatar
                                ? 80
                                : !userLabel
                                ? 150
                                : 100,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip
                            title={
                              <Fragment>
                                <Box>{user.FullName}</Box>
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems={"center"}
                                >
                                  <Grid item>
                                    {phoneGlobalRegex.test(
                                      user.UserDisplayPhoneNumber,
                                    )
                                      ? `0${user.UserDisplayPhoneNumber.slice(
                                          3,
                                        )}`
                                      : user.UserDisplayPhoneNumber || ""}
                                  </Grid>
                                  <Grid item>
                                    <CopyToClipboard
                                      text={
                                        user.UserDisplayPhoneNumber
                                          ? !user.Platform
                                            ? `0${user.UserDisplayPhoneNumber.slice(
                                                3,
                                              )}`
                                            : user.UserDisplayPhoneNumber
                                          : ""
                                      }
                                      onCopy={() => {
                                        enqueueSnackbar("Copied!", {
                                          variant: "success",
                                        });
                                      }}
                                    >
                                      <IconButton
                                        size="small"
                                        color="customWhite"
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <CopyAll
                                          sx={{ width: 18, height: 18 }}
                                        />
                                      </IconButton>
                                    </CopyToClipboard>
                                  </Grid>
                                </Grid>
                              </Fragment>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant={
                                applicationType === "extension" ||
                                matchesBigMobile
                                  ? "body2"
                                  : "body1"
                              }
                              sx={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                                // fontWeight: 700,
                                direction: !isHebrewString(user.FullName)
                                  ? "rtl"
                                  : "ltr",
                                fontFamily: "Heebo, Arimo",
                                fontWeight:
                                  user.UnreadMessagesNumber > 0 ? 700 : 400,
                                height:
                                  applicationType === "extension" ||
                                  matchesBigMobile
                                    ? 24
                                    : 20,

                                ".MuiListItemButton-root:not(.Mui-selected):hover &":
                                  {
                                    color:
                                      hoverStyles?.value === "custom"
                                        ? hoverStyles.customColor.name +
                                          " !important"
                                        : "rgba(0, 0, 0, 0.87) !important",
                                  },
                                color: selected
                                  ? selectedStyles?.value === "custom"
                                    ? selectedStyles.customColor.name +
                                      " !important"
                                    : null
                                  : user.UnreadMessagesNumber > 0
                                  ? unreadStyles?.value === "custom"
                                    ? unreadStyles.customColor.name +
                                      " !important"
                                    : null
                                  : defaultStyles?.value === "custom"
                                  ? defaultStyles.customColor.name +
                                    " !important"
                                  : null,
                                WebkitTransition:
                                  "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                transition:
                                  "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                              }}
                            >
                              {user.FullName}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Grid>
                      {userLabel && (
                        <Grid item xs="auto">
                          {matchesMobile ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Label
                                sx={{
                                  color:
                                    userLabel &&
                                    userLabel.name !==
                                      currentLocale.chat.chatHeader.filterLabel
                                        .noLabel
                                      ? userLabel.color
                                      : "transparent",
                                }}
                              />
                            </Box>
                          ) : (
                            <Box sx={{ width: 80 }}>
                              {userLabel &&
                                userLabel.name !==
                                  currentLocale.chat.chatHeader.filterLabel
                                    .noLabel && (
                                  <Tooltip title={userLabel.name} arrow>
                                    <Chip
                                      size="small"
                                      sx={{
                                        borderRadius: "3px !important",
                                        bgcolor: userLabel.color,
                                        color: getContrastYIQ(userLabel.color),
                                        width: "100%",
                                        direction: !isHebrewString(
                                          userLabel.name,
                                        )
                                          ? "rtl"
                                          : "ltr",
                                        // height: 25,
                                      }}
                                      label={userLabel.name}
                                    />
                                  </Tooltip>
                                )}
                            </Box>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Box>

                  {/* </Grid> */}
                  {/* <Grid item xs={12}> */}
                  <Box>
                    <Grid container columnSpacing={1} flexWrap={"nowrap"}>
                      <Grid item xs="auto" sx={{ mt: -0.375 }}>
                        {user.LastMessageType === "outcoming" &&
                          (user.OutcomingLastMessageStatus === "sent" ? (
                            <Done
                              sx={{ width: 14, height: 14, color: "grey.500" }}
                            />
                          ) : user.OutcomingLastMessageStatus ===
                            "delivered" ? (
                            <DoneAll
                              sx={{ width: 14, height: 14, color: "grey.500" }}
                            />
                          ) : user.OutcomingLastMessageStatus === "read" ? (
                            <DoneAll
                              sx={{
                                color: "primary.light",
                                width: 14,
                                height: 14,
                              }}
                            />
                          ) : (
                            ""
                          ))}
                      </Grid>
                      {user.LastMessageMediaType &&
                        (user.LastMessageMediaType === "location" ||
                          user.LastMessageMediaType === "audio" ||
                          user.LastMessageMediaType === "image" ||
                          fileTypes.includes(
                            user?.LastMessageMediaType?.toLowerCase(),
                          ) ||
                          user.LastMessageMediaType === "contacts" ||
                          user.LastMessageMediaType === "template" ||
                          user.LastMessageMediaType === "video" ||
                          user.LastMessageMediaType === "zip") && (
                          <Grid item xs="auto" sx={{ mt: -0.375 }}>
                            {user.LastMessageMediaType === "location" && (
                              <LocationOn
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "grey.500",
                                }}
                              />
                            )}
                            {user.LastMessageMediaType === "audio" && (
                              <Mic
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "grey.500",
                                }}
                              />
                            )}
                            {user.LastMessageMediaType === "image" && (
                              <Image
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "grey.500",
                                }}
                              />
                            )}

                            {fileTypes.includes(
                              user?.LastMessageMediaType?.toLowerCase(),
                            ) && (
                              <Description
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "grey.500",
                                }}
                              />
                            )}

                            {user.LastMessageMediaType === "contacts" && (
                              <Person
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "grey.500",
                                }}
                              />
                            )}

                            {user.LastMessageMediaType === "template" && (
                              <StickyNote2
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "grey.500",
                                }}
                              />
                            )}
                            {user.LastMessageMediaType === "video" && (
                              <VideoCameraBack
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "grey.500",
                                }}
                              />
                            )}
                            {user.LastMessageMediaType === "zip" && (
                              <FolderZip
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "grey.500",
                                }}
                              />
                            )}
                          </Grid>
                        )}
                      <Grid item flexGrow={1}>
                        <Typography
                          variant="caption"
                          component={"p"}
                          sx={{
                            ".MuiListItemButton-root:not(.Mui-selected):hover &":
                              {
                                color:
                                  hoverStyles?.value === "custom"
                                    ? hoverStyles.customColor.message +
                                      " !important"
                                    : "rgba(0, 0, 0, 0.6) !important",
                              },
                            color: selected
                              ? selectedStyles?.value === "custom"
                                ? selectedStyles.customColor.message +
                                  " !important"
                                : "text.secondary"
                              : user.UnreadMessagesNumber > 0
                              ? unreadStyles?.value === "custom"
                                ? unreadStyles.customColor.message +
                                  " !important"
                                : "text.secondary"
                              : defaultStyles?.value === "custom"
                              ? defaultStyles.customColor.message +
                                " !important"
                              : "text.secondary",
                            WebkitTransition:
                              "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            transition:
                              "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            maxWidth: "100%",
                            // maxHeight: "2.5em",
                            lineHeight: 1.25,
                            wordBreak: "break-all",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {(() => {
                            if (
                              user.LastMessageMediaType === "template" &&
                              IsJsonString(user.LastMessage)
                            ) {
                              const parsedLastMessage = JSON.parse(
                                user.LastMessage,
                              );
                              return parsedLastMessage["BODY"]?.text || "";
                            } else if (user.LastMessage === "image")
                              return "תמונה";
                            else if (user.LastMessage === "buttons")
                              return "כפתורי תשובה";
                            else return user.LastMessage || "";
                          })()}
                          {/* {user.LastMessage
                            ? user.LastMessage.length >= 70
                              ? user.LastMessage.slice(0, 70) + "..."
                              : user.LastMessage
                            : ""} */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* </Grid> */}
                  {/* </Grid> */}
                </Grid>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      width: 120,
                      [theme.breakpoints.down(480)]: {
                        width: 100,
                      },
                    }}
                  >
                    <Grid
                      container
                      spacing={
                        applicationType === "extension" || matchesBigMobile
                          ? 0.5
                          : 1
                      }
                      direction={"column"}
                    >
                      <Grid item sx={{ mt: -0.5 }}>
                        <Grid
                          container
                          spacing={0}
                          alignItems={"center"}
                          wrap="nowrap"
                        >
                          <Grid item flexGrow={1}>
                            <Box
                              sx={{
                                width: 80,
                                [theme.breakpoints.down(480)]: {
                                  width: 28,
                                  ml: "auto",
                                  mr: 1,
                                },
                              }}
                            >
                              {/* {assignee && ( */}
                              <Tooltip title={assignee?.FullName} arrow>
                                {matchesMobile ? (
                                  <span>
                                    {assignee && (
                                      <Avatar
                                        sx={{
                                          width: "18px !important",
                                          height: "18px !important",
                                          fontSize: "11px !important",

                                          // ".MuiListItemButton-root:not(.Mui-selected):hover &":
                                          //   {
                                          //     bgcolor:
                                          //       hoverStyles?.value === "custom"
                                          //         ? hoverStyles.customColor
                                          //             .assignee + " !important"
                                          //         : "#bdbdbd !important",
                                          //   },
                                          bgcolor: assigneColor,
                                          WebkitTransition:
                                            "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                          transition:
                                            "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

                                          [theme.breakpoints.down(480)]: {
                                            width: "28px !important",
                                            height: "28px !important",
                                            fontSize: "16px !important",
                                          },
                                        }}
                                      >
                                        {assignee.FullName?.split(" ").length >
                                          0 &&
                                          `${assignee.FullName?.split(
                                            " ",
                                          )[0].slice(0, 1)}${
                                            assignee.FullName.split(" ")[1]
                                              ? assignee.FullName.split(
                                                  " ",
                                                )[1].slice(0, 1)
                                              : ""
                                          }`}
                                      </Avatar>
                                    )}
                                  </span>
                                ) : (
                                  <Box
                                    sx={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      maxWidth: "100%",
                                      borderRadius: 0.75,
                                      px: 0.75,
                                      transitionProperty:
                                        "color, background-color, border-color",
                                      transitionDuration: "150ms",
                                      transitionTimingFunction:
                                        "cubic-bezier(0.4, 0, 0.2, 1)",
                                      transitionDelay: "0ms",

                                      //* Colors
                                      backgroundColor: assignee
                                        ? assigneColor || "#7C7C7C"
                                        : "transparent",
                                      color: assignee
                                        ? getContrastYIQ(
                                            assigneColor
                                              ? assigneColor
                                              : "#7C7C7C",
                                          )
                                        : assigneColor,
                                      border: `1px solid ${
                                        assigneColor || "#7C7C7C"
                                      }`,
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      sx={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        direction: !isHebrewString(
                                          assignee?.FullName || "",
                                        )
                                          ? "rtl"
                                          : "ltr",
                                        fontSize: 13,
                                        maxWidth: "100%",
                                      }}
                                    >
                                      {assignee
                                        ? assignee.FullName
                                        : "לא שוייך"}
                                    </Box>
                                  </Box>
                                )}
                              </Tooltip>
                              {/* )} */}
                            </Box>
                          </Grid>
                          {interactive && (
                            <Grid item>
                              <IconButton
                                size="small"
                                sx={
                                  applicationType === "extension" ||
                                  matchesBigMobile
                                    ? {
                                        width: 30,
                                        height: 30,
                                      }
                                    : null
                                }
                                aria-label="more"
                                ref={moreBtnRef}
                                color="primary"
                                onClick={(e) =>
                                  handleAdditionalMenuClick(e, user)
                                }
                              >
                                <MoreVert />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Box sx={{ pr: 1 }}>
                          <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            justifyContent={"flex-end"}
                          >
                            <Grid item xs="auto">
                              <Typography
                                variant={"body2"}
                                sx={{
                                  ".MuiListItemButton-root:not(.Mui-selected):hover &":
                                    {
                                      color:
                                        hoverStyles?.value === "custom"
                                          ? hoverStyles.customColor.date +
                                            " !important"
                                          : "rgba(0, 0, 0, 0.87) !important",
                                    },
                                  color: selected
                                    ? selectedStyles?.value === "custom"
                                      ? selectedStyles.customColor.date +
                                        " !important"
                                      : null
                                    : user.UnreadMessagesNumber > 0
                                    ? unreadStyles?.value === "custom"
                                      ? unreadStyles.customColor.date +
                                        " !important"
                                      : null
                                    : defaultStyles?.value === "custom"
                                    ? defaultStyles.customColor.date +
                                      " !important"
                                    : null,

                                  WebkitTransition:
                                    "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                  transition:
                                    "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                }}
                              >
                                {user.LastMessageDate &&
                                  (displayMessageDate(user.LastMessageDate) ===
                                  0 ? (
                                    String(user.LastMessageDate).slice(11, 16)
                                  ) : (
                                    <>
                                      {`${String(user.LastMessageDate).slice(
                                        8,
                                        10,
                                      )}/${String(user.LastMessageDate).slice(
                                        5,
                                        7,
                                      )}/${String(user.LastMessageDate).slice(
                                        0,
                                        4,
                                      )}`}
                                    </>
                                  ))}
                              </Typography>
                            </Grid>
                            {user.UnreadMessagesNumber > 0 && (
                              <Grid
                                item
                                xs="auto"
                                sx={{
                                  my: -1.25,
                                }}
                              >
                                <Chip
                                  size="small"
                                  color="primary"
                                  label={user.UnreadMessagesNumber}
                                  sx={{
                                    bgcolor:
                                      unreadStyles?.value === "custom"
                                        ? unreadStyles.customColor
                                            .unreadIndicatorBackground +
                                          " !important"
                                        : null,
                                    color:
                                      unreadStyles?.value === "custom"
                                        ? unreadStyles.customColor
                                            .unreadIndicatorText + " !important"
                                        : null,
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            }
            sx={{ my: 0 }}
          />
        </ListItemButton>
        {!contactsMargin && <Divider sx={{ width: "100%" }} />}
      </Box>
    ) : (
      <></>
    );
  },
  (prevProps, nextProps) => {
    return (
      deepEqual(prevProps.user, nextProps.user) &&
      prevProps.selected === nextProps.selected &&
      prevProps.chatLoading === nextProps.chatLoading &&
      prevProps.showAvatar === nextProps.showAvatar &&
      deepEqual(prevProps.funnerUsers, nextProps.funnerUsers) &&
      deepEqual(
        prevProps.chatUsersCustomizationSettings,
        nextProps.chatUsersCustomizationSettings,
      ) &&
      deepEqual(
        prevProps.chatUsersCustomizationAdditionalSettings,
        nextProps.chatUsersCustomizationAdditionalSettings,
      )
    );
  },
);

export default ChatUserCard;
