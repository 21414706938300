import React from "react";
import { IsJsonString } from "../../../../libs/helpers";

function ReplyButtonMessage({ replyObject }) {
  const templateReplyMessage =
    replyObject?.FileType === "template" && IsJsonString(replyObject.Message)
      ? JSON.parse(replyObject.Message)
      : null;

  return (
    <div className="message__upload-container">
      <div
        className={`message__reply-audio reply ${
          replyObject?.Type === "incoming" ? "incoming" : "outcoming"
        }`}
      >
        <div className="reply__text-part">
          <div className="reply__text">
            <p className="reply-text">
              {templateReplyMessage ? (
                <React.Fragment>
                  {templateReplyMessage["BODY"]?.text ||
                    templateReplyMessage.text ||
                    ""}
                  <br />
                  <span
                    style={{
                      opacity: 0.65,
                      display: "inline-block",
                      marginTop: 5,
                    }}
                  >
                    template
                  </span>
                </React.Fragment>
              ) : (
                replyObject?.Message || ""
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReplyButtonMessage;
