import React, { useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import DropdownIndicator from "../../modules/DropdownIndicator";
import { UseFetchPost } from "../../hooks/fetchFunctions";
import { Dialog, DialogContent } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useLocalization } from "../Context/LocalizationContext";

function ChatUsersFireberrySync({
  open,
  handleOpenState,
  selectedUsersFireberrySync,
  setSelectedUsersFireberrySync,
}) {
  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.settingsPopup.chatUsers.fireberrySync,
    [currentLocale],
  );

  const [isSaving, setIsSaving] = useState(false);

  const objects = [
    {
      value: "contact",
      label: localization.objectOptions.contact,
    },
    {
      value: "account",
      label: localization.objectOptions.account,
    },
    {
      value: "both",
      label: localization.objectOptions.both,
    },
  ];
  const resultActions = [
    {
      value: "create",
      label: localization.resultSelectOptions.create,
    },
    {
      value: "doNothing",
      label: localization.resultSelectOptions.doNothing,
    },
  ];

  const [data, setData] = useState({
    object: objects[0].value,
    isReplaceFunnerNameWithFireberry: true,
    resultAction: resultActions[0].value,
  });

  const dataRef = useRef({
    object: objects[0].value,
    isReplaceFunnerNameWithFireberry: true,
    resultAction: resultActions[0].value,
  });

  const closePopup = () => {
    handleOpenState(false);
    clearSelections();
  };
  const clearSelections = () => {
    setSelectedUsersFireberrySync([]);
    setData({
      object: objects[0].value,
      isReplaceFunnerNameWithFireberry: true,
      resultAction: resultActions[0].value,
    });
  };

  const handleDataParam = (param, value) => {
    setData((prevObj) => {
      const newObj = { ...prevObj };
      newObj[param] = value;
      return newObj;
    });
    dataRef.current[param] = value;
  };

  async function submitFireberrySync() {
    try {
      setIsSaving(true);
      const usersPhoneArray = selectedUsersFireberrySync.map((user) => {
        return user.UserDisplayPhoneNumber;
      });
      const params = {
        synMode: dataRef.current.object,
        setPrimary: dataRef.current.isReplaceFunnerNameWithFireberry,
        createOnFirebrry: dataRef.current.resultAction === "create",
        ChatUsersList: usersPhoneArray,
      };
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const res = await UseFetchPost(
        "/api/services/SyncChatUserWithFireberry",
        params,
        "history",
      );

      console.log(res);
      if (res.data.result) {
        enqueueSnackbar(localization.notifications.successSave, {
          variant: "success",
        });
        closePopup();
      } else {
        enqueueSnackbar(localization.notifications.error, { variant: "error" });
      }
    } catch (error) {
      console.log("submitFireberrySync error: ", error);
      enqueueSnackbar(localization.notifications.error, { variant: "error" });
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      fullWidth
      PaperProps={{
        sx: { maxWidth: 650, background: "#f5f5f5" },
      }}
      className="priority-documents-popup priority-documents-popup--no-style"
    >
      <DialogContent
        sx={{
          pt: 8.75,
          "*": {
            boxSizing: "border-box",
          },
        }}
      >
        <div
          className="priority-documents-popup__close funner-icon"
          onClick={closePopup}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
          </svg>
        </div>

        <div className="priority-documents-popup__body">
          <div className="chat-users-fireberry-sync">
            <div className="chat-users-fireberry-sync__title">
              {localization.title}
            </div>
            <div className="chat-users-fireberry-sync__text">
              {localization.subtitle}
            </div>
            <div
              className="chat-users-fireberry-sync__sel-wrapper"
              style={{ position: "relative" }}
            >
              <span className="input-title">
                {localization.objectSelectTopLabel}
              </span>
              <ReactSelect
                options={objects}
                isClearable={false}
                // isRtl={true}
                // hideSelectedOptions={true}
                value={objects.find((object) => object.value === data.object)}
                onChange={(option) => {
                  handleDataParam("object", option.value);
                }}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#0000003B",
                    borderRadius: "4px",
                    color: "#212121",
                    fontSize: "14px",
                    lineHeight: "18px",
                    letterSpacing: "0.15px",
                    cursor: "pointer",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#212121",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    cursor: "pointer",
                    color: "#212121",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "4px",
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(60, 174, 241, 0.25)",
                    primary50: "rgba(60, 174, 241, 0.5)",
                    primary75: "rgba(60, 174, 241, 0.75)",
                    primary: "rgb(60, 174, 241)",
                  },
                })}
              />
            </div>
            <div className="chat-users-fireberry-sync__title">
              {localization.checkboxTitle}
            </div>
            <div className="chat-users-fireberry-sync__checkbox checkbox-small-gray">
              <input
                type="checkbox"
                name=""
                checked={data.isReplaceFunnerNameWithFireberry}
                onChange={(e) =>
                  handleDataParam(
                    "isReplaceFunnerNameWithFireberry",
                    e.target.checked,
                  )
                }
                id="chat-users-fireberry-sync__check"
                className="checkbox-small-gray__input"
              />
              <label
                className="checkbox-small-gray__label"
                htmlFor="chat-users-fireberry-sync__check"
              >
                <span className="checkbox-small-gray__marker">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.7583 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.7583 0.5 13.8333 0.5ZM6.33333 12.1667L2.16667 8L3.34167 6.825L6.33333 9.80833L12.6583 3.48333L13.8333 4.66667L6.33333 12.1667Z"
                      fill="#2A3847"
                      fillOpacity="0.62"
                    />
                  </svg>
                </span>
                <span className="checkbox-small-gray__text">
                  {localization.checkboxText}
                </span>
              </label>
            </div>
            <div className="chat-users-fireberry-sync__title">
              {localization.resultSelectTitle}
            </div>
            <div
              className="chat-users-fireberry-sync__sel-wrapper"
              style={{ position: "relative" }}
            >
              <span className="input-title">
                {localization.resultSelectTopLabel}
              </span>
              <ReactSelect
                options={resultActions}
                isClearable={false}
                // isRtl={true}
                // hideSelectedOptions={true}
                value={resultActions.find(
                  (action) => action.value === data.resultAction,
                )}
                onChange={(option) => {
                  handleDataParam("resultAction", option.value);
                }}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#0000003B",
                    borderRadius: "4px",
                    color: "#212121",
                    fontSize: "14px",
                    lineHeight: "18px",
                    letterSpacing: "0.15px",
                    cursor: "pointer",
                  }),
                  singleValue: (provided, state) => ({
                    ...provided,
                    color: "#212121",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    cursor: "pointer",
                    color: "#212121",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "4px",
                  colors: {
                    ...theme.colors,
                    primary25: "rgba(60, 174, 241, 0.25)",
                    primary50: "rgba(60, 174, 241, 0.5)",
                    primary75: "rgba(60, 174, 241, 0.75)",
                    primary: "rgb(60, 174, 241)",
                  },
                })}
              />
            </div>
            <div className="chat-users-fireberry-sync__buttons-row">
              <div
                className="popup__blue-btn popup__blue-btn--outline h42"
                style={{
                  width: "calc(50% - 21px)",
                  flex: "0 0 calc(50% - 21px)",
                  maxWidth: "calc(50% - 21px)",
                }}
                onClick={closePopup}
              >
                {localization.buttons.close}
              </div>
              <div
                className={`popup__blue-btn h42 ${
                  isSaving ? "_disabled-element" : ""
                }`}
                style={{
                  width: "calc(50% - 21px)",
                  flex: "0 0 calc(50% - 21px)",
                  maxWidth: "calc(50% - 21px)",
                }}
                onClick={submitFireberrySync}
                disabled={isSaving}
              >
                {localization.buttons.submit}
              </div>
            </div>

            {/* <div className="chat-users-fireberry-sync__title">
                איך זה עובד?{" "}
              </div>
              <div className="chat-users-fireberry-sync__text">
                בלחיצה על “אישור” פאנר מחפשת רשומות באובייקט לקוח ו/או איש קשר
                עם מספר טלפון זהה.
              </div>
              <div className="chat-users-fireberry-sync__text">
                החיפוש מתבצע לפי כל הפורמטים האפשריים.
              </div> */}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ChatUsersFireberrySync;
