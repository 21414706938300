import {
  Box,
  Button,
  Divider,
  Fab,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  Zoom,
} from "@mui/material";
import React from "react";
import { applicationType } from "../../applicationType";
import ChatUserCard from "./ChatUserCard";
import { KeyboardArrowUp } from "@mui/icons-material";
import { FixedSizeList } from "react-window";
import { useLocalization } from "../Context/LocalizationContext";

const ChatUserListItem = ({ index, style, data }) => {
  const {
    usersToDisplay,
    selectedFunnerChatUser,
    selectedChatUsersList,
    userConfig,
    handleAdditionalMenuClick,
    onUserCardClick,
    funnerUsers,
    chatUsersCustomizationSettings,
    chatUsersCustomizationAdditionalSettings,
    chatUsersLabelsList,
  } = data;
  const user = usersToDisplay[index];

  return (
    <div style={style}>
      <ChatUserCard
        key={user.Id || user.UserDisplayPhoneNumber}
        user={user}
        selected={
          selectedFunnerChatUser?.UserDisplayPhoneNumber ===
            user.UserDisplayPhoneNumber ||
          selectedChatUsersList.some((item) => item.Id === user.Id)
        }
        showAvatar={
          userConfig.isAvatarActive === undefined ||
          userConfig.isAvatarActive === null
            ? true
            : userConfig.isAvatarActive
        }
        handleAdditionalMenuClick={handleAdditionalMenuClick}
        onUserCardClick={onUserCardClick}
        funnerUsers={funnerUsers}
        chatUsersCustomizationSettings={chatUsersCustomizationSettings}
        chatUsersCustomizationAdditionalSettings={
          chatUsersCustomizationAdditionalSettings
        }
        chatUsersLabelsList={chatUsersLabelsList}
      />
    </div>
  );
};

const ChatUsersList = React.memo(
  ({
    chatUsersListRef,
    handleLoadContactsOnScroll,
    IsShowAllChatUsers,
    isMyUsersChats,
    chatUsersFunner,
    selectedFunnerChatUser,
    selectedChatUsersList,
    userConfig,
    handleAdditionalMenuClick,
    onUserCardClick,
    funnerUsers,
    chatUsersCustomizationSettings,
    chatUsersCustomizationAdditionalSettings,
    chatUsersLabelsList,
    isContactsLoaded,
    showAvatar,
    unreadHint,
    goToTopTrigger,
    scrollChatUsersTop,
  }) => {
    const { currentLocale, langugageDirection } = useLocalization();

    const theme = useTheme();
    const matchesBigMobile = useMediaQuery(theme.breakpoints.down(768));
    const matchesMobile = useMediaQuery(theme.breakpoints.down(480));

    let usersToDisplay = React.useMemo(() => {
      if (IsShowAllChatUsers || IsShowAllChatUsers === null) {
        return isMyUsersChats
          ? chatUsersFunner?.myChats
          : chatUsersFunner?.notMyChats;
      } else {
        return chatUsersFunner?.myChats;
      }
    }, [IsShowAllChatUsers, chatUsersFunner, isMyUsersChats]);

    const [calculatedHeight, setCalculatedHeight] = React.useState(0);

    React.useEffect(() => {
      const calculateHeight = () => {
        const heightValue =
          applicationType === "extension"
            ? "calc(70vh - 125px)"
            : "calc(100svh - 157px)";
        const tempDiv = document.createElement("div");

        tempDiv.style.height = heightValue;
        document.body.appendChild(tempDiv);
        const computedHeight = parseFloat(
          window.getComputedStyle(tempDiv).height,
        );
        setCalculatedHeight(computedHeight);
        document.body.removeChild(tempDiv);
      };

      calculateHeight();

      window.addEventListener("resize", calculateHeight);

      return () => {
        window.removeEventListener("resize", calculateHeight);
      };
    }, []);

    return (
      <Box
        sx={{
          flex: "1 1 auto",
          position: "relative",
          "& .funner-chat-users-scroll-list": {
            "&::-webkit-scrollbar": {
              width: 10,
              padding: 0,
              // bgcolor: "transparent !important",
            },
            "&::-webkit-scrollbar-track": {
              padding: 0,
              width: "100%",
            },
            "&::-webkit-scrollbar-thumb": {
              border: "4px solid transparent",
            },
          },
        }}
      >
        {usersToDisplay?.length ? (
          <FixedSizeList
            style={{
              position: "relative",
              minHeight: applicationType === "extension" ? 375 : null,
              backgroundColor: "#fff",
              direction: langugageDirection,
            }}
            className="funner-chat-users-scroll-list"
            outerRef={chatUsersListRef}
            itemCount={usersToDisplay.length}
            itemSize={85}
            // height={400}
            height={calculatedHeight}
            onScroll={(scrollProps) => handleLoadContactsOnScroll(scrollProps)}
            width="100%"
            itemData={{
              usersToDisplay,
              selectedFunnerChatUser,
              selectedChatUsersList,
              userConfig,
              handleAdditionalMenuClick,
              onUserCardClick,
              funnerUsers,
              chatUsersCustomizationSettings,
              chatUsersCustomizationAdditionalSettings,
              chatUsersLabelsList,
            }}
          >
            {ChatUserListItem}
          </FixedSizeList>
        ) : isContactsLoaded ? (
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "500 !important",
              my: 2,
            }}
          >
            {currentLocale.chat.chatUsers.noUsersFound}
          </Typography>
        ) : (
          <List
            component="nav"
            ref={chatUsersListRef}
            // onScroll={handleLoadContactsOnScroll}
            sx={{
              position: "relative",
              height:
                applicationType === "extension"
                  ? "calc(70vh - 125px)"
                  : "calc(100svh - 157px)",
              minHeight: applicationType === "extension" ? "375px" : null,
              overflow: "auto",
              bgcolor: "common.white",
              "&::-webkit-scrollbar": {
                width: 10,
                padding: 0,
              },
              "&::-webkit-scrollbar-track": {
                padding: 0,
                width: "100%",
              },
              "&::-webkit-scrollbar-thumb": {
                borderWidth: 1,
              },
            }}
          >
            {[...Array(15)].map((_, index) => {
              return (
                <Box key={index}>
                  <ListItemButton
                    sx={{
                      p: 1.5,
                      height: 84,
                    }}
                  >
                    {showAvatar && (
                      <ListItemAvatar>
                        <Skeleton
                          variant="circular"
                          sx={{
                            width:
                              applicationType === "extension" ||
                              matchesBigMobile
                                ? 40
                                : 55,
                            height:
                              applicationType === "extension" ||
                              matchesBigMobile
                                ? 40
                                : 55,
                            mr:
                              applicationType === "extension" ||
                              matchesBigMobile
                                ? 1
                                : 1.5,
                          }}
                        />
                      </ListItemAvatar>
                    )}

                    <ListItemText
                      primary={
                        <Grid
                          container
                          columnSpacing={2}
                          rowSpacing={0}
                          flexWrap={"nowrap"}
                        >
                          <Grid item flexGrow={1}>
                            <Box sx={{ mb: 1 }}>
                              <Grid
                                container
                                spacing={1.5}
                                alignItems={"center"}
                                flexWrap={"nowrap"}
                              >
                                <Grid item xs="auto">
                                  <Skeleton
                                    variant="circular"
                                    sx={{
                                      width: 6,
                                      height: 6,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs="auto">
                                  <Skeleton
                                    variant="rounded"
                                    sx={{
                                      width:
                                        applicationType === "extension" &&
                                        showAvatar
                                          ? 80
                                          : 100,
                                      height: 24,
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  />
                                </Grid>
                                <Grid item xs="auto">
                                  <Skeleton
                                    variant="rounded"
                                    sx={{
                                      width: 80,
                                      height: 24,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Box>
                              <Grid
                                container
                                columnSpacing={1}
                                flexWrap={"nowrap"}
                              >
                                <Grid item xs={12} sx={{ mt: -0.375 }}>
                                  <Grid item flexGrow={1}>
                                    <Skeleton
                                      variant="square"
                                      sx={{
                                        maxWidth: "100%",
                                        width: "100%",
                                        height: 15,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid item xs="auto">
                            <Box
                              sx={{
                                width: 120,
                                [theme.breakpoints.down(480)]: {
                                  width: 100,
                                },
                              }}
                            >
                              <Grid
                                container
                                spacing={
                                  applicationType === "extension" ||
                                  matchesBigMobile
                                    ? 0.5
                                    : 1
                                }
                                direction={"column"}
                              >
                                <Grid item sx={{ mt: -0.25 }}>
                                  <Grid
                                    container
                                    spacing={0}
                                    alignItems={"center"}
                                    wrap="nowrap"
                                  >
                                    <Grid item flexGrow={1}>
                                      <Box
                                        sx={{
                                          width: 80,
                                          [theme.breakpoints.down(480)]: {
                                            width: 28,
                                            ml: "auto",
                                            mr: 1,
                                          },
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                          }}
                                        >
                                          <Skeleton
                                            variant="circular"
                                            sx={{
                                              width: "18px !important",
                                              height: "18px !important",
                                              fontSize: "11px !important",

                                              [theme.breakpoints.down(480)]: {
                                                width: "28px !important",
                                                height: "28px !important",
                                                fontSize: "16px !important",
                                              },
                                            }}
                                          />
                                          {!matchesMobile && (
                                            <Skeleton
                                              variant="rounded"
                                              height={20}
                                              sx={{
                                                flex: "1 1 auto",
                                              }}
                                            />
                                          )}
                                        </Box>
                                      </Box>
                                    </Grid>
                                    <Grid item></Grid>
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Box sx={{ pr: 1 }}>
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems={"center"}
                                      justifyContent={"flex-end"}
                                    >
                                      <Grid item xs="auto">
                                        <Skeleton
                                          variant="rounded"
                                          sx={{
                                            width: 70,
                                            height: 20,
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      }
                      sx={{ my: 0 }}
                    />
                  </ListItemButton>
                  <Divider sx={{ width: "100%" }} />
                </Box>
              );
            })}
          </List>
        )}

        {/* <List
          component="nav"
          ref={chatUsersListRef}
          onScroll={handleLoadContactsOnScroll}
          sx={{
            position: "relative",
            height:
              applicationType === "extension"
                ? "calc(70vh - 125px)"
                : "calc(100svh - 157px)",
            minHeight: applicationType === "extension" ? "375px" : null,
            overflow: "auto",
            bgcolor: "common.white",
            "&::-webkit-scrollbar": {
              width: 10,
              padding: 0,
            },
            "&::-webkit-scrollbar-track": {
              padding: 0,
              width: "100%",
            },
            "&::-webkit-scrollbar-thumb": {
              borderWidth: 1,
            },
          }}
        > */}

        {/* <>
          {(() => {
            return usersToDisplay?.length ? (
              usersToDisplay.map((user) => (
                <ChatUserCard
                  key={user.Id || user.UserDisplayPhoneNumber}
                  user={user}
                  selected={
                    selectedFunnerChatUser?.UserDisplayPhoneNumber ===
                      user.UserDisplayPhoneNumber ||
                    selectedChatUsersList.some((item) => item.Id === user.Id)
                  }
                  showAvatar={
                    userConfig.isAvatarActive === undefined ||
                    userConfig.isAvatarActive === null
                      ? true
                      : userConfig.isAvatarActive
                  }
                  handleAdditionalMenuClick={handleAdditionalMenuClick}
                  onUserCardClick={onUserCardClick}
                  funnerUsers={funnerUsers}
                  chatUsersCustomizationSettings={
                    chatUsersCustomizationSettings
                  }
                  chatUsersCustomizationAdditionalSettings={
                    chatUsersCustomizationAdditionalSettings
                  }
                  chatUsersLabelsList={chatUsersLabelsList}
                />
              ))
            ) : isContactsLoaded ? (
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "500 !important",
                  my: 2,
                }}
              >
                {currentLocale.chat.chatUsers.noUsersFound}
              </Typography>
            ) : (
              [...Array(15)].map((_, index) => {
                return (
                  <Box key={index}>
                    <ListItemButton
                      sx={{
                        p: 1.5,
                        height: 84,
                      }}
                    >
                      {showAvatar && (
                        <ListItemAvatar>
                          <Skeleton
                            variant="circular"
                            sx={{
                              width:
                                applicationType === "extension" ||
                                matchesBigMobile
                                  ? 40
                                  : 55,
                              height:
                                applicationType === "extension" ||
                                matchesBigMobile
                                  ? 40
                                  : 55,
                              mr:
                                applicationType === "extension" ||
                                matchesBigMobile
                                  ? 1
                                  : 1.5,
                            }}
                          />
                        </ListItemAvatar>
                      )}

                      <ListItemText
                        primary={
                          <Grid
                            container
                            columnSpacing={2}
                            rowSpacing={0}
                            flexWrap={"nowrap"}
                          >
                            <Grid item flexGrow={1}>
                              <Box sx={{ mb: 1 }}>
                                <Grid
                                  container
                                  spacing={1.5}
                                  alignItems={"center"}
                                  flexWrap={"nowrap"}
                                >
                                  <Grid item xs="auto">
                                    <Skeleton
                                      variant="circular"
                                      sx={{
                                        width: 6,
                                        height: 6,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    <Skeleton
                                      variant="rounded"
                                      sx={{
                                        width:
                                          applicationType === "extension" &&
                                          showAvatar
                                            ? 80
                                            : 100,
                                        height: 24,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs="auto">
                                    <Skeleton
                                      variant="rounded"
                                      sx={{
                                        width: 80,
                                        height: 24,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>

                              <Box>
                                <Grid
                                  container
                                  columnSpacing={1}
                                  flexWrap={"nowrap"}
                                >
                                  <Grid item xs={12} sx={{ mt: -0.375 }}>
                                    <Grid item flexGrow={1}>
                                      <Skeleton
                                        variant="square"
                                        sx={{
                                          maxWidth: "100%",
                                          width: "100%",
                                          height: 15,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item xs="auto">
                              <Box
                                sx={{
                                  width: 120,
                                  [theme.breakpoints.down(480)]: {
                                    width: 100,
                                  },
                                }}
                              >
                                <Grid
                                  container
                                  spacing={
                                    applicationType === "extension" ||
                                    matchesBigMobile
                                      ? 0.5
                                      : 1
                                  }
                                  direction={"column"}
                                >
                                  <Grid item sx={{ mt: -0.25 }}>
                                    <Grid
                                      container
                                      spacing={0}
                                      alignItems={"center"}
                                      wrap="nowrap"
                                    >
                                      <Grid item flexGrow={1}>
                                        <Box
                                          sx={{
                                            width: 80,
                                            [theme.breakpoints.down(480)]: {
                                              width: 28,
                                              ml: "auto",
                                              mr: 1,
                                            },
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: 1,
                                            }}
                                          >
                                            <Skeleton
                                              variant="circular"
                                              sx={{
                                                width: "18px !important",
                                                height: "18px !important",
                                                fontSize: "11px !important",

                                                [theme.breakpoints.down(480)]: {
                                                  width: "28px !important",
                                                  height: "28px !important",
                                                  fontSize: "16px !important",
                                                },
                                              }}
                                            />
                                            {!matchesMobile && (
                                              <Skeleton
                                                variant="rounded"
                                                height={20}
                                                sx={{
                                                  flex: "1 1 auto",
                                                }}
                                              />
                                            )}
                                          </Box>
                                        </Box>
                                      </Grid>
                                      <Grid item></Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Box sx={{ pr: 1 }}>
                                      <Grid
                                        container
                                        spacing={2}
                                        alignItems={"center"}
                                        justifyContent={"flex-end"}
                                      >
                                        <Grid item xs="auto">
                                          <Skeleton
                                            variant="rounded"
                                            sx={{
                                              width: 70,
                                              height: 20,
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        }
                        sx={{ my: 0 }}
                      />
                    </ListItemButton>
                    <Divider sx={{ width: "100%" }} />
                  </Box>
                );
              })
            );
          })()}
        </> */}

        {/* </List> */}

        <Zoom in={unreadHint.enabled}>
          <Button
            onClick={scrollChatUsersTop}
            size="small"
            color="error"
            variant="contained"
            sx={{
              position: "absolute",
              top: 10,
              right: "50%",
              transform: "translateX(50%)",
              zIndex: 10,
              width: 28,
              minWidth: 28,
              minHeight: 28,
              height: 28,
              borderRadius: "50%",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: 0,
                height: 0,
                borderBottom: `6px solid ${theme.palette.error.main}`,
                borderLeft: "4px solid transparent",
                borderRight: "4px solid transparent",
                top: 0,
                left: 10,
                transform: "translateY(-75%)",
              }}
            ></div>
            {unreadHint.count}
          </Button>
        </Zoom>
        <Zoom in={goToTopTrigger}>
          <Fab
            onClick={scrollChatUsersTop}
            color="primary"
            size="medium"
            sx={{
              position: "absolute",
              bottom: 60,
              left: 10,
              zIndex: 10,
            }}
          >
            <KeyboardArrowUp />
          </Fab>
        </Zoom>
      </Box>
    );
  },
);

export default ChatUsersList;
