import React from "react";

// import "./styles/old.css";
// import "./styles/style.scss";
import { createRoot } from "react-dom/client";
import { applicationType } from "./applicationType.js";
import App from "./App.jsx";
import { LocalizationLanguageProvider } from "./views/Context/LocalizationContext.jsx";
import ErrorProcesser from "./modules/ErrorsProcesser.jsx";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://c5e52434f2200e69458924aece30b238@o4508596059373568.ingest.de.sentry.io/4508596145029200",
    integrations: [],
  });
} else {
  console.log("Sentry is disabled in development mode");
}

const renderRootComponent = async (element) => {
  // await require("./styles/old.css");
  await require("./styles/old.scss");
  await require("./styles/style.scss");

  const insertNode = document.createElement("div");
  insertNode.className = "root-whatsapp chat w-0";
  if (applicationType === "extension") {
    const extensionAddtionalWrapper = document.createElement("div");
    extensionAddtionalWrapper.classList.add(
      "_funner-global-messanger-container",
    );

    extensionAddtionalWrapper.appendChild(insertNode);
    element.appendChild(extensionAddtionalWrapper);
  } else {
    element.appendChild(insertNode);
  }

  const root = createRoot(insertNode);
  root.render(
    <LocalizationLanguageProvider>
      <ErrorProcesser>
        <App />
      </ErrorProcesser>
    </LocalizationLanguageProvider>,
  );
};

if (
  !(
    applicationType === "extension" &&
    (document.body.classList.contains("funner-global-web-app-page") ||
      document.documentElement.classList.contains("funner-global-web-app-page"))
  )
) {
  // renderRootComponentOnMount(".root-whatsapp.chat");
  const isFireberryPage = window.location.href.includes("app.fireberry.com");
  if (isFireberryPage) {
    let i = 0;
    const startTime = new Date();
    const isFireberryInterval = setInterval(() => {
      if (document.querySelector("[data-jss][data-meta]")) {
        // console.log(document.querySelector("[data-jss][data-meta]"));
        renderRootComponent(document.documentElement);
        // const endTime = new Date();
        // console.log(endTime - startTime);
        clearInterval(isFireberryInterval);
      } else if (i >= 40) {
        renderRootComponent(document.documentElement);
        clearInterval(isFireberryInterval);
      }
      i++;
    }, 50);
  } else {
    renderRootComponent(
      applicationType === "extension"
        ? document.documentElement
        : document.body,
    );
  }
}

window.addEventListener("error", (e) => {
  if (
    e.message === "ResizeObserver loop limit exceeded" ||
    e.message ===
      "ResizeObserver loop completed with undelivered notifications."
  ) {
    const resizeObserverErrDiv = document.getElementById(
      "webpack-dev-server-client-overlay-div",
    );
    const resizeObserverErr = document.getElementById(
      "webpack-dev-server-client-overlay",
    );
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute("style", "display: none");
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute("style", "display: none");
    }
  }
});
