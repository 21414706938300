import * as Sentry from "@sentry/react";
import React from "react";
import AppLoadError from "../views/TechPages/AppLoadError";
import { getFromLocal, saveToLocal } from "../hooks/localStorage";
import { UseFetchPost } from "../hooks/fetchFunctions";

function FallbackComponent() {
  return (
    <AppLoadError
      message={"Oops, some error occured. Please try to reload page"}
      removeTokenBtn={false}
    />
  );
}

function ErrorProcesser({ children }) {
  const sendErrorToServer = async (error, errorInfo) => {
    const savedUserData = getFromLocal("userData");

    await UseFetchPost(
      "/api/services/saveFrontEndLog",
      {
        log: [
          {
            companyId: savedUserData?.CompanyId || "",
            userId: savedUserData?.Udid || "",
            action: "errorBoundary",
            date: new Date().toISOString(),
            additionalData: {
              name: error.name || "UnknownError",
              message: error.message || "No message provided",
              stack: errorInfo?.componentStack || "No stack trace available",
            },
          },
        ],
        logName: "ErrorBoundaryLog",
      },
      "priority",
    );
  };

  return (
    <Sentry.ErrorBoundary
      fallback={<FallbackComponent />}
      onError={(error, errorInfo) => {
        sendErrorToServer(error, errorInfo);
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorProcesser;
