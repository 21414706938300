import { Box, styled } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MarkdownContainer = styled(Box)({
  "&": {
    // fontFamily: "Arial, sans-serif",
    // lineHeight: 1.6,
    // color: #333,
  },

  "& h1": {
    fontWeight: "700 !important",
  },
  "& h2": {
    fontWeight: "700 !important",
  },
  "& h3": {
    fontWeight: "700 !important",
  },

  "& code": {
    backgroundColor: "#f4f4f4",
    padding: "2px 4px",
    borderRadius: "4px",
    fontFamily: "monospace !important",
  },

  "& pre": {
    backgroundColor: "#f4f4f4",
    padding: "10px",
    borderRadius: "4px",
    overflowX: "auto",
  },

  "& a": {
    color: "#007acc",
    textDecoration: "none",
  },

  "& a:hover": {
    textDecoration: "underline",
  },
});

const MarkdownRenderer = ({ markdownText }) => {
  return (
    <MarkdownContainer>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownText}</ReactMarkdown>
    </MarkdownContainer>
  );
};

export default MarkdownRenderer;
