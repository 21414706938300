import React, { useState } from "react";
import { useLocalization } from "../../Context/LocalizationContext";

export default function GetToken({
  getPowerLinkToken,
  handleCloseGetTokenPopup,
}) {
  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.auth.getToken,
    [currentLocale],
  );

  return (
    <>
      {/* <Header handleCloseGetTokenPopup={handleCloseGetTokenPopup}/> */}
      <div className="get-token-popup__window get-token-popup__window_1">
        <p className="get-token-popup__main-text">{localization.text}</p>
        <button
          onClick={getPowerLinkToken}
          className="get-token-popup__main-btn popup__blue-btn"
        >
          {localization.btn}
        </button>
      </div>
    </>
  );
}
