import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { applicationType } from "../applicationType";
// import SettingsPopup from "./Settings/SettingsPopup";
import FunnerGlobalMessanger from "./Funner/FunnerGlobalMessanger";
import { UseFetchGet, UseFetchPost } from "../hooks/fetchFunctions";
import { hintLogo, uploadPreview } from "../libs/images";
import FireberryPlatform from "./FireberryPlatform/FireberryPlatform";
import { sendMessageToBackground } from "../hooks/extensionActions";
import {
  clearPerformanceLog,
  getCurrentDateFormatted,
  safeParseJSON,
  writePerformanceLog,
} from "../libs/helpers";
import PriorityPlatform from "./PriorityPlatform/PriorityPlatform";
import { sortListByParam } from "../libs/messages";
import { NotificationIcon } from "../modules/NotificationContext";
import { enqueueSnackbar } from "notistack";
import { Box, Grid } from "@mui/material";
import { getFromLocal } from "../hooks/localStorage";
import { useLocalization } from "./Context/LocalizationContext";

const SettingsPopup =
  applicationType === "web"
    ? React.lazy(() => import("./Settings/SettingsPopup"))
    : null;

let isContactsLoading = false;

const Main = React.memo(
  ({
    token,
    iframeMode,
    userData,
    updateUserData,
    userDataRef,
    userConfig,
    userConfigRef,
    businessInfo,
    setBusinessInfo,
    businessInfoRef,
    handleRemoveToken,
    handleSetNotif,
    handleBrowserNotification,
    updateUserConfig,
    updateUserConfigWithObject,
    connection,
    isConnection,
    tabActiveRef,
    setIsActiveAppOnPage,
    handleApplicationUserProfile,
    isPowerLinkPage,
    handleSubmitType,
    isActiveAppOnPageRef,
    browserNotifPermissionRef,
    handleSetToken,
    funnerMessangerRef,
  }) => {
    const { currentLocale } = useLocalization();

    //! Config and Info
    const [templatesList, setTemplatesList] = useState({
      templates: [],
      whatsappTemplates: [],
      languages: [],
    });
    const [selectedUsersFireberrySync, setSelectedUsersFireberrySync] =
      useState([]);
    const [selectedUsersPrioritySync, setSelectedUsersPrioritySync] = useState(
      [],
    );
    const [funnerUsers, setFunnerUsers] = useState([]);
    const [chatUsersLabelsList, setChatUsersLabelsList] = useState(null);
    const [chatUsersGlobal, setChatUsersGlobal] = useState({
      myChats: [],
      notMyChats: [],
    });
    const [chatUsersFunner, setChatUsersFunner] = useState({
      myChats: [],
      notMyChats: [],
    });
    const [getContactsQueryParams, setGetContactsQueryParams] = useState({
      pageOffset: 1,
      searchQuery: "",
      label: "",
      open: "all",
      isOnlyUnread: false,
      assigneeFilter: [],
      dontDisturb: false,
    });
    const getContactsQueryParamsRef = useRef({
      pageOffset: 1,
      searchQuery: "",
      label: "",
      open: "all",
      isOnlyUnread: false,
      assigneeFilter: [],
      dontDisturb: false,
    });

    const [selectedFunnerChatUser, setSelectedFunnerChatUser] = useState(null);
    const [selectedFireberryChatUser, setSelectedFireberryChatUser] =
      useState(null);
    const [userInSettingsCreationState, setUserInSettingsCreationState] =
      useState(null);
    const [isMyUsersChats, setMyUsersChat] = useState(false);
    const [goToFavouriteMessageState, setGoToFavouriteMessageState] =
      useState(null);

    //* Priority
    const [disableChatGoBack, setDisableChatGoBack] = useState(false);

    const [totalUnredMessages, setTotalUnredMessages] = useState(null);
    const [totalUnreadChats, setTotalUnreadChats] = useState(null);

    //! App modules config
    //* Sound Notification
    // const [isNotifOn, setIsNotifOn] = useState(false);
    const savedActiveTab = getFromLocal("settings-active-tab");
    const [activeTab, setActiveTab] = useState(
      savedActiveTab
        ? savedActiveTab
        : applicationType === "web"
        ? "chat"
        : "about",
    );

    //! Popups and other view states
    const [isPriorityDocumentWindow, setPriorityDocumentWindow] =
      useState(false);
    const [isPrioritySendDocsWindow, setPrioritySendDocsWindow] =
      useState(false);
    const [isUsersFireberrySyncWindow, setUsersFireberrySyncWindow] =
      useState(false);
    const [isUsersPrioritySyncWindow, setUsersPrioritySyncWindow] =
      useState(false);
    const [isNavOpen, setNavOpen] = useState(false);
    const [templatesLoading, setTemplatesLoading] = useState(false);
    const isChatOpenLocalStorage = JSON.parse(
      localStorage.getItem("globalFunnerMessangerOpenStatus"),
    );
    const [openUnreadMessagesPopup, setOpenUnreadMessagesPopup] = useState(
      applicationType === "web"
        ? true
        : isChatOpenLocalStorage
        ? isChatOpenLocalStorage.isChatOpen
        : false,
    );
    const [sendUploadPriority, setSendUploadPriority] = useState({
      isUpload: false,
      file: "",
      fileTypeImg: uploadPreview,
    });
    const handleSendPriorityUploadState = React.useCallback((params) => {
      setSendUploadPriority((prevObj) => {
        return { ...prevObj, ...params };
      });
    }, []);
    const resetSendUploadPriority = React.useCallback(() => {
      handleSendPriorityUploadState({
        isUpload: false,
        file: "",
        fileTypeImg: uploadPreview,
      });
    }, [handleSendPriorityUploadState]);

    //! Refs
    const notifSound = useRef(null);
    const templatesListRef = useRef(null);
    const chatUsersGlobalRef = useRef({
      myChats: [],
      notMyChats: [],
    });
    const chatUsersFunnerRef = useRef({
      myChats: [],
      notMyChats: [],
    });
    const chatUsersSavedViewsRef = React.useRef({
      chatMy: null,
      chatNotMy: null,
      chatNotAssigned: null,
      chatBots: null,
      chatAll: null,
    });
    const chatUsersMessagesRef = useRef([]);
    const prevViewRef = useRef(null);
    const selectedFunnerChatUserRef = useRef(null);
    const selectedFireberryChatUserRef = useRef(null);
    const hintNotifContainerRef = useRef(null);
    const hintNotifContainerRoot = useRef(null);
    const appButtonsRef = useRef(null);
    const goToFavouriteMessageStateRef = useRef(null);

    //! Control functions and handlers
    //* Popups and other view states control
    const handlePriorityDocumentsWindow = React.useCallback((state) => {
      setPriorityDocumentWindow(state);
    }, []);
    const handlePrioritySendDocsWindow = React.useCallback((state) => {
      setPrioritySendDocsWindow(state);
    }, []);
    const handleUsersFireberrySettingsWindow = React.useCallback((state) => {
      setUsersFireberrySyncWindow(state);
    }, []);
    const handleUsersPrioritySettingsWindow = React.useCallback((state) => {
      setUsersPrioritySyncWindow(state);
    }, []);
    const openUnreadMessagesChat = () => {
      const currentUnreadChat = document.querySelector(
        ".unread-messages-popup .single-chat-unread-messages.unread",
      );
      if (currentUnreadChat) {
        setOpenUnreadMessagesPopup(true);
        currentUnreadChat.click();
      }
    };
    const toggleUnreadMessagesPopup = React.useCallback(() => {
      setOpenUnreadMessagesPopup((prev) => !prev);
    }, []);
    const toggleNavPanel = React.useCallback(() => {
      setNavOpen((prev) => !prev);
    }, []);
    const closeNavPanel = React.useCallback(() => {
      setNavOpen(false);
    }, []);

    const updateChatUsersSavedViews = React.useCallback((params) => {
      chatUsersSavedViewsRef.current = {
        ...chatUsersSavedViewsRef.current,
        ...params,
      };

      // console.log(
      //   "updateChatUsersSavedViews: ",
      //   params,
      //   chatUsersSavedViewsRef.current,
      // );
    }, []);
    const updateGlobalChatUsersStates = React.useCallback(
      (myChats, notMyChats) => {
        setChatUsersGlobal((prevObj) => {
          return {
            ...prevObj,
            notMyChats: notMyChats,
            myChats: myChats,
          };
        });

        chatUsersGlobalRef.current.notMyChats = notMyChats;
        chatUsersGlobalRef.current.myChats = myChats;
      },
      [],
    );
    const updateFunnerChatUsersStates = React.useCallback(
      (myChats, notMyChats, viewType = null) => {
        if (!viewType || viewType === prevViewRef.current) {
          setChatUsersFunner((prevObj) => {
            return {
              ...prevObj,
              notMyChats: notMyChats,
              myChats: myChats,
            };
          });

          chatUsersFunnerRef.current.notMyChats = notMyChats;
          chatUsersFunnerRef.current.myChats = myChats;
        }

        if (viewType) {
          const currentView = {
            "chat-my": "chatMy",
            "chat-not-my": "chatNotMy",
            "chat-not-assigned": "chatNotAssigned",
            "chat-bots": "chatBots",
            chat: "chatAll",
          }[viewType];
          updateChatUsersSavedViews({
            [currentView]: notMyChats,
            chatMy: myChats,
          });
        }
      },
      [updateChatUsersSavedViews],
    );

    const handleSelectedFunnerChatUser = React.useCallback((user) => {
      setSelectedFunnerChatUser(user);
      selectedFunnerChatUserRef.current = user;
    }, []);
    const handleSelectedFireberryChatUser = React.useCallback((user) => {
      setSelectedFireberryChatUser(user);
      selectedFireberryChatUserRef.current = user;
    }, []);

    const updateChatUser = React.useCallback(
      (userId, newData, idParam = "UserDisplayPhoneNumber") => {
        const myChatUsersGlobal = chatUsersGlobalRef.current.myChats;
        const notMyChatUsersGlobal = chatUsersGlobalRef.current.notMyChats;
        const myChatUsersFunner = chatUsersFunnerRef.current.myChats;
        const notMyChatUsersFunner = chatUsersFunnerRef.current.notMyChats;

        updateGlobalChatUsersStates(
          myChatUsersGlobal.map((user) =>
            user[idParam] === userId ? { ...user, ...newData } : user,
          ),
          notMyChatUsersGlobal.map((user) =>
            user[idParam] === userId ? { ...user, ...newData } : user,
          ),
        );
        updateFunnerChatUsersStates(
          myChatUsersFunner.map((user) =>
            user[idParam] === userId ? { ...user, ...newData } : user,
          ),
          notMyChatUsersFunner.map((user) =>
            user[idParam] === userId ? { ...user, ...newData } : user,
          ),
        );

        if (
          selectedFunnerChatUserRef.current &&
          selectedFunnerChatUserRef.current.UserDisplayPhoneNumber === userId
        ) {
          handleSelectedFunnerChatUser({
            ...selectedFunnerChatUserRef.current,
            ...newData,
          });
        }

        if (
          selectedFireberryChatUserRef.current &&
          selectedFireberryChatUserRef.current.UserDisplayPhoneNumber === userId
        ) {
          handleSelectedFireberryChatUser({
            ...selectedFireberryChatUserRef.current,
            ...newData,
          });
        }
      },
      [
        handleSelectedFunnerChatUser,
        handleSelectedFireberryChatUser,
        updateGlobalChatUsersStates,
        updateFunnerChatUsersStates,
      ],
    );

    const handleChatUserMessages = React.useCallback((id, params) => {
      const findUserIndex = chatUsersMessagesRef.current.findIndex(
        (user) => user.id === id,
      );
      if (findUserIndex === -1) {
        chatUsersMessagesRef.current.push({
          id: id,
          ...params,
        });
      } else {
        Object.assign(chatUsersMessagesRef.current[findUserIndex], params);
      }

      // console.log(chatUsersMessagesRef.current);
    }, []);
    const clearChatUsersMessages = React.useCallback(() => {
      chatUsersMessagesRef.current = [];
    }, []);
    const handleGoToFavouriteMessageState = React.useCallback((state) => {
      setGoToFavouriteMessageState(state);
      goToFavouriteMessageStateRef.current = state;
    }, []);
    const handleGetContactsParams = React.useCallback((params) => {
      getContactsQueryParamsRef.current = {
        ...getContactsQueryParamsRef.current,
        ...params,
      };
      setGetContactsQueryParams((prevObj) => ({ ...prevObj, ...params }));
    }, []);

    function closeHintNotification() {
      hintNotifContainerRoot.current.unmount();
    }

    //! Async and fetch functions
    const getFunnerUsers = React.useCallback(async () => {
      try {
        const res = await UseFetchGet(
          "/api/auth/getApplicationUsers",
          undefined,
          null,
          undefined,
          "config",
        );
        if (res?.data?.users) {
          setFunnerUsers(res.data.users);
          return res.data.users;
        } else return [];
      } catch (error) {
        console.log("GetPowerLinkUserglobalList error: ", error);
        return [];
      }
    }, []);
    async function getCompanyLabelsList() {
      try {
        const res = await UseFetchGet(
          "/api/services/GetCompanyLabelsList",
          {},
          null,
          undefined,
          "history",
        );
        if (
          res.data.labelsList.labelsList &&
          res.data.labelsList.labelsList.length
        ) {
          const newLabelsList = JSON.parse(res.data.labelsList.labelsList);
          setChatUsersLabelsList(() => {
            return [
              {
                name: currentLocale.chat.chatHeader.filterLabel.noLabel,
                color: "#2a3847",
              },
              ...newLabelsList,
            ];
          });
        } else {
          setChatUsersLabelsList(() => {
            return [
              {
                name: currentLocale.chat.chatHeader.filterLabel.noLabel,
                color: "#2a3847",
              },
            ];
          });
        }
      } catch (err) {
        console.log(err);
        setChatUsersLabelsList(() => {
          return [
            {
              name: currentLocale.chat.chatHeader.filterLabel.noLabel,
              color: "#2a3847",
            },
          ];
        });
      }
    }

    const fetchWhatsappTemplates = useCallback(async () => {
      try {
        const { Udid } = userDataRef.current;
        setTemplatesLoading(true);
        const res = await UseFetchGet(
          "/api/services/GetWhatsappTemplates",
          {
            globaluserid: Udid,
          },
          null,
          undefined,
          "history",
        );
        // const fetchedTemplates = res?.data?.whatsappTemplates || [];
        const fetchedTemplates = res?.data?.whatsappOriginalTemplates || [];
        const fetchedWhatsappTemplates = res?.data?.whatsappTemplates || [];
        const languages = res?.data?.languages || [];

        setTemplatesList({
          templates: [...fetchedTemplates],
          whatsappTemplates: [...fetchedWhatsappTemplates],
          languages: [...languages],
        });
        templatesListRef.current = {
          templates: [...fetchedTemplates],
          whatsappTemplates: [...fetchedWhatsappTemplates],
          languages: [...languages],
        };
      } catch (err) {
        console.log("GetWhatsappTemplates error: ", err);
      } finally {
        setTemplatesLoading(false);
      }
    }, [userDataRef]);

    const getChatMissingHistory = React.useCallback(async (phoneNumber) => {
      try {
        const currentMsgs =
          chatUsersMessagesRef.current?.find((user) => user.id === phoneNumber)
            ?.messages || [];

        let dateFrom,
          dateTo,
          closestBufferIndex = -1;
        if (currentMsgs?.length) {
          closestBufferIndex = currentMsgs.findLastIndex(
            (msg) => msg.FileType === "buffer",
          );
          const lastMessage = currentMsgs[currentMsgs.length - 1];

          dateTo = lastMessage.DateCreatedOriginal;
          dateFrom =
            currentMsgs[closestBufferIndex !== -1 ? closestBufferIndex + 1 : 0]
              .DateCreatedOriginal;
        } else {
          const date = getCurrentDateFormatted();
          dateFrom = date;
          dateTo = date;
        }

        const filteredMessages =
          closestBufferIndex !== -1
            ? currentMsgs.slice(closestBufferIndex + 1)
            : currentMsgs;
        const res = await UseFetchPost(
          "/api/sendMessage/getMissingMessages",
          {
            date: dateFrom,
            dateTo: dateTo,
            ids: filteredMessages.map((msg) => msg.Id),
            chatUserPhoneNumberId: phoneNumber.startsWith("972")
              ? "0" + phoneNumber.slice(3)
              : phoneNumber,
          },
          "history",
        );

        if (res?.data?.messages) {
          return res.data.messages;
        } else {
          return [];
        }
      } catch (error) {
        console.log("getChatUpdateHistory err: ", error);
        return [];
      }
    }, []);

    //! App connection
    function onAppConnectedActions() {
      // getCompanyChatUsersGlobal();
      fetchWhatsappTemplates();
      getFunnerUsers();
      getCompanyLabelsList();
    }

    const getCompanyChatUsersGlobal = React.useCallback(async () => {
      try {
        const params = {
          page_number: 10000,
          page_offset: 0,
          filter_by_open: "all",
          order_by: "last_message_date",
          order_descending: true,
          search_term: "",
          label: "",
        };

        const res = await UseFetchPost(
          "/api/services/GetCompanyChatUsersAll",
          params,
          "history",
          // "finland",
        );
        if (res) {
          updateGlobalChatUsersStates(
            res.data?.myChatUsers?.chatUsers
              ? [...res.data.myChatUsers.chatUsers]
              : [],
            res.data?.allChatUsers?.chatUsers
              ? [...res.data.allChatUsers.chatUsers]
              : [],
          );

          return {
            notMyChats: res.data?.allChatUsers?.chatUsers
              ? [...res.data.allChatUsers.chatUsers]
              : [],
            myChats: res.data?.myChatUsers?.chatUsers
              ? [...res.data.myChatUsers.chatUsers]
              : [],
          };
        }
      } catch (err) {
        console.log(err);
      }
    }, [updateGlobalChatUsersStates]);

    const getCompanyChatUsers = React.useCallback(
      async (type = null) => {
        isContactsLoading = true;
        try {
          let params;
          if (getContactsQueryParamsRef.current.searchQuery.length) {
            params = {
              page_number: 30,
              page_offset: 0,
              filter_by_open: "all",
              order_by: "last_message_date",
              order_descending: true,
              search_term: getContactsQueryParamsRef.current.searchQuery,
              label: "",
              isOnlyUnread: false,
              assigneeFilter: [],
              dontDisturb: getContactsQueryParamsRef.current.dontDisturb,
              filters: getContactsQueryParamsRef.current.filters || [],
            };
          } else {
            params = {
              page_number: 30 * getContactsQueryParamsRef.current.pageOffset,
              page_offset: 0,
              filter_by_open: getContactsQueryParamsRef.current.open,
              order_by: "last_message_date",
              order_descending: true,
              search_term: getContactsQueryParamsRef.current.searchQuery,
              label: getContactsQueryParamsRef.current.label,
              isOnlyUnread: getContactsQueryParamsRef.current.isOnlyUnread,
              assigneeFilter: getContactsQueryParamsRef.current.assigneeFilter,
              dontDisturb: getContactsQueryParamsRef.current.dontDisturb,
              filters: getContactsQueryParamsRef.current.filters || [],
            };
          }

          const res = await UseFetchPost(
            "/api/services/GetCompanyChatUsersAll",
            params,
            "history",
            // "finland",
          );
          if (res) {
            updateFunnerChatUsersStates(
              res.data?.myChatUsers?.chatUsers
                ? [...res.data.myChatUsers.chatUsers]
                : // ? [...res.data.myChatUsers.chatUsers]

                  [],
              res.data?.allChatUsers?.chatUsers
                ? // ? [...res.data.allChatUsers.chatUsers]
                  [...res.data.allChatUsers.chatUsers]
                : [],
              type,
            );

            return {
              notMyChats: res.data?.allChatUsers?.chatUsers
                ? [...res.data.allChatUsers.chatUsers]
                : [],
              myChats: res.data?.myChatUsers?.chatUsers
                ? [...res.data.myChatUsers.chatUsers]
                : [],
            };
          }
        } catch (err) {
          console.log("GetCompanyChatUsersAll error: ", err);
        } finally {
          isContactsLoading = false;
        }
      },
      [updateFunnerChatUsersStates],
    );

    function initSocketListener() {
      connection.on("ReceiveMessage", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketReceiveMessage(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("socketReceiveMessage", sendContent);
        }
      });
      connection.on("MessageStatusUpdate", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketUpdateMessage(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("socketUpdateMessage", sendContent);
        }
      });
      connection.on("ReceiveTemplateMessage", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketReceiveTemplateMessage(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("socketReceiveTemplateMessage", sendContent);
        }
      });
      connection.on("unreadNotification", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketUnreadNotification(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("socketUnreadNotification", sendContent);
        }
      });
      connection.on("UnreadUpdate", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        console.log("UnreadUpdate: ", messageObject);

        onSocketUpdateChatUserUnread(messageObject);
        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };
          sendMessageToBackground("socketUpdateChatUserUnread", sendContent);
        }
      });
      // connection.on("updateChatUserUnread", async function (message) {
      //   const messageObject = safeParseJSON(message);
      //   if (!messageObject) return;
      //   onSocketUpdateChatUserUnread(messageObject);
      //   if (applicationType === "extension") {
      //     const sendContent = {
      //       messageObject: messageObject,
      //     };

      //     sendMessageToBackground("socketUpdateChatUserUnread", sendContent);
      //   }
      // });
      connection.on("updateChatUserUnread", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketUpdateChatUserUnread(messageObject);
        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("socketUpdateChatUserUnread", sendContent);
        }
      });
      connection.on("Notification", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;

        let getExtensionPermissionStatus = null;
        if (applicationType === "extension") {
          getExtensionPermissionStatus = await sendMessageToBackground(
            "checkNotifPerm",
          );
        }
        onSocketNotification(
          messageObject,
          getExtensionPermissionStatus?.permission || null,
        );

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          if (getExtensionPermissionStatus?.permission !== "granted")
            sendMessageToBackground("socketNotification", sendContent);
        }
      });
      connection.on("TaggedUsersNotification", async function (message) {
        console.log("TaggedUsersNotification");
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;

        let getExtensionPermissionStatus = null;
        if (applicationType === "extension") {
          getExtensionPermissionStatus = await sendMessageToBackground(
            "checkNotifPerm",
          );
        }
        onSocketTaggedUsersNotification(
          messageObject,
          getExtensionPermissionStatus?.permission || null,
        );

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          if (getExtensionPermissionStatus?.permission !== "granted")
            sendMessageToBackground(
              "socketTaggedUsersNotification",
              sendContent,
            );
        }
      });
      connection.on("labelUpdate", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketLabelUpdate(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("labelUpdate", sendContent);
        }
      });
      // connection.on("botStatusUpdate", async function (message) {
      //   const messageObject = JSON.parse(message);
      //   onSocketBotStatusUpdate(messageObject);

      //   if (applicationType === "extension") {
      //     const sendContent = {
      //       messageObject: messageObject,
      //     };

      //     sendMessageToBackground("botStatusUpdate", sendContent);
      //   }
      // });
      connection.on("conversationOpenStatus", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketConversationOpenStatus(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("conversationOpenStatus", sendContent);
        }
      });
      connection.on("funnerServiceNotification", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSockerFunnerServiceNotification(messageObject);

        if (applicationType === "extension") {
          const sendContent = {
            messageObject: messageObject,
          };

          sendMessageToBackground("funnerServiceNotification", sendContent);
        }
      });
      connection.on("UpdateChatUserDontDisturb", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketUpdateChatUserDontDisturb(messageObject);
      });
      connection.on("updateFunnerUserActive", async function (message) {
        const messageObject = safeParseJSON(message);
        if (!messageObject) return;
        onSocketUpdateFunnerUserActive(messageObject);
      });
    }
    function onSocketReceiveMessage(messageObject) {
      const savedUserData = getFromLocal("userData");
      try {
        if (applicationType === "extension")
          checkAndUpdateFireberryPerformanceLog();
        const newMessage = messageObject.messages[0];
        if (newMessage.FileType !== "info") {
          const targetNotMyUser = chatUsersGlobalRef.current.notMyChats.find(
            (user) =>
              user.UserDisplayPhoneNumber ===
              newMessage.resultChatUser?.UserDisplayPhoneNumber,
          );
          const currentNotMyUser = { ...newMessage.resultChatUser };

          let newGlobalNotMyUsersList = [],
            newFunnerNotMyUsersList = [];

          const isSameMessage =
            targetNotMyUser?.LastMessageType ===
              currentNotMyUser?.LastMessageType &&
            (currentNotMyUser?.LastMessageType === "outcoming"
              ? targetNotMyUser?.OutcomingLastMessageId ===
                currentNotMyUser?.OutcomingLastMessageId
              : targetNotMyUser?.incomingLastMessageId ===
                currentNotMyUser?.incomingLastMessageId);
          if (isSameMessage || newMessage.FileType === "note") {
            newGlobalNotMyUsersList = chatUsersGlobalRef.current.notMyChats.map(
              (user) => {
                if (user.UserDisplayPhoneNumber === messageObject.phone) {
                  return currentNotMyUser;
                }

                return user;
              },
            );
            newFunnerNotMyUsersList = chatUsersFunnerRef.current.notMyChats.map(
              (user) => {
                if (user.UserDisplayPhoneNumber === messageObject.phone) {
                  return currentNotMyUser;
                }

                return user;
              },
            );
          } else {
            newGlobalNotMyUsersList =
              chatUsersGlobalRef.current.notMyChats.filter(
                (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
              );
            newFunnerNotMyUsersList =
              chatUsersFunnerRef.current.notMyChats.filter(
                (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
              );
            newGlobalNotMyUsersList.unshift(currentNotMyUser);

            //! Relate inserting user with active filters
            insertUsersAccordingToFilters(
              currentNotMyUser,
              newFunnerNotMyUsersList,
            );
          }

          let currentMyUser = chatUsersGlobalRef.current.myChats.some(
            (user) => user.UserDisplayPhoneNumber === messageObject.phone,
          )
            ? {
                ...chatUsersGlobalRef.current.myChats.find(
                  (user) => user.UserDisplayPhoneNumber === messageObject.phone,
                ),
              }
            : null;
          let newGlobalMyUsersList = [...chatUsersGlobalRef.current.myChats];
          let newFunnerMyUsersList = [...chatUsersFunnerRef.current.myChats];

          if (currentMyUser) {
            if (isSameMessage) {
              newGlobalMyUsersList = chatUsersGlobalRef.current.myChats.map(
                (user) => {
                  if (user.UserDisplayPhoneNumber === messageObject.phone) {
                    return currentNotMyUser;
                  }

                  return user;
                },
              );
              newFunnerMyUsersList = chatUsersFunnerRef.current.myChats.map(
                (user) => {
                  if (user.UserDisplayPhoneNumber === messageObject.phone) {
                    return currentNotMyUser;
                  }

                  return user;
                },
              );
            } else {
              newGlobalMyUsersList = chatUsersGlobalRef.current.myChats.filter(
                (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
              );
              newFunnerMyUsersList = chatUsersFunnerRef.current.myChats.filter(
                (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
              );
              currentMyUser = { ...currentNotMyUser };
              newGlobalMyUsersList.unshift(currentMyUser);
              newFunnerMyUsersList.unshift(currentMyUser);
            }
          }

          updateGlobalChatUsersStates(
            newGlobalMyUsersList,
            newGlobalNotMyUsersList,
          );
          updateFunnerChatUsersStates(
            newFunnerMyUsersList,
            newFunnerNotMyUsersList,
          );

          //TODO Update saved chatUsers
          // const newSavedUsersObj = {};
          // Object.keys(chatUsersSavedViewsRef.current).forEach((objKey) => {
          //   if (!chatUsersSavedViewsRef.current[objKey].length) return;
          //   let newList = chatUsersSavedViewsRef.current[objKey];
          //   if (isSameMessage || newMessage.FileType === "note") {
          //     newList = newList.map((user) => {
          //       if (user.UserDisplayPhoneNumber === messageObject.phone) {
          //         return currentNotMyUser;
          //       }

          //       return user;
          //     });
          //   } else {
          //     newList = newList.filter(
          //       (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
          //     );

          //     //! Relate inserting user with active filters
          //     insertUsersAccordingToFilters(currentNotMyUser, newList);
          //   }
          //   newSavedUsersObj[objKey] = newList;
          // });
          // updateChatUsersSavedViews(newSavedUsersObj);

          if (
            selectedFunnerChatUserRef.current?.UserDisplayPhoneNumber ===
            currentNotMyUser.UserDisplayPhoneNumber
          ) {
            handleSelectedFunnerChatUser(currentNotMyUser);
          }
          if (
            selectedFireberryChatUserRef.current?.UserDisplayPhoneNumber ===
            currentNotMyUser.UserDisplayPhoneNumber
          ) {
            handleSelectedFireberryChatUser(currentNotMyUser);
          }

          if (
            selectedFunnerChatUserRef.current?.UserDisplayPhoneNumber !==
              currentNotMyUser.UserDisplayPhoneNumber &&
            selectedFireberryChatUserRef.current?.UserDisplayPhoneNumber !==
              currentNotMyUser.UserDisplayPhoneNumber &&
            chatUsersMessagesRef.current.some(
              (user) => user.id === messageObject.phone,
            )
          ) {
            const messagesList = chatUsersMessagesRef.current.find(
              (user) => user.id === messageObject.phone,
            ).messages;
            const existingMessageIndex = messagesList.findIndex(
              (msg) => msg.Id === newMessage.Id,
            );
            let newMessagesList = [...messagesList];
            if (existingMessageIndex !== -1) {
              newMessagesList[existingMessageIndex] = {
                ...newMessagesList[existingMessageIndex],
                ...newMessage,
              };
            } else {
              newMessagesList.push(newMessage);
            }

            newMessagesList = sortListByParam(newMessagesList, "Id");
            handleChatUserMessages(messageObject.phone, {
              messages: [...newMessagesList],
            });

            writePerformanceLog("MessagesHealthLog", {
              companyId: savedUserData?.CompanyId || "",
              userId: savedUserData?.Udid || "",
              action:
                existingMessageIndex !== -1
                  ? "ReceiveMessageUpdateInChatUsersMessage"
                  : "ReceiveMessageInsertedToChatUsersMessage",
              date: new Date().toISOString(),
              additionalData: {
                messageId: messageObject?.messages[0]?.Id || "",
                messageTempId:
                  messageObject?.messages[0]?.TempId ||
                  messageObject?.messages[0]?.tempId ||
                  "",
              },
            });
          }
        }
      } catch (error) {
        console.log("onSocketReceiveMessage error", error);
        writePerformanceLog("MessagesHealthLog", {
          companyId: savedUserData?.CompanyId || "",
          userId: savedUserData?.Udid || "",
          action: "ReceiveMessageError",
          date: new Date().toISOString(),
          additionalData: {
            messageId: messageObject?.messages[0]?.Id || "",
            messageTempId:
              messageObject?.messages[0]?.TempId ||
              messageObject?.messages[0]?.tempId ||
              "",
            error: {
              name: error.name || "UnknownError",
              message: error.message || "No message provided",
              stack: error.stack || "No stack trace available",
            },
          },
        });
      }
    }
    async function onSocketUpdateMessage(messageObject) {
      try {
        const value = messageObject.entry[0].changes[0].value;
        const statuses = value.statuses?.[0];
        if (!statuses) return;

        const userHasMessages = chatUsersMessagesRef.current.some(
          (user) => user.id === statuses.recipient_id,
        );

        //* Update user status
        let currentLastMessage = null;
        if (userHasMessages) {
          const messages = chatUsersMessagesRef.current.find(
            (user) => user.id === statuses.recipient_id,
          ).messages;
          currentLastMessage = messages[messages.length - 1];
        } else {
          const lastUserMessageRes = await UseFetchGet(
            `/api/whatsapp/getChatUserMsgHistoryActvStatus/${statuses.recipient_id}/1/1`,
            undefined,
            null,
            true,
            "history",
          );
          if (lastUserMessageRes?.data?.messages?.length) {
            currentLastMessage = lastUserMessageRes.data.messages[0];
          }
        }

        const isNotifLastMessage =
          statuses.id === currentLastMessage?.MessageId ||
          statuses.id === currentLastMessage?.messageId;

        if (isNotifLastMessage && currentLastMessage.Type === "outcoming") {
          const setUserStatus = (user, status) => {
            return (status === "sent" &&
              (user.OutcomingLastMessageStatus === "delivered" ||
                user.OutcomingLastMessageStatus === "read")) ||
              (status === "delivered" &&
                user.OutcomingLastMessageStatus === "read")
              ? user
              : { ...user, OutcomingLastMessageStatus: status };
          };
          const newGlobalMyUsersList = chatUsersGlobalRef.current.myChats.map(
            (user) => {
              if (user.UserDisplayPhoneNumber === statuses.recipient_id) {
                return setUserStatus(user, statuses.status);
              }

              return user;
            },
          );
          const newFunnerMyUsersList = chatUsersFunnerRef.current.myChats.map(
            (user) => {
              if (user.UserDisplayPhoneNumber === statuses.recipient_id) {
                return setUserStatus(user, statuses.status);
              }

              return user;
            },
          );
          const newGlobalNotMyUsersList =
            chatUsersGlobalRef.current.notMyChats.map((user) => {
              if (user.UserDisplayPhoneNumber === statuses.recipient_id) {
                return setUserStatus(user, statuses.status);
              }

              return user;
            });
          const newFunnerNotMyUsersList =
            chatUsersFunnerRef.current.notMyChats.map((user) => {
              if (user.UserDisplayPhoneNumber === statuses.recipient_id) {
                return setUserStatus(user, statuses.status);
              }

              return user;
            });

          updateGlobalChatUsersStates(
            newGlobalMyUsersList,
            newGlobalNotMyUsersList,
          );
          updateFunnerChatUsersStates(
            newFunnerMyUsersList,
            newFunnerNotMyUsersList,
          );
        }
      } catch (error) {
        console.log("onSocketUpdateMessage error", error);
      }
    }
    function onSocketReceiveTemplateMessage(messageObject) {
      try {
        let targetNotMyUser = chatUsersGlobalRef.current.notMyChats.find(
          (user) => user.UserDisplayPhoneNumber === messageObject.phone,
        );
        console.log(
          "Receive Template Message users: ",
          chatUsersGlobalRef.current.notMyChats,
        );
        console.log("Receive Template Message target user: ", targetNotMyUser);
        if (!targetNotMyUser.Id) return;

        targetNotMyUser = {
          ...targetNotMyUser,
          LastMessage: messageObject.displayMessage,
          LastMessageDate: messageObject.date,
          LastMessageMediaType: "template",
          LastMessageType: "outcoming",
          OutcomingLastMessage: messageObject.displayMessage,
          // OutcomingLastMessageId: 7508191, //?
          OutcomingLastMessageStatus: messageObject.Status,
        };

        const newGlobalNotMyUsersList =
          chatUsersGlobalRef.current.notMyChats.filter(
            (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
          );
        const newFunnerNotMyUsersList =
          chatUsersFunnerRef.current.notMyChats.filter(
            (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
          );
        newGlobalNotMyUsersList.unshift(targetNotMyUser);

        //! Relate inserting user with active filters
        insertUsersAccordingToFilters(targetNotMyUser, newFunnerNotMyUsersList);

        let targetMyUser = chatUsersGlobalRef.current.myChats.find(
          (user) => user.UserDisplayPhoneNumber === messageObject.phone,
        );

        let newGlobalMyUsersList = [...chatUsersGlobalRef.current.myChats];
        let newFunnerMyUsersList = [...chatUsersFunnerRef.current.myChats];
        if (targetMyUser) {
          newGlobalMyUsersList = chatUsersGlobalRef.current.myChats.filter(
            (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
          );
          newFunnerMyUsersList = chatUsersFunnerRef.current.myChats.filter(
            (user) => user.UserDisplayPhoneNumber !== messageObject.phone,
          );
          targetMyUser = { ...targetNotMyUser };
          newGlobalMyUsersList.unshift(targetMyUser);
          newFunnerMyUsersList.unshift(targetMyUser);
        }
        updateGlobalChatUsersStates(
          newGlobalMyUsersList,
          newGlobalNotMyUsersList,
        );
        updateFunnerChatUsersStates(
          newFunnerMyUsersList,
          newFunnerNotMyUsersList,
        );

        if (
          selectedFunnerChatUserRef.current?.UserDisplayPhoneNumber ===
          targetNotMyUser.UserDisplayPhoneNumber
        ) {
          handleSelectedFunnerChatUser(targetNotMyUser);
        }
        if (
          selectedFireberryChatUserRef.current?.UserDisplayPhoneNumber ===
          targetNotMyUser.UserDisplayPhoneNumber
        ) {
          handleSelectedFireberryChatUser(targetNotMyUser);
        }
      } catch (error) {
        console.log("onSocketReceiveMessage error", error);
      }
    }
    function onSocketUnreadNotification(messageObject) {
      try {
        Number(messageObject.unreadMsgsNumb)
          ? setTotalUnredMessages(Number(messageObject.unreadMsgsNumb))
          : setTotalUnredMessages(null);
        Number(messageObject.unreadMsgsNumb)
          ? setTotalUnreadChats(Number(messageObject.unread))
          : setTotalUnreadChats(null);
      } catch (error) {
        console.log("onSocketUnreadNotification error: ", error);
      }
    }
    function onSocketUpdateChatUserUnread(messageObject) {
      try {
        if (messageObject.source === "setReadAPI") {
          updateChatUser(
            messageObject.id,
            {
              UnreadMessagesNumber: messageObject.unreadNumber,
            },
            "Id",
          );
        }
      } catch (error) {
        console.log("onSocketUpdateChatUserUnread error: ", error);
      }
    }
    function onSocketNotification(messageObject, extPerm) {
      try {
        const enableCustomTime = userConfig.enableCustomTime;
        const notifHintTimeout = userConfig.notifHintTimeout;

        notifSound.current?.play().catch((error) => {
          console.log(error);
        });

        if (enableCustomTime) {
          const container = hintNotifContainerRef.current;

          const phone = messageObject.displayPhoneNumber;
          const targetUser = chatUsersGlobalRef.current.notMyChats.find(
            (user) => user.UserDisplayPhoneNumber === phone,
          );

          const targetLink = messageObject.fireberryAccountLink
            ? messageObject.fireberryAccountLink
            : `https://app.powerlink.co.il/app/views/${
                businessInfo?.LinkToObjectNum
                  ? businessInfo.LinkToObjectNum
                  : "first" === "second"
                  ? "2"
                  : "1"
              }?q=${phone}`;

          if (
            (applicationType === "web" &&
              browserNotifPermissionRef.current === "granted") ||
            (applicationType === "extension" && extPerm === "granted")
          ) {
            handleBrowserNotification(
              `Funner - ${targetUser.FullName}`,
              messageObject.notificationText,
            );
          } else {
            enqueueSnackbar(
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleSelectedFunnerChatUser(targetUser);
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item>{targetUser?.FullName || ""}</Grid>
                      <Grid item>{messageObject.displayPhoneNumber}</Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {messageObject.notificationText}
                </Grid>
              </Grid>,
              {
                variant: "info",
                iconVariant: {
                  info: (
                    <NotificationIcon
                      type={"info"}
                      data={{ link: targetLink }}
                    />
                  ),
                },
              },
            );
          }
        }
      } catch (error) {
        console.log("onSocketNotification: ", error);
      }
    }
    function onSocketTaggedUsersNotification(messageObject, extPerm) {
      try {
        if (
          userDataRef.current.Udid === messageObject.notifyContactUserglobalid
        ) {
          notifSound.current?.play().catch((error) => {
            console.log(error);
          });
          // const { enableCustomTime, notifHintTimeout } = await getUserConfig();
          const enableCustomTime = userConfig.enableCustomTime;
          const notifHintTimeout = userConfig.notifHintTimeout;
          // if (getFromLocal("enableCustomTime")) {
          if (enableCustomTime) {
            const container = hintNotifContainerRef.current;

            const phone = messageObject.displayPhoneNumber;
            const targetUser = chatUsersGlobalRef.current.notMyChats.find(
              (user) => user.UserDisplayPhoneNumber === phone,
            );

            if (
              (applicationType === "web" &&
                browserNotifPermissionRef.current === "granted") ||
              (applicationType === "extension" && extPerm === "granted")
            ) {
              handleBrowserNotification(
                `Funner - ${targetUser.FullName}`,
                messageObject.notificationText,
              );
            } else {
              enqueueSnackbar(
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <Box
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleSelectedFunnerChatUser(targetUser);
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item>{targetUser?.FullName || ""}</Grid>
                        <Grid item>{messageObject.displayPhoneNumber}</Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {messageObject.notificationText}
                  </Grid>
                </Grid>,
                {
                  variant: "info",
                },
              );
            }
          }
        }
      } catch (error) {
        console.log("onSocketTaggedUsersNotification error: ", error);
      }
    }
    function onSocketLabelUpdate(messageObject) {
      try {
        updateChatUser(messageObject.phoneId, {
          LableName: messageObject.label,
        });
      } catch (error) {
        console.log("onSocketLabelUpdate error: ", error);
      }
    }
    // function onSocketBotStatusUpdate(messageObject) {
    //   try {
    //     const configObject = JSON.parse(messageObject.configObj);
    //     handleChatUserConfig(
    //       messageObject.phoneId,
    //       { isBotActive: configObject.isBotActive },
    //       false,
    //     );
    //   } catch (error) {
    //     console.log("onSocketBotStatusUpdate error: ", error);
    //   }
    // }
    function onSocketConversationOpenStatus(messageObject) {
      try {
        updateChatUser(messageObject.chatUserPhoneId, {
          IsOpen: messageObject.isOpen,
        });
      } catch (error) {
        console.log("onSocketConversationOpenStatus: ", error);
      }
    }
    function onSockerFunnerServiceNotification(messageObject) {
      const { notificationText, type, durationSeconds } = messageObject;
      const scheme = type.split("/");
      enqueueSnackbar(notificationText, {
        autoHideDuration: durationSeconds * 1000,
        variant: "info",
        style: {
          backgroundColor: scheme[0],
          color: scheme[1],
        },
        iconVariant: {
          info: (
            <NotificationIcon
              type="info"
              customColors={{
                bgcolor: scheme[0],
                color: scheme[1],
              }}
            />
          ),
        },
      });
    }
    function onSocketUpdateChatUserDontDisturb(messageObject) {
      try {
        const dontDisturb = messageObject.chatUser.DontDisturb;

        const newGlobalChatUsersNotMyList =
          chatUsersGlobalRef.current.notMyChats.map((user) => {
            if (user.Id === messageObject.chatUser.Id) {
              user.DontDisturb = dontDisturb;
            }
            return user;
          });
        const newGlobalChatUsersMyList = chatUsersGlobalRef.current.myChats.map(
          (user) => {
            if (user.Id === messageObject.chatUser.Id) {
              user.DontDisturb = dontDisturb;
            }
            return user;
          },
        );

        updateGlobalChatUsersStates(
          newGlobalChatUsersMyList,
          newGlobalChatUsersNotMyList,
        );

        console.log("onSocketUpdateChatUserDontDisturb: ", messageObject);
      } catch (error) {
        console.log("onSocketConversationOpenStatus: ", error);
      }
    }
    function onSocketUpdateFunnerUserActive(messageObject) {
      try {
        const { funnerUserId, isActive } = messageObject;
        if (funnerUserId === userDataRef.current.Udid)
          return handleRemoveToken();

        setFunnerUsers((prevList) => {
          const newList = [...prevList];
          const findedUser = newList.find((user) => user.Udid === funnerUserId);
          if (findedUser) {
            findedUser.IsActive = isActive;
          }
          return newList;
        });

        console.log("onSocketUpdateFunnerUserActive: ", messageObject);
      } catch (error) {
        console.log("onSocketUpdateFunnerUserActive error: ", error);
      }
    }

    function insertUsersAccordingToFilters(
      currentNotMyUser,
      newFunnerNotMyUsersList,
    ) {
      const {
        searchQuery,
        label,
        open,
        isOnlyUnread,
        assigneeFilter,
        filters,
      } = getContactsQueryParamsRef.current;

      const searchCond = !searchQuery?.length
        ? true
        : currentNotMyUser.UserDisplayPhoneNumber.includes(
            searchQuery.toLowerCase(),
          ) ||
          currentNotMyUser.FullName.toLowerCase().includes(
            searchQuery.toLowerCase(),
          );
      const labelCond =
        label && label !== "" ? currentNotMyUser.LableName === label : true;
      const openCond =
        open === "open"
          ? currentNotMyUser.IsOpen === true
          : open === "close"
          ? currentNotMyUser.IsOpen === false
          : true;
      const onlyUnreadCond = isOnlyUnread
        ? currentNotMyUser.UnreadMessagesNumber > 0
        : true;
      const assigneeFilterCond = assigneeFilter.length
        ? assigneeFilter.includes(currentNotMyUser.NotificationContactUser)
        : true;

      // const additionalFiltersCond = doesUserMatchAdditionalFilters(
      //   filters,
      //   currentNotMyUser,
      // );

      // console.log(
      //   "searchCond: ",
      //   searchCond,
      //   "\nlabelCond: ",
      //   labelCond,
      //   "\nopenCond: ",
      //   openCond,
      //   "\nonlyUnreadCond: ",
      //   onlyUnreadCond,
      //   "\nassigneeFilterCond: ",
      //   assigneeFilterCond,
      // );

      if (
        searchCond &&
        labelCond &&
        openCond &&
        onlyUnreadCond &&
        assigneeFilterCond
        // TODO && additionalFiltersCond
      ) {
        if (newFunnerNotMyUsersList.length === 0) {
          handleSelectedFunnerChatUser(currentNotMyUser);
        }
        newFunnerNotMyUsersList.unshift(currentNotMyUser);
      }
    }
    function doesUserMatchAdditionalFilters(filters, user) {
      if (!filters || !user) return true;
      for (const filter of filters.filter((f) => f.value != null)) {
        const key = filter.key;
        let value = filter.value;

        switch (key) {
          case "IsOpen":
            if (user.IsOpen !== Boolean(value)) {
              return false;
            }
            break;
          case "IsOpenUpdateTimeFrom":
            if (new Date(user.OpenTime) <= new Date(value)) {
              return false;
            }
            break;
          case "IsOpenUpdateTimeTo":
            if (new Date(user.OpenTime) >= new Date(value)) {
              return false;
            }
            break;
          case "NotificationContactUser":
            if (!Array.isArray(value)) {
              value = [value];
            }
            if (!value.includes(user.NotificationContactUser)) {
              return false;
            }
            break;
          case "LableName":
            if (user.LableName !== String(value)) {
              return false;
            }
            break;
          case "LastMessageMediaType":
            if (user.LastMessageMediaType !== String(value)) {
              return false;
            }
            break;
          case "LastMessageIsImage":
            if (value === true && user.LastMessageMediaType !== "image") {
              return false;
            }
            break;
          case "LastMessageIsVideo":
            if (value === true && user.LastMessageMediaType !== "video") {
              return false;
            }
            break;
          case "LastMessageIsAudio":
            if (value === true && user.LastMessageMediaType !== "audio") {
              return false;
            }
            break;
          case "LastMessageSource":
            if (user.LastMessageSource !== String(value)) {
              return false;
            }
            break;
          case "IncomingLastMessageMediaType":
            if (user.IncomingLastMessageMediaType !== String(value)) {
              return false;
            }
            break;
          case "IncomingLastMessageDateFrom":
            if (new Date(user.IncomingLastMessageDate) <= new Date(value)) {
              return false;
            }
            break;
          case "IncomingLastMessageDateTo":
            if (new Date(user.IncomingLastMessageDate) >= new Date(value)) {
              return false;
            }
            break;
          case "IncomingLastMessageIsImage":
            if (
              value === true &&
              user.IncomingLastMessageMediaType !== "image"
            ) {
              return false;
            }
            break;
          case "IncomingLastMessageIsVideo":
            if (
              value === true &&
              user.IncomingLastMessageMediaType !== "video"
            ) {
              return false;
            }
            break;
          case "IncomingLastMessageIsAudio":
            if (
              value === true &&
              user.IncomingLastMessageMediaType !== "audio"
            ) {
              return false;
            }
            break;
          case "IncomingLastMessageSource":
            if (user.IncomingLastMessageSource !== String(value)) {
              return false;
            }
            break;
          case "IncomingLastMessageContactUser":
            if (!Array.isArray(value)) {
              value = [value];
            }
            if (!value.includes(user.IncomingLastMessageContactUser)) {
              return false;
            }
            break;
          case "OutcomingLastMessageMediaType":
            if (user.OutgoingLastMessageMediaType !== String(value)) {
              return false;
            }
            break;
          case "OutcomingLastMessageDateFrom":
            if (new Date(user.OutcomingLastMessageDate) <= new Date(value)) {
              return false;
            }
            break;
          case "OutcomingLastMessageDateTo":
            if (new Date(user.OutcomingLastMessageDate) >= new Date(value)) {
              return false;
            }
            break;
          case "OutcomingLastMessageIsImage":
            if (
              value === true &&
              user.OutgoingLastMessageMediaType !== "image"
            ) {
              return false;
            }
            break;
          case "OutcomingLastMessageIsVideo":
            if (
              value === true &&
              user.OutgoingLastMessageMediaType !== "video"
            ) {
              return false;
            }
            break;
          case "OutcomingLastMessageIsAudio":
            if (
              value === true &&
              user.OutgoingLastMessageMediaType !== "audio"
            ) {
              return false;
            }
            break;
          case "OutcomingLastMessageSource":
            if (user.OutgoingLastMessageSource !== String(value)) {
              return false;
            }
            break;
          default:
            break;
        }
      }

      return true;
    }
    async function checkAndUpdateFireberryPerformanceLog() {
      try {
        const savedData =
          getFromLocal("fireberryPlaceChatPerformanceLog") ?? [];
        if (savedData.length) {
          const lastSavedLogDate = savedData[savedData.length - 1].date;

          if (isMoreThanTwoHours(lastSavedLogDate)) {
            console.log(lastSavedLogDate);

            const res = await UseFetchPost(
              "/api/services/saveExtensionPerformanceLog",
              {
                log: savedData,
              },
              "priority",
            );

            if (res.status === 200) {
              clearPerformanceLog("fireberryPlaceChatPerformanceLog");
            }
          }
        }
      } catch (error) {
        console.error("checkAndUpdateFireberryPerformanceLog error: ", error);
      }

      function isMoreThanTwoHours(dateISOString) {
        const savedDate = new Date(dateISOString);
        const now = new Date();

        const diffInMilliseconds = now - savedDate;
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

        return diffInHours > 2;
      }
    }

    function activeTabChangesListener() {
      // eslint-disable-next-line no-undef
      chrome.runtime.onMessage.addListener(async function (
        message,
        sender,
        sendResponse,
      ) {
        if (message.type === "socketReceiveMessage") {
          const { messageObject } = message.content;
          onSocketReceiveMessage(messageObject);
        }
        if (message.type === "socketUpdateMessage") {
          const { messageObject } = message.content;
          onSocketUpdateMessage(messageObject);
        }
        if (message.type === "socketReceiveTemplateMessage") {
          const { messageObject } = message.content;
          onSocketReceiveTemplateMessage(messageObject);
        }
        if (message.type === "socketUnreadNotification") {
          const { messageObject } = message.content;
          onSocketUnreadNotification(messageObject);
        }
        if (message.type === "socketUpdateChatUserUnread") {
          const { messageObject } = message.content;
          onSocketUpdateChatUserUnread(messageObject);
        }
        if (message.type === "socketNotification") {
          const { messageObject } = message.content;
          onSocketNotification(messageObject);
        }
        if (message.type === "socketTaggedUsersNotification") {
          const { messageObject } = message.content;
          onSocketTaggedUsersNotification(messageObject);
        }
        if (message.type === "labelUpdate") {
          const { messageObject } = message.content;
          onSocketLabelUpdate(messageObject);
        }
        // if (message.type === "botStatusUpdate") {
        //   const { messageObject } = message.content;
        //   onSocketBotStatusUpdate(messageObject);
        // }
        if (message.type === "conversationOpenStatus") {
          const { messageObject } = message.content;
          onSocketConversationOpenStatus(messageObject);
        }
        if (message.type === "funnerServiceNotification") {
          const { messageObject } = message.content;
          onSockerFunnerServiceNotification(messageObject);
        }
      });
    }

    useEffect(() => {
      onAppConnectedActions();
      if (tabActiveRef.current === false && applicationType === "extension") {
        activeTabChangesListener();
      }

      if (applicationType === "extension") {
        // eslint-disable-next-line no-undef
        chrome.runtime.onMessage.addListener(async function (
          message,
          sender,
          sendResponse,
        ) {
          if (message.type === "closeHintNotif") {
            closeHintNotification();
          }
        });
      }
    }, []);

    useEffect(() => {
      if (isConnection) {
        initSocketListener();
      }
    }, [isConnection]);

    return (
      <div
        className={`config-popup root-whatsapp ${
          applicationType === "web" ? "funner-global-web-app-page" : ""
        } ${isNavOpen ? "nav-open" : ""}`}
      >
        {/* Audio notification component */}
        {/* <AudioNotification
        notifSound={notifSound}
        isNotifOn={userConfig.isNotifOn}
        volume={userConfig.audioNotifVol}
      /> */}
        <svg width="0" height="0" style={{ display: "none" }}>
          <defs>
            <filter id="toWhiteColorFilter">
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1
                      0 0 0 0 1
                      0 0 0 0 1
                      0 0 0 1 0"
              />
            </filter>
          </defs>
        </svg>

        {/* On page Bottom Buttons */}
        <div className="funner-status unred-messages" ref={appButtonsRef}>
          {token && (
            <>
              <div
                className={`funner-status__button funner-status__button--chat ${
                  !token ? "not-login" : ""
                }`}
                onClick={token ? toggleUnreadMessagesPopup : null}
              >
                <img src={hintLogo} alt="" />
              </div>
              {totalUnredMessages && totalUnreadChats && (
                <div
                  style={{
                    fontWeight: "bold",
                    backgroundColor: "red",
                    fontFamily: "Arimo, sans-serif",
                    color: "white",
                    padding: "2px 12px",
                    borderRadius: "14px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    direction: "ltr",
                  }}
                  onClick={openUnreadMessagesChat}
                >
                  {`${totalUnreadChats} (${totalUnredMessages})`}
                </div>
              )}
            </>
          )}
        </div>
        {applicationType === "web" && (
          <SettingsPopup
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            token={token}
            iframeMode={iframeMode}
            businessInfo={businessInfo}
            setBusinessInfo={setBusinessInfo}
            businessInfoRef={businessInfoRef}
            toggleNavPanel={toggleNavPanel}
            closeNavPanel={closeNavPanel}
            handleRemoveToken={handleRemoveToken}
            isNavOpen={isNavOpen}
            setNavOpen={setNavOpen}
            userData={userData}
            userConfig={userConfig}
            userConfigRef={userConfigRef}
            updateUserConfig={updateUserConfig}
            updateUserConfigWithObject={updateUserConfigWithObject}
            templatesList={templatesList}
            fetchWhatsappTemplates={fetchWhatsappTemplates}
            handleSetNotif={handleSetNotif}
            handleUsersFireberrySettingsWindow={
              handleUsersFireberrySettingsWindow
            }
            selectedUsersFireberrySync={selectedUsersFireberrySync}
            setSelectedUsersFireberrySync={setSelectedUsersFireberrySync}
            handleUsersPrioritySettingsWindow={
              handleUsersPrioritySettingsWindow
            }
            selectedUsersPrioritySync={selectedUsersPrioritySync}
            setSelectedUsersPrioritySync={setSelectedUsersPrioritySync}
            funnerUsers={funnerUsers}
            setFunnerUsers={setFunnerUsers}
            getFunnerUsers={getFunnerUsers}
            setIsActiveAppOnPage={setIsActiveAppOnPage}
            chatUsersGlobal={chatUsersGlobal}
            handleApplicationUserProfile={handleApplicationUserProfile}
            chatUsersLabelsList={chatUsersLabelsList}
            setChatUsersLabelsList={setChatUsersLabelsList}
            handleSubmitType={handleSubmitType}
            handleSelectedFunnerChatUser={handleSelectedFunnerChatUser}
            updateChatUser={updateChatUser}
            setUserInSettingsCreationState={setUserInSettingsCreationState}
            getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
            userDataRef={userDataRef}
            chatUsersFunnerRef={chatUsersFunnerRef}
            chatUsersGlobalRef={chatUsersGlobalRef}
            updateFunnerChatUsersStates={updateFunnerChatUsersStates}
            updateGlobalChatUsersStates={updateGlobalChatUsersStates}
            handleSetToken={handleSetToken}
            isMyUsersChats={isMyUsersChats}
            handleGoToFavouriteMessageState={handleGoToFavouriteMessageState}
            handleGetContactsParams={handleGetContactsParams}
            getCompanyChatUsers={getCompanyChatUsers}
            getContactsQueryParams={getContactsQueryParams}
            getContactsQueryParamsRef={getContactsQueryParamsRef}
            funnerMessangerRef={funnerMessangerRef}
            setMyUsersChat={setMyUsersChat}
            chatUsersSavedViewsRef={chatUsersSavedViewsRef}
            prevViewRef={prevViewRef}
          />
        )}

        <FunnerGlobalMessanger
          iframeMode={iframeMode}
          openUnreadMessagesPopup={openUnreadMessagesPopup}
          chatUsersFunner={chatUsersFunner}
          chatUsersFunnerRef={chatUsersFunnerRef}
          chatUsersGlobal={chatUsersGlobal}
          chatUsersGlobalRef={chatUsersGlobalRef}
          getContactsQueryParams={getContactsQueryParams}
          getContactsQueryParamsRef={getContactsQueryParamsRef}
          handleGetContactsParams={handleGetContactsParams}
          getCompanyChatUsers={getCompanyChatUsers}
          isContactsLoading={isContactsLoading}
          getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
          selectedFunnerChatUser={selectedFunnerChatUser}
          selectedFunnerChatUserRef={selectedFunnerChatUserRef}
          handleSelectedFunnerChatUser={handleSelectedFunnerChatUser}
          updateChatUser={updateChatUser}
          isNavOpen={isNavOpen}
          userData={userData}
          userDataRef={userDataRef}
          userConfig={userConfig}
          userConfigRef={userConfigRef}
          businessInfo={businessInfo}
          funnerUsers={funnerUsers}
          chatUsersLabelsList={chatUsersLabelsList}
          updateUserConfig={updateUserConfig}
          updateUserConfigWithObject={updateUserConfigWithObject}
          handleSetNotif={handleSetNotif}
          toggleUnreadMessagesPopup={toggleUnreadMessagesPopup}
          templatesList={templatesList}
          templatesLoading={templatesLoading}
          fetchWhatsappTemplates={fetchWhatsappTemplates}
          connection={connection}
          isConnection={isConnection}
          setTotalUnredMessages={setTotalUnredMessages}
          setTotalUnreadChats={setTotalUnreadChats}
          handleUsersFireberrySettingsWindow={
            handleUsersFireberrySettingsWindow
          }
          setSelectedUsersFireberrySync={setSelectedUsersFireberrySync}
          handleUsersPrioritySettingsWindow={handleUsersPrioritySettingsWindow}
          setSelectedUsersPrioritySync={setSelectedUsersPrioritySync}
          tabActiveRef={tabActiveRef}
          handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
          sendUploadPriority={sendUploadPriority}
          resetSendUploadPriority={resetSendUploadPriority}
          chatUsersMessagesRef={chatUsersMessagesRef}
          userInSettingsCreationState={userInSettingsCreationState}
          handleChatUserMessages={handleChatUserMessages}
          clearChatUsersMessages={clearChatUsersMessages}
          disableChatGoBack={disableChatGoBack}
          getChatMissingHistory={getChatMissingHistory}
          isMyUsersChats={isMyUsersChats}
          setMyUsersChat={setMyUsersChat}
          goToFavouriteMessageState={goToFavouriteMessageState}
          goToFavouriteMessageStateRef={goToFavouriteMessageStateRef}
          handleGoToFavouriteMessageState={handleGoToFavouriteMessageState}
          ref={funnerMessangerRef}
        />

        {businessInfo.CompanyType === "fireberry" && (
          <FireberryPlatform
            isPowerLinkPage={isPowerLinkPage}
            userData={userData}
            userDataRef={userDataRef}
            chatUsersGlobal={chatUsersGlobal}
            chatUsersGlobalRef={chatUsersGlobalRef}
            selectedFireberryChatUser={selectedFireberryChatUser}
            selectedFireberryChatUserRef={selectedFireberryChatUserRef}
            handleSelectedFireberryChatUser={handleSelectedFireberryChatUser}
            userConfig={userConfig}
            funnerUsers={funnerUsers}
            handleSetNotif={handleSetNotif}
            chatUsersLabelsList={chatUsersLabelsList}
            updateChatUser={updateChatUser}
            businessInfo={businessInfo}
            templatesList={templatesList}
            templatesLoading={templatesLoading}
            fetchWhatsappTemplates={fetchWhatsappTemplates}
            connection={connection}
            isConnection={isConnection}
            tabActiveRef={tabActiveRef}
            isUsersFireberrySyncWindow={isUsersFireberrySyncWindow}
            handleUsersFireberrySettingsWindow={
              handleUsersFireberrySettingsWindow
            }
            selectedUsersFireberrySync={selectedUsersFireberrySync}
            setSelectedUsersFireberrySync={setSelectedUsersFireberrySync}
            chatUsersMessagesRef={chatUsersMessagesRef}
            handleChatUserMessages={handleChatUserMessages}
            getChatMissingHistory={getChatMissingHistory}
          />
        )}

        {businessInfo.CompanyType === "priority" && (
          <PriorityPlatform
            userData={userData}
            userDataRef={userDataRef}
            businessInfo={businessInfo}
            businessInfoRef={businessInfoRef}
            selectedFunnerChatUser={selectedFunnerChatUser}
            handleSelectedFunnerChatUser={handleSelectedFunnerChatUser}
            isPrioritySendDocsWindow={isPrioritySendDocsWindow}
            handlePrioritySendDocsWindow={handlePrioritySendDocsWindow}
            handleSetNotif={handleSetNotif}
            templatesList={templatesList}
            isPriorityDocumentWindow={isPriorityDocumentWindow}
            handlePriorityDocumentsWindow={handlePriorityDocumentsWindow}
            funnerUsers={funnerUsers}
            chatUsersGlobal={chatUsersGlobal}
            chatUsersGlobalRef={chatUsersGlobalRef}
            isActiveAppOnPageRef={isActiveAppOnPageRef}
            setOpenUnreadMessagesPopup={setOpenUnreadMessagesPopup}
            fetchWhatsappTemplates={fetchWhatsappTemplates}
            isUsersPrioritySyncWindow={isUsersPrioritySyncWindow}
            handleUsersPrioritySettingsWindow={
              handleUsersPrioritySettingsWindow
            }
            selectedUsersPrioritySync={selectedUsersPrioritySync}
            setSelectedUsersPrioritySync={setSelectedUsersPrioritySync}
            handleSendPriorityUploadState={handleSendPriorityUploadState}
            appButtonsRef={appButtonsRef}
            disableChatGoBack={disableChatGoBack}
            setDisableChatGoBack={setDisableChatGoBack}
            openUnreadMessagesPopup={openUnreadMessagesPopup}
          />
        )}

        <div className="hint-notif-container" ref={hintNotifContainerRef} />
      </div>
    );
  },
);

export default Main;
