import { Close, SendOutlined, SmartToyOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { generateUniqueId } from "../../../libs/helpers";
import DotsLoader from "../../../modules/DotsLoader";
import MarkdownRenderer from "../../../modules/MarkdownRenderer";

function AskAIDialog({ open, onClose, phoneNumber }) {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [sessionId, setSessionId] = React.useState(null);

  const [inputValue, setInputValue] = React.useState("");
  const [messages, setMessages] = React.useState([]);

  const messagesRef = React.useRef([]);
  const messagesContainerRef = React.useRef(null);

  const handleClear = () => {
    handleMessages([]);
    setInputValue("");
    setSessionId(null);
  };
  const handleClose = () => {
    onClose();
    setTimeout(() => {
      handleClear();
    }, 300);
  };

  const handleMessages = (newMessages) => {
    setMessages(newMessages);
    messagesRef.current = newMessages;
  };
  const handleAddMessageToList = (message) => {
    const newMessages = JSON.parse(JSON.stringify(messagesRef.current));

    const existingMessageIndex = newMessages.findIndex(
      (msg) => msg.id === message.id,
    );
    if (existingMessageIndex !== -1) {
      newMessages[existingMessageIndex] = message;
    } else {
      newMessages.push(message);
    }

    handleMessages(newMessages);
    setTimeout(() => {
      handleScrollBottom();
    }, 50);
  };

  const startChat = React.useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.post(
        "https://api.geekstechglobal.com/api/Chat/start_chat",
        {
          toUser: phoneNumber,
        },
      );

      if (res.status === 200) setSessionId(res.data);
    } catch (error) {
      console.log("startChat error: ", error);
    } finally {
      setLoading(false);
    }
  }, [phoneNumber]);
  const sendMessageToAI = async () => {
    try {
      const currentValue = inputValue;
      setInputValue("");

      const newMessageObject = {
        id: generateUniqueId(
          messagesRef.current.map((msg) => msg.id),
          5,
        ),
        sender: "user",
        message: currentValue,
      };
      handleAddMessageToList(newMessageObject);

      const newAILoadingResponse = {
        id: generateUniqueId(
          messagesRef.current.map((msg) => msg.id),
          5,
        ),
        sender: "AI",
        message: "loading",
      };
      setTimeout(() => {
        handleAddMessageToList(newAILoadingResponse);
      }, 100);

      const res = await axios.post(
        "https://api.geekstechglobal.com/api/Chat/ask",
        {
          sessionId: sessionId,
          input: currentValue,
        },
      );

      if (res.status === 200) {
        newAILoadingResponse.message = res.data;
        handleAddMessageToList(newAILoadingResponse);
      }
    } catch (error) {
      console.warn("sendMessageToAI error: ", error);
    }
  };
  const handleSendMessageOnEnter = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      sendMessageToAI();
    }
  };
  const handleScrollBottom = () => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  };

  React.useEffect(() => {
    if (open) {
      startChat();
    }
  }, [open, startChat]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} fullWidth>
      <DialogTitle
        sx={{
          textAlign: "right",
        }}
      >
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          minHeight: 200,
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress size={24} color="secondary" />
            <Typography>Starting chat...</Typography>
          </Box>
        ) : sessionId ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <Box
              sx={{
                width: "100%",
              }}
            ></Box> */}
            <Box
              sx={{
                width: "100%",
                flex: "1 1 auto",
                height: "40vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                // justifyContent: "flex-end",
                mr: -1.25,
                pr: 1.25,
                py: 1.25,
                gap: 1.25,
              }}
              ref={messagesContainerRef}
            >
              {messages.length > 0 ? (
                messages.map((message) => (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: 1.5,
                      flexDirection:
                        message.sender === "user" ? "row" : "row-reverse",
                    }}
                  >
                    <Box>
                      <Avatar
                        sx={{
                          width: 30,
                          height: 30,
                          fontSize: 16,
                          bgcolor: "info.dark",
                        }}
                      >
                        {message.sender === "user" ? (
                          "You"
                        ) : (
                          <SmartToyOutlined />
                        )}
                      </Avatar>
                    </Box>
                    <Box
                      sx={{
                        flex: "1 1 auto",
                        display: "flex",
                        flexDirection:
                          message.sender === "user" ? "row" : "row-reverse",
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: 400,
                          display: "inline-flex",
                          py: 1,
                          px: 1.25,
                          borderRadius: 1,
                          bgcolor:
                            message.sender === "user"
                              ? "rgba(76,175,80,0.17)"
                              : "rgba(208,92,227,0.11)",
                        }}
                      >
                        {message.sender === "AI" &&
                        message.message === "loading" ? (
                          <DotsLoader
                            size={20}
                            color={"rgba(208,92,227,0.8)"}
                          />
                        ) : message.sender === "AI" ? (
                          <MarkdownRenderer markdownText={message.message} />
                        ) : (
                          <Typography>{message.message}</Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography textAlign={"center"}>
                  No messages in this conversation yet
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                mx: -3,
                px: 3,
                mb: -2.5,
                pb: 2.5,
                pt: 1.25,
                bgcolor: "#f7f7f7",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    label="Please write your question"
                    value={inputValue}
                    size="small"
                    onChange={(e) => setInputValue(e.target.value)}
                    InputProps={{
                      sx: {
                        bgcolor: "common.white",
                      },
                    }}
                    onKeyDown={handleSendMessageOnEnter}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Button
                    variant="contained"
                    sx={{
                      height: "100%",
                      width: 100,
                    }}
                    disabled={!inputValue}
                    onClick={() => sendMessageToAI()}
                  >
                    <SendOutlined />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography>
              Session has not been created, sorry, please try again
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default AskAIDialog;
