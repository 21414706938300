/* eslint-disable react-hooks/rules-of-hooks */
import React, { useReducer, useRef, useState } from "react";
import { UseFetchPost } from "../hooks/fetchFunctions";

import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { useLocalization } from "../views/Context/LocalizationContext";

function AddUsersFromFile({
  // setIsAddNewUserFormOpen,
  // updateChatUsersList,
  // setUserCreationState,
  parentHandleUploadFunction,
  buttonRight,
  style,
  contentStyle,
  singleSelection = false,
}) {
  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.settingsPopup.massMessagingTasks,
    [currentLocale],
  );

  const [addUserType, setAddUserType] = useState(null);
  const [isManuallyFireberryChecked, setIsManuallyFireberryChecked] =
    useState(false);
  const [isByFileFireberryChecked, setIsByFileFireberryChecked] =
    useState(false);
  const [fileName, setFileName] = useState(
    localization.addNew.targetContacts.fromFile.fileNamePlaceholder,
  );
  const [usersFromFileList, setUsersFromFileList] = useState([]);
  const [isUsersFromFileSendToSave, setIsUsersFromFileSendToSave] =
    useState(false);
  const [isXlsxLoading, setIsXlsxLoading] = useState(false);
  const [isDynamicVars, setIsDynamicVars] = useState(false);

  const fileInputRef = useRef(null);

  const handleAddUserType = (type) => {
    setAddUserType(type);
  };

  const handleRemoveUserType = () => {
    setAddUserType(null);
    setIsManuallyFireberryChecked(false);
    setIsByFileFireberryChecked(false);
    setFileName(
      localization.addNew.targetContacts.fromFile.fileNamePlaceholder,
    );
    setUsersFromFileList([]);
    setIsUsersFromFileSendToSave(false);
  };

  async function handleUploadUsersFile(e) {
    const file = e.target.files[0];
    const thisFileName = file.name;
    setFileName(thisFileName);

    const res = await importChatUsersViaXlsx(file, false, false);
    if (res) {
      setUsersFromFileList([...res.importedUsers]);
      parentHandleUploadFunction(res.filePath, res.isDynamicVars);
    }

    e.target.value = null;
  }
  async function importChatUsersViaXlsx(file, isSave, isFireberry) {
    setIsXlsxLoading(true);
    const warningNotif = enqueueSnackbar(
      localization.addUsers.fromFile.waitForFileLoadNotif,
      {
        variant: "warning",
        persist: true,
      },
    );

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("isSave", isSave);
      formData.append("isFireberry", isFireberry);
      formData.append("dynamicVars", isDynamicVars);

      const result = await UseFetchPost(
        "/api/services/importChatUsersViaXlsx",
        formData,
        "config",
      );
      if (result.data) {
        if (result.data.newImportedChatUsers.length) {
          return {
            importedUsers: result.data.newImportedChatUsers,
            filePath: result.data.filePath,
            isDynamicVars: isDynamicVars,
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.log("importChatUsersViaXlsx err: ", error);
      return null;
    } finally {
      setIsXlsxLoading(false);
      closeSnackbar(warningNotif);
    }
  }

  return (
    <>
      <div
        className="unread-messages-popup__add-new-user add-new-user-funner"
        style={{ position: "static", width: "100%", height: "auto", ...style }}
      >
        {/* <div
          className="add-new-user-funner__close"
          // onClick={() => setIsAddNewUserFormOpen(false)}
        ></div> */}
        {addUserType && (
          <div
            className="add-new-user-funner__prev-btn"
            onClick={handleRemoveUserType}
          >
            <svg
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.49003 20.6299L8.27003 22.3999L18.16 12.4999L8.26003 2.5999L6.49003 4.3699L14.62 12.4999L6.49003 20.6299Z"
                fill="#2a3847"
              />
            </svg>
          </div>
        )}
        <div
          className="add-new-user-funner__content"
          style={{ ...contentStyle }}
        >
          {console.log(singleSelection)}
          {!addUserType && (
            <div className="add-new-user-funner__add-type add-type-new-user-funner">
              <Grid container spacing={1.25}>
                {(!singleSelection || singleSelection === "default") && (
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleAddUserType("by-file");
                        setIsDynamicVars(false);
                      }}
                      sx={{ width: 125 }}
                    >
                      {localization.addNew.targetContacts.fromFile.buttonText}
                    </Button>
                  </Grid>
                )}

                {(!singleSelection || singleSelection === "vars") && (
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleAddUserType("by-file");
                        setIsDynamicVars(true);
                      }}
                    >
                      {
                        localization.addNew.targetContacts.fromFile
                          .dynamicVarsButtonText
                      }
                    </Button>
                  </Grid>
                )}
              </Grid>
              {/* <div
                className="add-type-new-user-funner__buttons"
                style={{
                  alignItems: buttonRight ? "flex-start" : null,
                  justifyContent: "flex-start",
                  flexDirection: "row",
                }}
              > */}
              {/* <button
                  className="add-type-new-user-funner__add add-type-new-user-funner__add--from-file popup__blue-btn"
                  onClick={() => handleAddUserType("by-file")}
                >
                  {localization.addNew.targetContacts.fromFile.buttonText}
                </button>
                <button
                  className="add-type-new-user-funner__add add-type-new-user-funner__add--from-file popup__blue-btn"
                  onClick={() => handleAddUserType("by-file")}
                >
                  {localization.addNew.targetContacts.fromFile.buttonText}
                </button> */}
              {/* </div> */}
            </div>
          )}
          {addUserType === "by-file" && (
            <div className="add-by-file-new-user-funner add-by-file-new-user-funner--mass-messaging">
              <div className="add-by-file-new-user-funner__content">
                {isXlsxLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      bgcolor: "rgba(255, 255, 255, 0.75)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 10,
                    }}
                  >
                    <CircularProgress size={60} color="secondary" />
                  </Box>
                )}
                <label
                  className="add-by-file-new-user-funner__upload-file-btn popup__blue-btn"
                  htmlFor="add-by-file-new-user-funner__file"
                >
                  <input
                    type="file"
                    name="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                      handleUploadUsersFile(e);
                    }}
                    id="add-by-file-new-user-funner__file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  {localization.addNew.targetContacts.fromFile.uploadBtnText}
                </label>

                <div className="add-by-file-new-user-funner__file-name">
                  {fileName}
                </div>

                {!usersFromFileList.length && (
                  <a
                    href="https://docs.google.com/spreadsheets/d/1tSEOP82YO7CxIMGrJ3Nu_6efiGyIo2VJ/edit#gid=1788392530"
                    target="_blank"
                  >
                    xlsx example file
                  </a>
                )}

                {usersFromFileList && usersFromFileList.length > 0 && (
                  <>
                    <div className="add-by-file-new-user-funner__list-wrapper">
                      <div className="add-by-file-new-user-funner__list-tooltip">
                        <svg
                          role="presentation"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 300"
                          height={42}
                          width={42}
                        >
                          <rect
                            className="tooltip-horizontal-scroll-icon_card"
                            x={480}
                            width={200}
                            height={200}
                            rx={5}
                            fill="rgba(190,190,190,0.3)"
                          />
                          <rect
                            className="tooltip-horizontal-scroll-icon_card"
                            y={0}
                            width={200}
                            height={200}
                            rx={5}
                            fill="rgba(190,190,190,0.3)"
                          />
                          <rect
                            className="tooltip-horizontal-scroll-icon_card"
                            x={240}
                            width={200}
                            height={200}
                            rx={5}
                            fill="rgba(190,190,190,0.3)"
                          />
                          <path
                            className="tooltip-horizontal-scroll-icon_hand"
                            d="M78.9579 285.7C78.9579 285.7 37.8579 212.5 20.5579 180.8C-2.44209 138.6 -6.2422 120.8 9.6579 112C19.5579 106.5 33.2579 108.8 41.6579 123.4L61.2579 154.6V32.3C61.2579 32.3 60.0579 0 83.0579 0C107.558 0 105.458 32.3 105.458 32.3V91.7C105.458 91.7 118.358 82.4 133.458 86.6C141.158 88.7 150.158 92.4 154.958 104.6C154.958 104.6 185.658 89.7 200.958 121.4C200.958 121.4 236.358 114.4 236.358 151.1C236.358 187.8 192.158 285.7 192.158 285.7H78.9579Z"
                            fill="rgba(190,190,190,1)"
                          />
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                ".tooltip-horizontal-scroll-icon_hand {\nanimation: tooltip-horizontal-scroll-icon_anim-scroll-hand 2s infinite\n}\n.tooltip-horizontal-scroll-icon_card {\nanimation: tooltip-horizontal-scroll-icon_anim-scroll-card 2s infinite\n}\n@keyframes tooltip-horizontal-scroll-icon_anim-scroll-hand {\n0% { transform: translateX(80px) scale(1); opacity: 0 }\n10% { transform: translateX(80px) scale(1); opacity: 1 }\n20%,60% { transform: translateX(175px) scale(.6); opacity: 1 }\n80% { transform: translateX(5px) scale(.6); opacity: 1 } to { transform: translateX(5px) scale(.6); opacity: 0 }\n}\n@keyframes tooltip-horizontal-scroll-icon_anim-scroll-card {\n0%,60% { transform: translateX(0) }\n80%,to { transform: translateX(-240px) }\n}",
                            }}
                          />
                        </svg>
                      </div>

                      <div className="add-by-file-new-user-funner__list">
                        <div className="add-by-file-new-user-funner__list-header">
                          <div className="add-by-file-new-user-funner__list-row">
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                N/o
                              </div>
                            </div>
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                Status
                              </div>
                            </div>
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                First Name
                              </div>
                            </div>
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                Last Name
                              </div>
                            </div>
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                Fullname
                              </div>
                            </div>
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                Phone number
                              </div>
                            </div>
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                Email
                              </div>
                            </div>
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                Fireberry Account Id
                              </div>
                            </div>
                            <div className="add-by-file-new-user-funner__list-item">
                              <div className="add-by-file-new-user-funner__list-item-text">
                                Fireberry Contact Id
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="add-by-file-new-user-funner__list-body">
                          {usersFromFileList.map((item, index) => (
                            <div className="add-by-file-new-user-funner__list-row">
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  {index + 1}
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <ul className="add-by-file-new-user-funner__errors-list">
                                  {isUsersFromFileSendToSave && (
                                    <>
                                      {item.created ? (
                                        <li className="_accept _create">
                                          Created
                                        </li>
                                      ) : (
                                        <>
                                          <li className="_err _create">
                                            Didn't created
                                          </li>
                                          {item.errors
                                            .split(" - ")
                                            .slice(1)
                                            .map((error) => (
                                              <li className="_err">{error}</li>
                                            ))}
                                        </>
                                      )}
                                    </>
                                  )}
                                  {!isUsersFromFileSendToSave && (
                                    <>
                                      {!item.valid ? (
                                        item.errors
                                          .split(" - ")
                                          .slice(1)
                                          .map((error) => (
                                            <li className="_err">{error}</li>
                                          ))
                                      ) : (
                                        <li className="_accept">All is Ok!</li>
                                      )}
                                    </>
                                  )}
                                </ul>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  {item.firstName}
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  {item.lastName}
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  {item.fullName}
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  {item.phone}
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  {item.email}
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  {item.fireberryAccountId}
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  {item.fireberryContactId}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AddUsersFromFile;
