import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FixedSizeList } from "react-window";
import { phoneGlobalRegex } from "../../../libs/regexes";
import { Close, SearchOutlined } from "@mui/icons-material";
import { useLocalization } from "../../Context/LocalizationContext";

function renderRow(props) {
  const { index, style, data } = props;
  const { toggleChatUsersInForwardList, forwardMessage } = data;
  const user = data.items[index];
  const userPhoneNumber = phoneGlobalRegex.test(user.UserDisplayPhoneNumber)
    ? `0${user.UserDisplayPhoneNumber.slice(3)}`
    : user.UserDisplayPhoneNumber || "";

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton
        onClick={() => toggleChatUsersInForwardList(userPhoneNumber)}
        selected={forwardMessage.chatUsersPhoneList.includes(userPhoneNumber)}
        sx={{ height: "100%" }}
      >
        <Grid
          container
          spacing={2}
          alignItems={"center"}
          flexWrap={"nowrap"}
          overflow={"hidden"}
        >
          <Grid item>
            <Checkbox
              checked={forwardMessage.chatUsersPhoneList.includes(
                userPhoneNumber,
              )}
            />
          </Grid>
          <Grid item>
            <Avatar src={user.UserPic} />
          </Grid>
          <Grid item flexGrow={1}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    maxWidth: 237,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  fontSize={16}
                  fontWeight={600}
                >
                  {user.FullName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    maxWidth: 237,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  fontSize={14}
                >
                  {userPhoneNumber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItemButton>
    </ListItem>
  );
}

function ForwardChatUsersPopup({
  open,
  onClose,
  onSubmit,
  forwardMessage,
  chatUsersGlobal,
  toggleChatUsersInForwardList,
}) {
  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.chat.messages.forwardMessages.chatUsersDialog,
    [currentLocale],
  );

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const clearSearch = () => {
    setSearchQuery("");
  };

  const filteredChatUsers =
    searchQuery !== ""
      ? chatUsersGlobal.notMyChats.filter((user) => {
          const userPhoneNumber = phoneGlobalRegex.test(
            user.UserDisplayPhoneNumber,
          )
            ? `0${user.UserDisplayPhoneNumber.slice(3)}`
            : user.UserDisplayPhoneNumber || "";
          return (
            user.isRestricted24 &&
            (user.FullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              userPhoneNumber.includes(searchQuery))
          );
        })
      : chatUsersGlobal.notMyChats.filter((user) => user.isRestricted24);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: 450,
        },
      }}
    >
      <DialogTitle>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            {localization.title}
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ color: "text.secondary" }}>
              {localization.subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={localization.searchLabel}
              variant="outlined"
              size="small"
              fullWidth
              margin="dense"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
                endAdornment: searchQuery !== "" && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={clearSearch}>
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <FixedSizeList
          height={500}
          itemCount={filteredChatUsers.length}
          itemSize={61}
          width={400}
          itemData={{
            items: filteredChatUsers,
            toggleChatUsersInForwardList: toggleChatUsersInForwardList,
            forwardMessage: forwardMessage,
          }}
          direction="rtl"
        >
          {renderRow}
        </FixedSizeList>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          {localization.close}
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          disabled={!forwardMessage.chatUsersPhoneList.length}
        >
          {localization.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ForwardChatUsersPopup;
