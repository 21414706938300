import { useLocalization } from "../Context/LocalizationContext";

export const useTableLocaleText = () => {
  const { currentLocale } = useLocalization();
  const tableLocalization = currentLocale.settingsPopup.tableLocalization;

  return {
    //* Toolbar
    //** Root
    noRowsLabel: tableLocalization.toolbar.root.noRowsLabel,
    noResultsOverlayLabel: tableLocalization.toolbar.root.noResultsOverlayLabel,

    //** Density selector toolbar button text
    toolbarDensity: tableLocalization.toolbar.densitySelector.toolbarDensity,
    toolbarDensityLabel:
      tableLocalization.toolbar.densitySelector.toolbarDensityLabel,
    toolbarDensityCompact:
      tableLocalization.toolbar.densitySelector.toolbarDensityCompact,
    toolbarDensityStandard:
      tableLocalization.toolbar.densitySelector.toolbarDensityStandard,
    toolbarDensityComfortable:
      tableLocalization.toolbar.densitySelector.toolbarDensityComfortable,

    //** Columns selector toolbar button text
    toolbarColumns: tableLocalization.toolbar.columnsSelector.toolbarColumns,
    toolbarColumnsLabel:
      tableLocalization.toolbar.columnsSelector.toolbarColumnsLabel,

    //** Filters toolbar button text
    toolbarFilters:
      tableLocalization.toolbar.filtersToolbarButton.toolbarFilters,
    toolbarFiltersLabel:
      tableLocalization.toolbar.filtersToolbarButton.toolbarFiltersLabel,
    toolbarFiltersTooltipHide:
      tableLocalization.toolbar.filtersToolbarButton.toolbarFiltersTooltipHide,
    toolbarFiltersTooltipShow:
      tableLocalization.toolbar.filtersToolbarButton.toolbarFiltersTooltipShow,
    toolbarFiltersTooltipActive: (count) =>
      count !== 1
        ? `${count} ${tableLocalization.toolbar.filtersToolbarButton.toolbarFiltersTooltipActiveMultiple}`
        : `${count} ${tableLocalization.toolbar.filtersToolbarButton.toolbarFiltersTooltipActiveSingle}`,

    //** Quick filter toolbar field
    toolbarQuickFilterPlaceholder:
      tableLocalization.toolbar.quickFilter.toolbarQuickFilterPlaceholder,
    toolbarQuickFilterLabel:
      tableLocalization.toolbar.quickFilter.toolbarQuickFilterLabel,
    toolbarQuickFilterDeleteIconLabel:
      tableLocalization.toolbar.quickFilter.toolbarQuickFilterDeleteIconLabel,

    //** Export selector toolbar button text
    toolbarExport: tableLocalization.toolbar.exportSelector.toolbarExport,
    toolbarExportLabel:
      tableLocalization.toolbar.exportSelector.toolbarExportLabel,
    toolbarExportCSV: tableLocalization.toolbar.exportSelector.toolbarExportCSV,
    toolbarExportPrint:
      tableLocalization.toolbar.exportSelector.toolbarExportPrint,
    toolbarExportExcel:
      tableLocalization.toolbar.exportSelector.toolbarExportExcel,

    //* Columns management text
    columnsManagementSearchTitle:
      tableLocalization.columnsManagementText.columnsManagementSearchTitle,
    columnsManagementNoColumns:
      tableLocalization.columnsManagementText.columnsManagementNoColumns,
    columnsManagementShowHideAllText:
      tableLocalization.columnsManagementText.columnsManagementShowHideAllText,
    columnsManagementReset:
      tableLocalization.columnsManagementText.columnsManagementReset,

    //* Filter panel text
    filterPanelAddFilter:
      tableLocalization.filterPanelText.filterPanelAddFilter,
    filterPanelRemoveAll:
      tableLocalization.filterPanelText.filterPanelRemoveAll,
    filterPanelDeleteIconLabel:
      tableLocalization.filterPanelText.filterPanelDeleteIconLabel,
    filterPanelLogicOperator:
      tableLocalization.filterPanelText.filterPanelLogicOperator,
    filterPanelOperator: tableLocalization.filterPanelText.filterPanelOperator,
    filterPanelOperatorAnd:
      tableLocalization.filterPanelText.filterPanelOperatorAnd,
    filterPanelOperatorOr:
      tableLocalization.filterPanelText.filterPanelOperatorOr,
    filterPanelColumns: tableLocalization.filterPanelText.filterPanelColumns,
    filterPanelInputLabel:
      tableLocalization.filterPanelText.filterPanelInputLabel,
    filterPanelInputPlaceholder:
      tableLocalization.filterPanelText.filterPanelInputPlaceholder,

    //* Filter operators text
    filterOperatorContains:
      tableLocalization.filterOperatorsText.filterOperatorContains,
    filterOperatorDoesNotContain:
      tableLocalization.filterOperatorsText.filterOperatorDoesNotContain,
    filterOperatorEquals:
      tableLocalization.filterOperatorsText.filterOperatorEquals,
    filterOperatorDoesNotEqual:
      tableLocalization.filterOperatorsText.filterOperatorDoesNotEqual,
    filterOperatorStartsWith:
      tableLocalization.filterOperatorsText.filterOperatorStartsWith,
    filterOperatorEndsWith:
      tableLocalization.filterOperatorsText.filterOperatorEndsWith,
    filterOperatorIs: tableLocalization.filterOperatorsText.filterOperatorIs,
    filterOperatorNot: tableLocalization.filterOperatorsText.filterOperatorNot,
    filterOperatorAfter:
      tableLocalization.filterOperatorsText.filterOperatorAfter,
    filterOperatorOnOrAfter:
      tableLocalization.filterOperatorsText.filterOperatorOnOrAfter,
    filterOperatorBefore:
      tableLocalization.filterOperatorsText.filterOperatorBefore,
    filterOperatorOnOrBefore:
      tableLocalization.filterOperatorsText.filterOperatorOnOrBefore,
    filterOperatorIsEmpty:
      tableLocalization.filterOperatorsText.filterOperatorIsEmpty,
    filterOperatorIsNotEmpty:
      tableLocalization.filterOperatorsText.filterOperatorIsNotEmpty,
    filterOperatorIsAnyOf:
      tableLocalization.filterOperatorsText.filterOperatorIsAnyOf,

    //* Header filter operators text
    headerFilterOperatorContains:
      tableLocalization.filterOperatorsText.headerFilterOperatorContains,
    headerFilterOperatorDoesNotContain:
      tableLocalization.filterOperatorsText.headerFilterOperatorDoesNotContain,
    headerFilterOperatorEquals:
      tableLocalization.filterOperatorsText.headerFilterOperatorEquals,
    headerFilterOperatorDoesNotEqual:
      tableLocalization.filterOperatorsText.headerFilterOperatorDoesNotEqual,
    headerFilterOperatorStartsWith:
      tableLocalization.filterOperatorsText.headerFilterOperatorStartsWith,
    headerFilterOperatorEndsWith:
      tableLocalization.filterOperatorsText.headerFilterOperatorEndsWith,
    headerFilterOperatorIs:
      tableLocalization.filterOperatorsText.headerFilterOperatorIs,
    headerFilterOperatorNot:
      tableLocalization.filterOperatorsText.headerFilterOperatorNot,
    headerFilterOperatorAfter:
      tableLocalization.filterOperatorsText.headerFilterOperatorAfter,
    headerFilterOperatorOnOrAfter:
      tableLocalization.filterOperatorsText.headerFilterOperatorOnOrAfter,
    headerFilterOperatorBefore:
      tableLocalization.filterOperatorsText.headerFilterOperatorBefore,
    headerFilterOperatorOnOrBefore:
      tableLocalization.filterOperatorsText.headerFilterOperatorOnOrBefore,
    headerFilterOperatorIsEmpty:
      tableLocalization.filterOperatorsText.headerFilterOperatorIsEmpty,
    headerFilterOperatorIsNotEmpty:
      tableLocalization.filterOperatorsText.headerFilterOperatorIsNotEmpty,
    headerFilterOperatorIsAnyOf:
      tableLocalization.filterOperatorsText.headerFilterOperatorIsAnyOf,
    "headerFilterOperator=":
      tableLocalization.filterOperatorsText["headerFilterOperator="],
    "headerFilterOperator!=":
      tableLocalization.filterOperatorsText["headerFilterOperator!="],
    "headerFilterOperator>":
      tableLocalization.filterOperatorsText["headerFilterOperator>"],
    "headerFilterOperator>=":
      tableLocalization.filterOperatorsText["headerFilterOperator>="],
    "headerFilterOperator<":
      tableLocalization.filterOperatorsText["headerFilterOperator<"],
    "headerFilterOperator<=":
      tableLocalization.filterOperatorsText["headerFilterOperator<="],

    //* Filter values text
    filterValueAny: tableLocalization.filterValuesText.filterValueAny,
    filterValueTrue: tableLocalization.filterValuesText.filterValueTrue,
    filterValueFalse: tableLocalization.filterValuesText.filterValueFalse,

    //* Column menu text
    columnMenuLabel: tableLocalization.columnMenuText.columnMenuLabel,
    columnMenuShowColumns:
      tableLocalization.columnMenuText.columnMenuShowColumns,
    columnMenuManageColumns:
      tableLocalization.columnMenuText.columnMenuManageColumns,
    columnMenuFilter: tableLocalization.columnMenuText.columnMenuFilter,
    columnMenuHideColumn: tableLocalization.columnMenuText.columnMenuHideColumn,
    columnMenuUnsort: tableLocalization.columnMenuText.columnMenuUnsort,
    columnMenuSortAsc: tableLocalization.columnMenuText.columnMenuSortAsc,
    columnMenuSortDesc: tableLocalization.columnMenuText.columnMenuSortDesc,

    //* Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1
        ? `${count} ${tableLocalization.columnHeaderText.columnHeaderFiltersTooltipActiveMultiple}`
        : `${count} ${tableLocalization.columnHeaderText.columnHeaderFiltersTooltipActiveSingle}`,
    columnHeaderFiltersLabel:
      tableLocalization.columnHeaderText.columnHeaderFiltersLabel,
    columnHeaderSortIconLabel:
      tableLocalization.columnHeaderText.columnHeaderSortIconLabel,

    //* Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `${count.toLocaleString()} ${
            tableLocalization.rowsSelectedFooter.footerRowSelected.multiple
          }`
        : `${count.toLocaleString()} ${
            tableLocalization.rowsSelectedFooter.footerRowSelected.single
          }`,

    //* Total row amount footer text
    footerTotalRows: tableLocalization.rowsSelectedFooter.footerTotalRows,

    //* Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} ${
        tableLocalization.rowsSelectedFooter.footerTotalVisibleRows
      } ${totalCount.toLocaleString()}`,

    //* Checkbox selection text
    checkboxSelectionHeaderName:
      tableLocalization.checkboxSelectionText.checkboxSelectionHeaderName,
    checkboxSelectionSelectAllRows:
      tableLocalization.checkboxSelectionText.checkboxSelectionSelectAllRows,
    checkboxSelectionUnselectAllRows:
      tableLocalization.checkboxSelectionText.checkboxSelectionUnselectAllRows,
    checkboxSelectionSelectRow:
      tableLocalization.checkboxSelectionText.checkboxSelectionSelectRow,
    checkboxSelectionUnselectRow:
      tableLocalization.checkboxSelectionText.checkboxSelectionUnselectRow,

    //* Boolean cell text
    booleanCellTrueLabel:
      tableLocalization.booleanCellText.booleanCellTrueLabel,
    booleanCellFalseLabel:
      tableLocalization.booleanCellText.booleanCellFalseLabel,

    //* Actions cell more text
    actionsCellMore: tableLocalization.actionsCellMore,

    //* Column pinning text
    pinToLeft: tableLocalization.columnPinningText.pinToLeft,
    pinToRight: tableLocalization.columnPinningText.pinToRight,
    unpin: tableLocalization.columnPinningText.unpin,

    //* Tree Data
    treeDataGroupingHeaderName: tableLocalization.treeDataGroupingHeaderName,
    treeDataExpand: tableLocalization.treeDataExpand,
    treeDataCollapse: tableLocalization.treeDataCollapse,

    //* Grouping columns
    groupingColumnHeaderName:
      tableLocalization.groupingColumns.groupingColumnHeaderName,
    groupColumn: (name) =>
      `${tableLocalization.groupingColumns.groupColumn} ${name}`,
    unGroupColumn: (name) =>
      `${tableLocalization.groupingColumns.unGroupColumn} ${name}`,

    //* Master/detail
    detailPanelToggle: tableLocalization.masterDetail.detailPanelToggle,
    expandDetailPanel: tableLocalization.masterDetail.expandDetailPanel,
    collapseDetailPanel: tableLocalization.masterDetail.collapseDetailPanel,

    //* Used core components translation keys
    MuiTablePagination: {},

    //* Row reordering text
    rowReorderingHeaderName: tableLocalization.rowReorderingHeaderName,

    //* Aggregation
    aggregationMenuItemHeader: tableLocalization.aggregationMenuItemHeader,
    aggregationFunctionLabelSum: tableLocalization.aggregationFunctionLabelSum,
    aggregationFunctionLabelAvg: tableLocalization.aggregationFunctionLabelAvg,
    aggregationFunctionLabelMin: tableLocalization.aggregationFunctionLabelMin,
    aggregationFunctionLabelMax: tableLocalization.aggregationFunctionLabelMax,
    aggregationFunctionLabelSize:
      tableLocalization.aggregationFunctionLabelSize,
  };
};
