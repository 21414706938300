import React from "react";
import { Box } from "@mui/material";

const DotsLoader = React.memo(({ color, size = 30 }) => {
  return (
    <Box
      sx={{
        height: size,
        aspectRatio: "2.5",
        "--_g": `no-repeat radial-gradient(farthest-side, ${
          color ?? "#000"
        } 90%, #0000)`,
        background: "var(--_g), var(--_g), var(--_g), var(--_g)",
        backgroundSize: "20% 50%",
        animation: "l43 1s infinite linear",
        "@keyframes l43": {
          "0%": {
            backgroundPosition:
              "calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%",
          },
          "16.67%": {
            backgroundPosition:
              "calc(0*100%/3) 0, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%",
          },
          "33.33%": {
            backgroundPosition:
              "calc(0*100%/3) 100%, calc(1*100%/3) 0, calc(2*100%/3) 50%, calc(3*100%/3) 50%",
          },
          "50%": {
            backgroundPosition:
              "calc(0*100%/3) 50%, calc(1*100%/3) 100%, calc(2*100%/3) 0, calc(3*100%/3) 50%",
          },
          "66.67%": {
            backgroundPosition:
              "calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 100%, calc(3*100%/3) 0",
          },
          "83.33%": {
            backgroundPosition:
              "calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 100%",
          },
          "100%": {
            backgroundPosition:
              "calc(0*100%/3) 50%, calc(1*100%/3) 50%, calc(2*100%/3) 50%, calc(3*100%/3) 50%",
          },
        },
      }}
    />
  );
});

export default DotsLoader;
