import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import SettingsCard, { CardTitle } from "../SettingsCard";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  Close,
  CloudUpload,
  SearchOutlined,
  ViewList,
  ViewModule,
} from "@mui/icons-material";
import FilesTableView from "./FilesTableView";
import FilesGalleryView from "./FilesGalleryView";
import { VisuallyHiddenInput } from "../../../modules/VisuallyHiddenInput";
import { enqueueSnackbar } from "notistack";
import debounce from "lodash/debounce";
import {
  UseFetchGet,
  UseFetchPost,
  UseFetchPostAnonymos,
} from "../../../hooks/fetchFunctions";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { getFileLinkFromServer } from "../../../libs/helpers";
import { useLocalization } from "../../Context/LocalizationContext";

const FILES_COUNT = 30;
function FilesListLayout({
  type,
  companyId,
  FunnerUserId,
  noActions = false,
  onClickAction = () => {},
}) {
  const [view, setView] = useState("gallery");
  const acceptUploadByType = {
    image: "image/*",
    video: "video/*",
    document: ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx",
  };
  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.settingsPopup.uploadFiles,
    [currentLocale],
  );
  const [files, setFiles] = useState([]);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileInfo, setFileInfo] = useState({
    Id: null,
    Name: "",
    Link: null,
  });
  const [filesListFilters, setFilesListFilters] = useState({
    from: null,
    to: null,
    searchTermName: "",
  });
  const [filesListLoading, setFilesListLoading] = useState(false);

  const [isFileNameEdit, setIsFileNameEdit] = useState(false);

  const fileUploadInputRef = useRef(null);
  const pageOffsetRef = useRef(1);

  const handleFileInfo = (params) => {
    setFileInfo((prevData) => ({ ...prevData, ...params }));
  };
  const handleCloseFileNameEdit = (clear = true) => {
    setIsFileNameEdit(false);
    if (clear) {
      setTimeout(() => {
        handleClearFileInfo();
      }, 300);
    }
  };
  const handleClearFileInfo = () => {
    setFileInfo({
      Id: null,
      Name: "",
      Link: null,
    });
  };

  const onEditExistingNameClick = (file) => {
    handleFileInfo({
      Id: file.Id,
      Name: file.Name,
      Link: file.Link,
    });
    setIsFileNameEdit(true);
  };

  const handleFilesListFilters = (params, handleType = null) => {
    const newFilters = { ...filesListFilters, ...params };
    pageOffsetRef.current = 1;
    setFilesListFilters(newFilters);
    if (handleType !== "searchTerm") {
      getMediaGalleryByCategory(newFilters);
    } else debouncedGetFilesList(newFilters);
  };
  function handleLoadFilesListOnScroll(e, offset = 150) {
    const scrollContainer = e.target;
    const isAtBottom =
      scrollContainer.scrollTop + scrollContainer.clientHeight >=
      scrollContainer.scrollHeight - offset;

    if (isAtBottom) {
      // offsetContactsPage++;
      onScrollEndReached();
    }
  }
  const onScrollEndReached = () => {
    if (
      !filesListLoading &&
      files.length / pageOffsetRef.current === FILES_COUNT
    ) {
      console.log("query on scroll");
      pageOffsetRef.current += 1;
      getMediaGalleryByCategory(null, false);
    }
  };
  const parseDate = (dateString) => {
    if (!dateString) return dateString;
    const [day, month] = dateString.split(" ")[0].split("/");
    const [hours, minutes] = dateString.split(" ")[1].split(":");
    return dayjs(`${day}-${month} ${hours}:${minutes}`, "DD/MM HH:mm");
  };

  //! Fetch functions
  async function getMediaGalleryByCategory(
    directFilters = null,
    loader = true,
  ) {
    try {
      const filters = directFilters || filesListFilters;
      if (loader) {
        setFilesListLoading(true);
      }

      const params = {
        category: type,
        page: 1,
        number: FILES_COUNT * pageOffsetRef.current,
        from: filters.from,
        to: filters.to,
        searchTermName: filters.searchTermName,
      };
      const res = await UseFetchGet(
        "/api/mediaGallery/getMediaGalleryByCategory",
        params,
        null,
        false,
        "history",
      );
      if (res?.data?.result) {
        setTimeout(() => {
          setFiles(res.data.gallery);
        }, 100);
      }
    } catch (error) {
      console.error("getMediaGalleryByCategory error: ", error);
    } finally {
      if (loader)
        setTimeout(() => {
          setFilesListLoading(false);
        }, 100);
    }
  }
  const debouncedGetFilesList = useCallback(
    debounce((nextValue) => getMediaGalleryByCategory(nextValue), 1000),
    [],
  );
  // async function getFileLinkFromServer(e) {
  //   try {
  //     const file = e.target.files?.[0];
  //     if (!file) {
  //       return console.error("No file selected");
  //     }

  //     const formData = new FormData();
  //     if (file.type.startsWith("image/")) {
  //       formData.append("image", file);
  //       const response = await UseFetchPostAnonymos(
  //         "/api/services/GetImageLink",
  //         formData,
  //         "config",
  //       );
  //       const { data } = response;
  //       if (data?.result === true) {
  //         return {
  //           Name: file.name,
  //           Link: data.link,
  //         };
  //       } else return null;
  //     } else {
  //       formData.append("file", file);
  //       const response = await UseFetchPostAnonymos(
  //         "/api/whatsapp/SaveFile",
  //         formData,
  //         "config",
  //       );
  //       const { data } = response;
  //       console.log("data: ", data);
  //       if (data?.result === true) {
  //         return {
  //           Name: file.name,
  //           Link: data.link,
  //         };
  //       } else return null;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     return null;
  //   } finally {
  //     fileUploadInputRef.current.value = null;
  //   }
  // }
  const checkFileSize = (e) => {
    const file = e.target.files?.[0];
    const currentSizeLimit = { image: 5, video: 15, document: 100 }[type];
    console.log(file?.size);
    console.log(currentSizeLimit);
    console.log(currentSizeLimit * 1024 * 1024);
    if (currentSizeLimit * 1024 * 1024 < file?.size) {
      return false;
    } else return true;
  };
  async function onUploadClick(e) {
    if (!checkFileSize(e)) {
      e.target.value = null;
      return enqueueSnackbar(
        <span
          dangerouslySetInnerHTML={{
            __html: localization.filesSizesLimits[type],
          }}
        ></span>,
        {
          variant: "error",
        },
      );
    }
    setFileLoading(true);
    const uploadedFile = await getFileLinkFromServer(e);
    setFileLoading(false);
    if (uploadedFile) {
      handleFileInfo(uploadedFile);
      setIsFileNameEdit(true);
    } else {
      enqueueSnackbar("Error uploading file, please try again", {
        variant: "error",
      });
    }
  }
  async function saveUpdateItemInMediaGallery(params) {
    try {
      const res = await UseFetchPost(
        "/api/mediaGallery/saveUpdate",
        params,
        "history",
      );
      console.log(res);
      if (res?.data?.result) {
        return true;
      } else return false;
    } catch (error) {
      console.error("saveUpdateItemInMediaGallery error: ", error);
      return false;
    }
  }
  async function handleUploadMediaToGallery(
    editedParams = {},
    handleType = "upload",
  ) {
    try {
      handleCloseFileNameEdit(false);
      const params = {
        Name: fileInfo.Name,
        Link: fileInfo.Link,
        Category: type,
        Status: "Active",
        CompanyId: companyId,
        FunnerUserId: FunnerUserId,
        ...editedParams,
      };
      const res = await saveUpdateItemInMediaGallery(params);
      console.log(res);
      if (res) {
        enqueueSnackbar("Success", { variant: "success" });
        if (handleType === "upload") {
          getMediaGalleryByCategory();
        } else if (handleType === "update") {
          setFiles((prevFiles) => {
            const newFiles = [...prevFiles];
            const currentFile = newFiles.find(
              (item) => item.Id === editedParams.Id,
            );
            if (currentFile) {
              currentFile.Name = params.Name;
              currentFile.Status = params.Status;
            }
            return newFiles;
          });
        }
      } else {
        enqueueSnackbar("Error uploading file, please try again", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Error uploading file, please try again", {
        variant: "error",
      });
    } finally {
      handleClearFileInfo();
    }
  }
  async function handleDeleteFileFromGallery(file) {
    try {
      const params = {
        Id: file.Id,
        Name: file.Name,
        Link: file.Link,
        Category: type,
        Status: "Removed",
        CompanyId: companyId,
        FunnerUserId: FunnerUserId,
      };
      const res = await saveUpdateItemInMediaGallery(params);
      console.log(res);
      if (res) {
        enqueueSnackbar("Success", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error uploading file, please try again", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("handleDeleteFileFromGallery error: ", error);
      enqueueSnackbar("Error uploading file, please try again", {
        variant: "error",
      });
    } finally {
      handleClearFileInfo();
    }
  }

  useEffect(() => {
    getMediaGalleryByCategory();
  }, []);

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          height: "100%",
          flexWrap: "nowrap",
          position: "relative",
        }}
      >
        <Box
          width={"100%"}
          sx={{
            px: 1,
          }}
        >
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              {!noActions && (
                <Button
                  variant="contained"
                  startIcon={
                    fileLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <CloudUpload />
                    )
                  }
                  disabled={fileLoading}
                  component="label"
                >
                  {localization.upload}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => onUploadClick(e)}
                    ref={fileUploadInputRef}
                    accept={acceptUploadByType[type]}
                  />
                </Button>
              )}
            </Grid>
            <Grid item>
              <ButtonGroup variant="outlined">
                <Button
                  disableElevation
                  variant={view === "list" ? "contained" : "outlined"}
                  onClick={() => setView("list")}
                >
                  <ViewList />
                </Button>
                <Button
                  disableElevation
                  variant={view === "gallery" ? "contained" : "outlined"}
                  onClick={() => setView("gallery")}
                >
                  <ViewModule />
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label={localization.search}
                value={filesListFilters.searchTermName}
                onChange={(e) => {
                  handleFilesListFilters(
                    {
                      searchTermName: e.target.value,
                    },
                    "searchTerm",
                  );
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {filesListFilters.searchTermName.length > 0 && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleFilesListFilters({
                              searchTermName: "",
                            })
                          }
                        >
                          <Close color="error" sx={{ fontSize: 20 }} />
                        </IconButton>
                      )}
                      <SearchOutlined sx={{ fontSize: 20 }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item>
              <Grid
                container
                spacing={1.5}
                alignItems={"center"}
                flexWrap={"nowrap"}
              >
                <Grid item xs={5.75}>
                  <DateTimePicker
                    label={localization.dateFrom}
                    value={parseDate(filesListFilters.from)}
                    onChange={(newValue) =>
                      handleFilesListFilters({
                        from: newValue?.format("DD/MM HH:mm") || null,
                      })
                    }
                    slotProps={{
                      textField: {
                        size: "small",
                        InputProps: {
                          sx: {
                            pr: 1,
                            fontSize: 15,
                            "& input": {
                              py: 1.25,
                              px: 1,
                              fontSize: 15,
                            },
                          },
                        },
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                      inputAdornment: {
                        sx: { m: 0 },
                      },
                      openPickerButton: {
                        size: "small",
                      },
                      openPickerIcon: {
                        sx: {
                          fontSize: 18,
                        },
                      },
                      clearButton: {
                        size: "small",
                        mr: -0.5,
                      },
                      clearIcon: {
                        sx: {
                          fontSize: 18,
                        },
                      },
                      field: {
                        clearable: true,
                        onClear: () => handleFilesListFilters({ from: null }),
                      },
                    }}
                    disableFuture
                  />
                </Grid>
                <Grid item xs={0.5} textAlign={"center"}>
                  -
                </Grid>
                <Grid item xs={5.75}>
                  <DateTimePicker
                    label={localization.dateFrom}
                    value={parseDate(filesListFilters.to)}
                    onChange={(newValue) =>
                      handleFilesListFilters({
                        to: newValue?.format("DD/MM HH:mm") || null,
                      })
                    }
                    slotProps={{
                      textField: {
                        size: "small",
                        InputProps: {
                          sx: {
                            pr: 1,
                            fontSize: 15,
                            "& input": {
                              py: 1.25,
                              px: 1,
                              fontSize: 15,
                            },
                          },
                        },
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                      inputAdornment: {
                        sx: { m: 0 },
                      },
                      openPickerButton: {
                        size: "small",
                      },
                      openPickerIcon: {
                        sx: {
                          fontSize: 18,
                        },
                      },
                      clearButton: {
                        size: "small",
                        mr: -0.5,
                      },
                      clearIcon: {
                        sx: {
                          fontSize: 18,
                        },
                      },
                      field: {
                        clearable: true,
                        onClear: () => handleFilesListFilters({ to: null }),
                      },
                    }}
                    disableFuture
                  />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Box>
        <Box
          width={"100%"}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
          }}
        >
          {view === "list" ? (
            <FilesTableView
              files={files}
              handleDeleteFileFromGallery={handleDeleteFileFromGallery}
              onEditExistingNameClick={onEditExistingNameClick}
              onScrollEndReached={onScrollEndReached}
              noActions={noActions}
              onClickAction={onClickAction}
            />
          ) : (
            <FilesGalleryView
              files={files}
              type={type}
              handleDeleteFileFromGallery={handleDeleteFileFromGallery}
              onEditExistingNameClick={onEditExistingNameClick}
              handleLoadFilesListOnScroll={handleLoadFilesListOnScroll}
              noActions={noActions}
              onClickAction={onClickAction}
            />
          )}
        </Box>
        {filesListLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "rgba(255, 255, 255, 0.5)",
              zIndex: 10000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={60} color="secondary" />
          </Box>
        )}
      </Box>
      <Dialog
        open={isFileNameEdit}
        onClose={handleCloseFileNameEdit}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{localization.editNamePopup.title}</DialogTitle>
        <DialogContent>
          <Box pt={1}>
            <TextField
              autoFocus
              label={localization.editNamePopup.fileName}
              fullWidth
              value={fileInfo.Name}
              onChange={(e) => handleFileInfo({ Name: e.target.value })}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFileNameEdit}>
            {localization.editNamePopup.cancel}
          </Button>
          <Button
            onClick={() =>
              handleUploadMediaToGallery(
                fileInfo.Id ? { Id: fileInfo.Id } : {},
                fileInfo.Id ? "update" : "upload",
              )
            }
            variant="contained"
          >
            {localization.editNamePopup.submit}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default FilesListLayout;
