export const getImplementedVars = (locale) => [
  {
    varName: locale.implementedVariables["$contact.accountid"].name,
    label: locale.implementedVariables["$contact.accountid"].label,
  },
  {
    varName: locale.implementedVariables["$contact.last_incoming_message"].name,
    label: locale.implementedVariables["$contact.last_incoming_message"].label,
  },
  {
    varName: locale.implementedVariables["$contact.accountname"].name,
    label: locale.implementedVariables["$contact.accountname"].label,
  },
  {
    varName: locale.implementedVariables["$contact.phone"].name,
    label: locale.implementedVariables["$contact.phone"].label,
  },
  {
    varName: locale.implementedVariables["$contact.contactd"].name,
    label: locale.implementedVariables["$contact.contactd"].label,
  },
  {
    varName: locale.implementedVariables["$contact.firstName"].name,
    label: locale.implementedVariables["$contact.firstName"].label,
  },
];

export const getConversationImplementedVars = (locale) => [
  {
    varName: locale.implementedVariables["$conversation.opened_timestamp"].name,
    label: locale.implementedVariables["$conversation.opened_timestamp"].label,
  },
  {
    varName: locale.implementedVariables["$conversation.opened_by_source"].name,
    label: locale.implementedVariables["$conversation.opened_by_source"].label,
  },
  {
    varName:
      locale.implementedVariables["$conversation.opened_by_channel"].name,
    label: locale.implementedVariables["$conversation.opened_by_channel"].label,
  },
  {
    varName: locale.implementedVariables["$conversation.contact_type"].name,
    label: locale.implementedVariables["$conversation.contact_type"].label,
  },
  {
    varName:
      locale.implementedVariables["$conversation.first_incoming_message"].name,
    label:
      locale.implementedVariables["$conversation.first_incoming_message"].label,
  },
  {
    varName:
      locale.implementedVariables[
        "$conversation.first_incoming_message_channel_id"
      ].name,
    label:
      locale.implementedVariables[
        "$conversation.first_incoming_message_channel_id"
      ].label,
  },
];
