import React, { useEffect, useRef, useState } from "react";
import {
  checkUrlViews,
  checkUrlViewsCard,
  ifUserPage,
  isAppLinkPage,
  isUpdateTokenPage,
} from "./viewsCheck";
import {
  getFromChromeStorage,
  getFromLocal,
  saveToChromeStorage,
  saveToLocal,
} from "../../hooks/localStorage";
import { UseFetchGet, UseFetchGetAnonymos } from "../../hooks/fetchFunctions";
import { changeNumber, writePerformanceLog } from "../../libs/helpers";
import { createPortal } from "react-dom";
import Chat from "../Chat/Chat";
import ChatUsersFireberrySync from "./ChatUsersFireberrySync";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { hintLogo } from "../../libs/images";
import { phoneGlobalRegex } from "../../libs/regexes";
import { tabId } from "../../App";

let pageUrl = window.location.href.toString();
const FireberryPlatform = React.memo(
  ({
    isPowerLinkPage,
    userData,
    userDataRef,
    chatUsersGlobal,
    chatUsersGlobalRef,
    selectedFireberryChatUser,
    selectedFireberryChatUserRef,
    handleSelectedFireberryChatUser,
    userConfig,
    funnerUsers,
    handleSetNotif,
    chatUsersLabelsList,
    updateChatUser,
    businessInfo,
    templatesList,
    templatesLoading,
    fetchWhatsappTemplates,
    connection,
    isConnection,
    tabActiveRef,
    isUsersFireberrySyncWindow,
    handleUsersFireberrySettingsWindow,
    selectedUsersFireberrySync,
    setSelectedUsersFireberrySync,
    chatUsersMessagesRef,
    handleChatUserMessages,
    getChatMissingHistory,
  }) => {
    const theme = useTheme();
    const MDBreakpoint = useMediaQuery(theme.breakpoints.down(1500));

    const [powerlinkChatWrapper, setPowerlinkChatWrapper] = useState(null);
    const [chatLoading, setChatLoading] = useState(false);
    const [chatFolded, setChatFolded] = useState(false);
    const [isHashtag, setIsHashtag] = useState(false);
    const [pageType, setPageType] = useState(null);

    const contentColRef = useRef(null);
    const textareaRef = useRef(null);

    const toggleChatFold = () => {
      saveToChromeStorage("funner-fireberry-fold", !chatFolded);
      setChatFolded((prev) => !prev);
      if (!isHashtag) contentColRef.current.classList.toggle("half-width");
    };

    function getGlobalScripsData() {
      return new Promise((resolve, reject) => {
        let getScripts = setInterval(() => {
          const scripts = document.querySelectorAll("script");
          if (scripts) {
            [...scripts].map((script) => {
              if (
                script.textContent.includes(
                  "window.dataLayer = window.dataLayer",
                )
              ) {
                const scriptString = String(script.textContent);
                // const arr = scriptString.split('(');
                // if (arr) {
                // const obj = JSON.parse(arr[1].slice(0, -1));
                // const userglobalid = obj.userglobalid;
                let userGlobalIdMatch = scriptString.match(
                  /"userglobalid":\s*"([^"]*)"/,
                );
                let userGlobalId = userGlobalIdMatch
                  ? userGlobalIdMatch[1]
                  : null;

                let companyIdMatch = scriptString.match(
                  /"companyid":\s*"([^"]*)"/,
                );
                let companyId = companyIdMatch ? companyIdMatch[1] : null;

                let companyNameMatch = scriptString.match(
                  /"copmanyname":\s*"((?:[^"\\]|\\.)*)"/,
                );
                let companyName = companyNameMatch
                  ? companyNameMatch[1].replace(/\\\"/g, '"')
                  : null;

                let userFullNameMatch = scriptString.match(
                  /"userfullname":\s*"((?:[^"\\]|\\.)*)"/,
                );
                let userFullName = userFullNameMatch
                  ? userFullNameMatch[1].replace(/\\\"/g, '"')
                  : null;

                if (userGlobalId) {
                  clearInterval(getScripts);

                  // Resolve the promise with the obtained data
                  resolve({
                    globalID: userGlobalId,
                    companyID: companyId,
                    companyName: companyName,
                    userfullname: userFullName,
                  });
                }
                // }
              }
            });
          }
        }, 10);
      });
    }
    async function checkPowerlinkClasses(
      selectors,
      version,
      currentVersion,
      powerlinkClasses,
    ) {
      if (powerlinkClasses == null) {
        const result = await fetchClasses();

        if (result && result.selectors) {
          ({ selectors, version } = result);
        } else {
          console.error("Error fetching classes:", result);
          return;
        }

        saveToLocal("powerlinkClasses", { selectors, version });
        // window.location.reload();
      } else {
        ({ selectors: powerlinkClasses, version: currentVersion } =
          getFromLocal("powerlinkClasses"));

        const result = await fetchClasses();

        if (result && result.selectors) {
          ({ selectors, version } = result);
        } else {
          console.error("Error fetching classes:", result);
          return;
        }

        if (currentVersion !== version) {
          saveToLocal("powerlinkClasses", { selectors, version });
          // window.location.reload();
        }
      }
    }
    async function fetchClasses() {
      try {
        const response = await UseFetchGetAnonymos(
          "/api/services/getPowerlinkSelectors",
        );
        const {
          data: { selectors },
        } = response;
        return response.data;
      } catch (err) {
        console.error(err);
      }
    }

    const fetchUserDataRecord = async () => {
      const globalID = userDataRef.current.Udid;
      let isChatOpen = false; // Initialize with default value
      try {
        const link = window.location.href;
        const objectid = link
          .substring(link.indexOf("record/") + 7)
          .split("/")[0];
        const contactid = link.split(`record/${objectid}/`)[1];
        // setLink(link);
        // setObjectID(objectid);
        // setContactID(contactid);
        const res = await UseFetchGet("/api/services/GetContactFullName", {
          userglobalId: globalID,
          contactId: contactid,
          objectId: objectid,
        });
        console.log("unreadDebug getContaactFullData", res?.data);

        return {
          isChatOpen,
          whatsappPhoneNumber: changeNumber(res?.data.phoneNumber),
          userName: res.data.userFullName,
          chatUser: res.data.chatUser,
        };
      } catch (err) {
        console.log(err);
        return {
          isChatOpen: null,
          whatsappPhoneNumber: "חסר מספר טלפון",
          userName: "Name",
          chatUser: null,
        };
      }
    };
    async function getActiveObjects() {
      try {
        const res = await UseFetchGet(
          "/api/services/GetPowerlinkObjects",
          {
            companyId: userDataRef.current.CompanyId,
          },
          null,
          undefined,
          "fireberry",
        );
        const rows = res.data;
        const activeObjectTypes = rows.filter((obj) => obj.IsActive);
        // .map((obj) => obj.ObjectType);

        return activeObjectTypes;
      } catch (err) {
        console.log(err);
        return null;
      }
    }
    function getViewsObject(link) {
      let objectid;
      const parts = link.split("/");
      return (objectid = parts[5]);
    }

    async function getChatUser(phone, userName) {
      try {
        const params = {
          phoneNumberId: phone,
        };
        if (userName) params.userName = userName;

        const res = await UseFetchGet(
          "/api/services/getPriorityChatUserObject",
          params,
          null,
          undefined,
          "history",
        );

        if (res?.status === 200 && res.data) {
          const user = { ...res.data };
          if (userName) user.FullName = userName;
          return user;
        } else return null;
      } catch (error) {
        console.log("get fireberry chat user err: ", error);
        return null;
      }
    }
    async function onPageLoadCases() {
      writePerformanceLog("fireberryPlaceChatPerformanceLog", {
        companyId: businessInfo.CompanyId,
        userId: userData.Udid,
        action: "pageChange",
        date: new Date().toISOString(),
        url: pageUrl,
        sessionId: tabId,
      });

      const currentLink = window.location.href;
      const isUpdateTokenPageBool = isUpdateTokenPage();
      const isAppLinkPageBool = isAppLinkPage();
      const isViewsCardPage = checkUrlViewsCard(currentLink);
      const isViewsPage = checkUrlViews(currentLink);
      const isRecordPage = ifUserPage();
      //?
      // const { globalID } = await getGlobalScripsData();

      // console.log("isUpdateTokenPageBool: ", isUpdateTokenPageBool);
      // console.log("isAppLinkPageBool: ", isAppLinkPageBool);
      // console.log("isViewsCardPage: ", isViewsCardPage);
      // console.log("isViewsPage: ", isViewsPage);
      // console.log("isRecordPage: ", isRecordPage);

      let selectors, version, currentVersion, powerlinkClasses;

      if (getFromLocal("powerlinkClasses")) {
        ({ selectors: powerlinkClasses } = getFromLocal("powerlinkClasses"));
      }

      checkPowerlinkClasses(
        selectors,
        version,
        currentVersion,
        powerlinkClasses,
      );

      let isHashtag = false;

      const activeObjects = await getActiveObjects();
      const thisObjId = getViewsObject(currentLink);
      console.log("activeObjects: ", activeObjects);

      const thisObj = activeObjects?.find(
        (obj) => obj.ObjectType === thisObjId,
      );
      writePerformanceLog("fireberryPlaceChatPerformanceLog", {
        companyId: businessInfo.CompanyId,
        userId: userData.Udid,
        action: "gotActiveObjectsAndCheckedThisOne",
        date: new Date().toISOString(),
        url: pageUrl,
        sessionId: tabId,
      });
      if (!thisObj) return;

      // / Record page
      if (isRecordPage) {
        setPageType("recordPage");
        // const { whatsappPhoneNumber, chatUser, userName } =
        //   await fetchUserDataRecord();

        // const currentUser = {
        //   ...chatUser,
        //   UserDisplayPhoneNumber: whatsappPhoneNumber,
        //   FullName: userName,
        // };

        console.log(`selector: [title*="${thisObj.PhoneLable}"]`);
        let i = 0;

        const findPhoneLabelInterval = setInterval(() => {
          const phoneNode = findPhoneNumberByLabel(thisObj.PhoneLable);
          // const phoneNode = phoneLabel?.parentElement?.nextElementSibling.querySelector(`[data-tid="telephone"] a`) || null;
          if (phoneNode) {
            const nameNode = findNameNode();
            writePerformanceLog("fireberryPlaceChatPerformanceLog", {
              companyId: businessInfo.CompanyId,
              userId: userData.Udid,
              action: "foundPhoneAndNameNodes",
              date: new Date().toISOString(),
              url: pageUrl,
              sessionId: tabId,
            });
            placeChat(phoneNode, nameNode, thisObj);
            clearInterval(findPhoneLabelInterval);
          } else if (i >= 10000) {
            writePerformanceLog("fireberryPlaceChatPerformanceLog", {
              companyId: businessInfo.CompanyId,
              userId: userData.Udid,
              action: "foundPhoneAndNameNodesError",
              date: new Date().toISOString(),
              url: pageUrl,
              sessionId: tabId,
            });
            clearInterval(findPhoneLabelInterval);
          }

          i++;
        }, 20);
      } else if (isUpdateTokenPageBool) {
      } else if (isAppLinkPageBool) {
      } else if (isViewsCardPage) {
        setPageType("viewsCardPage");
        let i = 0;
        const findPhoneLabelInterval = setInterval(() => {
          const phoneNode = findPhoneNumberByLabel(
            thisObj.PhoneLable,
            "[data-rbd-droppable-id]",
          );
          // const phoneNode = phoneLabel?.parentElement?.nextElementSibling.querySelector(`[data-tid="telephone"] a`) || null;
          if (phoneNode) {
            const nameNode = findNameNode();
            writePerformanceLog("fireberryPlaceChatPerformanceLog", {
              companyId: businessInfo.CompanyId,
              userId: userData.Udid,
              action: "foundPhoneAndNameNodes",
              date: new Date().toISOString(),
              url: pageUrl,
              sessionId: tabId,
            });
            placeChat(phoneNode, nameNode, thisObj);
            clearInterval(findPhoneLabelInterval);
          } else if (i >= 10000) {
            writePerformanceLog("fireberryPlaceChatPerformanceLog", {
              companyId: businessInfo.CompanyId,
              userId: userData.Udid,
              action: "foundPhoneAndNameNodesError",
              date: new Date().toISOString(),
              url: pageUrl,
              sessionId: tabId,
            });
            clearInterval(findPhoneLabelInterval);
          }

          i++;
        }, 20);
      } else {
        setPageType(null);
      }
      //?
      // else if (isViewsCardPage || isViewsPage) {
      //   setIsViewsPage(true);
      //   let count = 0;
      //   const waitCardBlock = setInterval(async () => {
      //     const cardBlock = document.querySelector(powerlinkClasses?.cardsBlock);
      //     const activeCard = document.querySelector(powerlinkClasses?.activeCard);
      //     count++;
      //     if (count > 50) {
      //       clearInterval(waitCardBlock);
      //     }

      //     if (cardBlock && activeCard) {
      //       clearInterval(waitCardBlock);
      //       // to get users data need the link to have an id that's why we emulate
      //       // active card click on first load to get the id
      //       if (
      //         currentLink.includes("views") &&
      //         currentLink.split("/").length <= 8
      //       ) {
      //         activeCard.click();
      //       }

      //       const activeObjects = await getACtiveObjects();
      //       // console.log(activeObjects, 'activeObjects')
      //       activeObjectsRef.current = activeObjects;
      //       const link = window.location.href;
      //       const objectid = getViewsObject(link);

      //       const userData = await fetchUserDataViews();
      //       try {
      //         //#unread if there are no cards hide notifications badge
      //         const unreadIndicator = document.getElementById(
      //           "toaster-" + userData.chatUser.UserId,
      //         );
      //         //console.log("unreadDebug message unreadIndicator", unreadIndicator);
      //         if (unreadIndicator) {
      //           unreadIndicator.style.display = "none";
      //         }
      //       } catch (error) {
      //         //console.log("unreadDebug message on first history error", error);
      //       }
      //       if (!userData) return;
      //       let {
      //         phoneNumber,
      //         objectData: { IsActive },
      //         userFullName,
      //         messageHistory: {
      //           isActive: isMessageHistoryActive,
      //           isFunnerUserDisabled,
      //           messages,
      //         },
      //         chatUser,
      //       } = userData;
      //       phoneNumber = changeNumber(phoneNumber);
      //       dataRef.current.whatsappPhoneNumber = phoneNumber;
      //       // console.log(
      //       //   "phoneNumber_Views ref",
      //       //   dataRef.current.whatsappPhoneNumber,
      //       // );

      //       const object = extractViewsNumber(window.location.href);
      //       const dataLocal = "usersDataFromServer";
      //       const savedLocal = getFromLocal(object + dataLocal);

      //       if (activeObjects?.includes(String(objectid)) === false) {
      //         document.body.classList.remove("chat-inside-views");
      //         return; // does not run chat logic if object is not  active
      //       } else {
      //         await handleFetchNameList(phoneNumber);
      //         document.body.classList.add("chat-inside-views");

      //         const currentUser = chatHistoryObjectRef.current.usersList.find(
      //           (user) => user.userDisplayPhoneNumber === phoneNumber,
      //         );
      //         handleSelectedUser(currentUser);

      //         const containerCol = document.querySelector(
      //           powerlinkClasses?.powerlinkContainer,
      //         );
      //         if (containerCol) {
      //           setPowerlinkChatWrapper(containerCol);
      //         }
      //         setTimeout(() => {
      //           startUsersListListner();
      //         }, 3000);
      //       }
      //     }
      //   }, 100);
      // } else {
      //   setIsChatOpen(false);
      //   dataRef.current.isChatOpen = false;
      //   const userInfo = await fetchApplicationUserProfile();
      //   checkIfTokenMatch(userInfo);
      // }

      function findPhoneNumberByLabel(phoneLabel, parentSelector = "") {
        const labels = document.querySelectorAll(
          `${
            parentSelector ? parentSelector + " " : ""
          }[title*="${phoneLabel}"]`,
        );

        for (let label of labels) {
          const isRightElement =
            label?.parentElement?.nextElementSibling.getAttribute(
              "data-tid",
            ) === "telephone";
          if (isRightElement) {
            let phoneNode =
              label?.parentElement?.nextElementSibling.children[0] || null;

            console.log("phoneLabel: ", label);
            console.log("phoneNode: ", phoneNode);
            return phoneNode;
          }
        }
        return null;
      }
      function findNameNode() {
        let nameNode;

        if (isRecordPage) {
          if (thisObj === "1") {
            nameNode =
              document.querySelector(`[title="שם חברה"]`)?.parentElement
                ?.nextElementSibling || null;
          } else {
            nameNode = document
              .querySelector(`[data-tid="header"] [class*="icon icon-"]`)
              ?.parentElement?.parentElement?.nextElementSibling?.querySelector(
                `[font-size]`,
              );
          }
        } else if (isViewsCardPage) {
          nameNode =
            document.querySelector(`[data-rbd-droppable-id] [title="שם פרטי"]`)
              ?.parentElement?.nextElementSibling || null;
        }

        return nameNode;
      }
      async function placeChat(phoneNode, nameNode, thisObj) {
        // console.log("placeChat func called!");
        // const phoneNode =
        //   phoneLabel?.parentElement?.nextElementSibling.querySelector(`a`) ||
        //   null;
        console.log("nameNode: ", nameNode);

        if (phoneNode) {
          //! Phone
          let phone = null;
          const additionalNode = phoneNode.querySelector(["phone-number"]);
          if (additionalNode) {
            const phoneAttr = additionalNode.getAttribute("phone-number");
            phone = phoneGlobalRegex.test(phoneAttr)
              ? "0" + phoneAttr.slice(3)
              : phoneAttr;
          } else {
            phone = phoneGlobalRegex.test(phoneNode.innerText)
              ? "0" + phoneNode.innerText.slice(3)
              : phoneNode.innerText;
          }
          //! UserName
          let userName = null;
          if (nameNode) {
            if (thisObj === "1" || !isRecordPage) {
              userName = nameNode.innerHTML;
            } else {
              const parts = nameNode.innerHTML.split(":");
              userName = parts[1].startsWith(" ")
                ? parts[1].slice(1)
                : parts[1];
            }
          }
          const currentUser = await getChatUser(phone, userName);
          writePerformanceLog("fireberryPlaceChatPerformanceLog", {
            companyId: businessInfo.CompanyId,
            userId: userData.Udid,
            action: "gotChatUser",
            date: new Date().toISOString(),
            url: pageUrl,
            sessionId: tabId,
          });

          if (currentUser) {
            handleSelectedFireberryChatUser(currentUser);

            const walker = document.createTreeWalker(
              document,
              NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
              null,
              false,
            );

            while (walker.nextNode()) {
              const currentNode = walker.currentNode;
              if (
                currentNode.nodeType === Node.TEXT_NODE &&
                currentNode.textContent === "#FUNNER"
              ) {
                const localStorageFoldState = await getFromChromeStorage(
                  "funner-fireberry-fold",
                );
                if (localStorageFoldState) {
                  setChatFolded(true);
                } else {
                  setChatFolded(false);
                }
                const placementContainer =
                  currentNode.parentElement.closest(
                    "[data-rbd-draggable-id]",
                  ) || currentNode.parentElement;
                if (placementContainer) {
                  placementContainer.innerHTML = "";
                  setPowerlinkChatWrapper(placementContainer);
                  writePerformanceLog("fireberryPlaceChatPerformanceLog", {
                    companyId: businessInfo.CompanyId,
                    userId: userData.Udid,
                    action: "chatPlaced",
                    date: new Date().toISOString(),
                    url: pageUrl,
                    sessionId: tabId,
                  });
                }
                setIsHashtag(true);
                isHashtag = true;
                return;
              }
            }

            setIsHashtag(false);
            isHashtag = false;
            const containerCol = document.querySelector(
              powerlinkClasses?.powerlinkContainer,
            );
            const columnInsides = document.querySelector(
              powerlinkClasses?.colNextToChat,
            );

            contentColRef.current = columnInsides;

            // console.log("RecordPage containerCol: ", containerCol);
            // console.log("RecordPage columnInsides: ", columnInsides);

            // if (containerCol && columnInsides) {
            if (containerCol) {
              if (columnInsides) {
                columnInsides.style.display = "block";
                columnInsides.classList.add("fireberry-content-col");
              }
              //console.log('inside classes')
              const localStorageFoldState = await getFromChromeStorage(
                "funner-fireberry-fold",
              );
              if (localStorageFoldState) {
                if (columnInsides) columnInsides.classList.remove("half-width");
                setChatFolded(true);
              } else {
                if (columnInsides) columnInsides.classList.add("half-width");
                setChatFolded(false);
              }
              containerCol.classList.add("flex-cont");
              setPowerlinkChatWrapper(containerCol);
              writePerformanceLog("fireberryPlaceChatPerformanceLog", {
                companyId: businessInfo.CompanyId,
                userId: userData.Udid,
                action: "chatPlaced",
                date: new Date().toISOString(),
                url: pageUrl,
                sessionId: tabId,
              });
            }
          }
        }
      }
    }
    function pageWatcher() {
      let watcherInterval = setInterval(() => {
        const urlLocal = pageUrl;
        const url = window.location.href;

        if (urlLocal !== url) {
          pageUrl = window.location.href.toString();
          setTimeout(() => {
            setPowerlinkChatWrapper(null);
            onPageLoadCases();
          }, 0);
        }
      }, 100);
    }

    useEffect(() => {
      onPageLoadCases();
      pageWatcher();
    }, []);

    return (
      <>
        {powerlinkChatWrapper &&
          createPortal(
            <Box
              sx={{
                width: !isHashtag
                  ? chatFolded
                    ? 29
                    : pageType === "viewsCardPage"
                    ? "100%"
                    : MDBreakpoint
                    ? "calc(50% - 5px)"
                    : "calc(50% - 7.5px)"
                  : undefined,
                flex: !isHashtag
                  ? chatFolded
                    ? "0 0 29px"
                    : pageType === "viewsCardPage"
                    ? "100%"
                    : MDBreakpoint
                    ? "0 0 calc(50% - 5px)"
                    : "0 0 calc(50% - 7.5px)"
                  : undefined,
                overflow: isHashtag ? "hidden" : undefined,
                height: isHashtag ? (chatFolded ? 65 : "75vh") : undefined,
                position: "relative",
                transition: "height 0.5s ease 0s",
                boxShadow: isHashtag
                  ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                  : undefined,
                // order: pageType === "viewsCardPage" ? -1 : null,
                order: -1,
              }}
              className="_funner-global-messanger-container"
            >
              {chatFolded && !isHashtag && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: 28,
                    height: "75vh",
                    zIndex: 100,
                    borderRight: "1px solid",
                    borderColor: "primary.main",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: 0.325,
                      borderRadius: 0.75,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      minWidth: 0,
                      py: 2,
                      width: "100%",
                    }}
                    onClick={toggleChatFold}
                  >
                    <img
                      src={hintLogo}
                      alt=""
                      style={{ width: 20, height: 20 }}
                    />
                  </Button>
                </Box>
              )}
              <Chat
                chatType={"fireberry"}
                selectedUserId={
                  selectedFireberryChatUser?.UserDisplayPhoneNumber
                }
                selectedChatUser={selectedFireberryChatUser}
                selectedUserRef={selectedFireberryChatUserRef}
                openChatByContact={() => {}}
                chatUsersFunnerRef={chatUsersGlobalRef}
                chatUsersGlobalRef={chatUsersGlobalRef}
                userData={userData}
                userDataRef={userDataRef}
                userConfig={userConfig}
                chatLoading={chatLoading}
                setChatLoading={setChatLoading}
                funnerUsers={funnerUsers}
                closeChat={() => {}}
                handleSetNotif={handleSetNotif}
                chatUsersLabelsList={chatUsersLabelsList}
                toggleUnreadMessagesPopup={toggleChatFold}
                updateChatUser={updateChatUser}
                businessInfo={businessInfo}
                templatesList={templatesList}
                templatesLoading={templatesLoading}
                fetchWhatsappTemplates={fetchWhatsappTemplates}
                connection={connection}
                isConnection={isConnection}
                tabActiveRef={tabActiveRef}
                chatUsersMessagesRef={chatUsersMessagesRef}
                containerStyle={{
                  opacity: chatFolded && !isHashtag ? 0 : 1,
                }}
                chatFolded={chatFolded}
                isHashtag={isHashtag}
                handleChatUserMessages={handleChatUserMessages}
                textareaRef={textareaRef}
                getChatMissingHistory={getChatMissingHistory}
                chatUsersGlobal={chatUsersGlobal}
                goToFavouriteMessageStateRef={{
                  current: null,
                }}
              />
            </Box>,
            powerlinkChatWrapper,
          )}

        <ChatUsersFireberrySync
          open={isUsersFireberrySyncWindow}
          handleOpenState={handleUsersFireberrySettingsWindow}
          selectedUsersFireberrySync={selectedUsersFireberrySync}
          setSelectedUsersFireberrySync={setSelectedUsersFireberrySync}
          handleSetNotif={handleSetNotif}
        />
      </>
    );
  },
);

export default FireberryPlatform;
