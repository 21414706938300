import { Close, SendOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ChatMessagesPreview from "./ChatMessagesPreview";
import { UseFetchGet } from "../../../hooks/fetchFunctions";
import axios from "axios";
import { IsJsonString } from "../../../libs/helpers";
import { enqueueSnackbar } from "notistack";
import { phoneGlobalRegex } from "../../../libs/regexes";
import { useLocalization } from "../../Context/LocalizationContext";

function ForwardMessagesToWebhookPreview({
  open,
  onClose,
  onSubmit,
  userConfig,
  chatCustomizationSettings,
  messages,
  scrollToMessage,
  templatesList,
  funnerUsers,
  chatUsersGlobalRef,
  handleMediaPreview,
  selectedUserId,
  companyId,
  companyPlatforms,
}) {
  const forwardMessagesButtonsWebhook = useMemo(() => {
    let webhook = null,
      status = false;
    if (IsJsonString(companyPlatforms)) {
      const platformsObject = JSON.parse(companyPlatforms);
      const webHooksList = platformsObject.webHooks;
      if (webHooksList) {
        const findedForwardMessagesButtonsWebhook = webHooksList.find(
          (webhook) =>
            webhook.WebhookIdentifier === "forwardMessagesButtonsWebhook",
        );
        if (findedForwardMessagesButtonsWebhook) {
          webhook = findedForwardMessagesButtonsWebhook.WebhookUrl;
          const webhookActiveStates = platformsObject.webHooksActiveStates;
          status = webhookActiveStates?.forwardMessagesButtonsWebhook ?? false;
        }
      }
    }
    return { url: webhook, status };
  }, [companyPlatforms]);

  const buttonsEnabled =
    companyId === "987a2eaa-4d89-43e5-8e58-c7006513b9f4" &&
    // companyId === "a226c4d1-72de-4743-b332-6b70cd22fec2" &&
    forwardMessagesButtonsWebhook?.status;

  const { currentLocale } = useLocalization();
  const localization = React.useMemo(
    () => currentLocale.chat.messages.forwardMessagesToWebhook,
    [currentLocale],
  );

  const [buttons, setButtons] = useState([]);
  const [buttonsLoading, setButtonsLoading] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [description, setDescription] = useState("");

  const [isAdmin, setIsAdmin] = useState(false);
  const [priorityCustomers, setPriorityCustomers] = useState([]);
  const [priorityCustomerPhonebook, setPriorityCustomerPhonebook] = useState(
    [],
  );
  const [selectedPhonebookItem, setSelectedPhonebookItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const priorityCustomersSearchParams = useRef({
    name: "",
    top: 100,
    skip: 0,
  });

  const handlePriorityCustomersSearchParams = (params) => {
    priorityCustomersSearchParams.current = {
      ...priorityCustomersSearchParams.current,
      ...params,
    };
  };
  const handleSearch = (value) => {
    setSearchQuery(value);
    // debouncedRequestOnSearch(value);
  };
  const handleSearchClick = () => {
    requestSearch(searchQuery);
  };
  const handleSelectedCustomer = async (value) => {
    setButtonsLoading(true);
    setSelectedButton(value);
    setPriorityCustomerPhonebook([]);
    setSelectedPhonebookItem(null);
    await getPriorityCustomerPhonebook(value);
    setButtonsLoading(false);
  };

  const closePopup = () => {
    onClose();
    setTimeout(() => {
      setButtons([]);
      setDescription("");
      setSelectedButton(null);
      setIsAdmin(false);
    }, 300);
  };

  async function getButtons() {
    try {
      setButtonsLoading(true);
      // const res = await UseFetchGet(
      //   "/api/services/getButtons",
      //   {
      //     phoneNumber: selectedUserId,
      //   },
      //   null,
      //   false,
      //   "history",
      // );
      if (!forwardMessagesButtonsWebhook.url)
        return enqueueSnackbar("No url set to webhook", {
          variant: "error",
        });

      if (
        !forwardMessagesButtonsWebhook.url
          .toLowerCase()
          .includes("{phoneNumber}".toLowerCase())
      )
        return enqueueSnackbar(
          localization.noPlaceholderNotif,
          {
            variant: "error",
          },
        );

      //* Default behaviour
      const userFormattedPhoneNumber = phoneGlobalRegex.test(selectedUserId)
        ? `0${selectedUserId.slice(3, 5)}${selectedUserId.slice(5)}`
        : selectedUserId;
      //* Request phone number for buttons (data) list
      // const userFormattedPhoneNumber = phoneGlobalRegex.test("972522676961")
      //   ? `0${"972522676961".slice(3, 5)}${"972522676961".slice(5)}`
      //   : "972522676961";
      //* Request phone number for isAdmin
      // const userFormattedPhoneNumber = phoneGlobalRegex.test("972544859252")
      //   ? `0${"972544859252".slice(3, 5)}${"972544859252".slice(5)}`
      //   : "972544859252";
      const resultLink = forwardMessagesButtonsWebhook.url
        .toLowerCase()
        .replace("{phoneNumber}".toLowerCase(), userFormattedPhoneNumber);
      const res = await axios.get(resultLink);

      setIsAdmin(Boolean(res?.data?.isAdmin));
      if (res?.data?.data?.length) {
        setButtons(res.data.data);
      } else {
        setButtons([]);
      }
    } catch (error) {
      console.error(error);
      // setButtons([]);
    } finally {
      setButtonsLoading(false);
    }
  }

  async function getPriorityCustomers() {
    try {
      const res = await UseFetchGet(
        "/api/priority/findPriorityCustomerByName",
        priorityCustomersSearchParams.current,
        null,
        undefined,
        "priority",
      );

      if (res?.data?.customers) {
        const customersObj = IsJsonString(res.data.customers)
          ? JSON.parse(res.data.customers)
          : res.data.customers;

        const list = customersObj?.value || [];
        // console.log("getPriorityCustomers list: ", list);
        return list;
      } else return [];

      // setContactsList(res?.data?.contacts?.value || []);
      // setCustomersList(res?.data?.customers?.value || []);
    } catch (error) {
      console.log("getPriorityContacts error: ", error);
      return [];
    }
  }
  async function getPriorityCustomerPhonebook(value) {
    try {
      const res = await UseFetchGet(
        "/api/priority/getPriorityCustomerPhonebook",
        {
          name: value,
        },
        null,
        false,
        "priority",
      );

      if (res?.data?.result) {
        const parsedValue =
          IsJsonString(res.data.phonebook) && JSON.parse(res.data.phonebook);
        setPriorityCustomerPhonebook(parsedValue?.value || []);
      }
    } catch (error) {
      console.error("getPriorityCustomerPhonebook error", error);
    }
  }

  const requestSearch = async (searchValue) => {
    setButtonsLoading(true);
    handlePriorityCustomersSearchParams({
      skip: 0,
      name: searchValue,
    });
    const list = await getPriorityCustomers();
    setPriorityCustomers(list);
    setButtonsLoading(false);
  };

  // const debouncedRequestOnSearch = useCallback(
  //   debounce(async (searchValue) => {
  //     setButtonsLoading(true);
  //     handlePriorityCustomersSearchParams({
  //       skip: 0,
  //       name: searchValue,
  //     });
  //     const list = await getPriorityCustomers();
  //     setPriorityCustomers(list);
  //     setButtonsLoading(false);
  //   }, 1000),
  //   [],
  // );

  useEffect(() => {
    if (open && buttonsEnabled) {
      getButtons();
    }
  }, [open, buttonsEnabled]);

  return (
    <Dialog
      open={open}
      onClose={closePopup}
      PaperProps={{
        sx: {
          // minWidth: 500,
          width: "100%",
          maxWidth: 499.98,
        },
      }}
    >
      <DialogTitle>
        <Grid container spacing={1.5} alignItems="center">
          <Grid item flexGrow={1}>
            <Typography fontWeight={600} fontSize={20}>
              {localization.title}
            </Typography>
          </Grid>
          {/* <Grid item xs="auto">
            <IconButton
              onClick={() => {
                onClose();
                onSubmit(messages);
              }}
            >
              <SendOutlined />
            </IconButton>
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Grid> */}
        </Grid>
      </DialogTitle>
      <DialogContent>
        {buttonsLoading ? (
          <Box
            sx={{
              width: "100%",
              minHeight: 120,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={50} color="secondary" />
          </Box>
        ) : (
          <Grid container spacing={2.5} sx={{ pt: 1 }}>
            {/* {buttonsEnabled && buttons.length > 0 && ( */}
            {buttonsEnabled && isAdmin && (
              <Fragment>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Search"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={() => handleSearchClick()}
                  >
                    Search
                  </Button>
                </Grid>
                {priorityCustomers.length > 0 && (
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="forward-messages-webhook-buttons-label">
                        {localization.selectTitle}
                      </InputLabel>
                      <Select
                        labelId="forward-messages-webhook-buttons-label"
                        id="forward-messages-webhook-buttons"
                        value={selectedButton || ""}
                        label={localization.selectTitle}
                        onChange={(e) => handleSelectedCustomer(e.target.value)}
                      >
                        {/* {buttons.map((btn) => ( */}
                        {priorityCustomers.map((btn) => (
                          <MenuItem
                            // value={btn.description}
                            // key={btn.description}
                            value={btn.CUSTNAME}
                            key={btn.CUSTNAME}
                          >
                            {/* {btn.title} */}
                            {btn.CUSTDES}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {priorityCustomers.length > 0 && selectedButton && (
                  <Grid item xs={12}>
                    {priorityCustomerPhonebook.length > 0 ? (
                      <FormControl fullWidth>
                        <InputLabel id="forward-messages-webhook-buttons-label">
                          Phonebook item
                        </InputLabel>
                        <Select
                          labelId="forward-messages-webhook-buttons-label"
                          id="forward-messages-webhook-buttons"
                          value={selectedPhonebookItem || ""}
                          label={"Phonebook item"}
                          onChange={(e) =>
                            setSelectedPhonebookItem(e.target.value)
                          }
                        >
                          {/* {buttons.map((btn) => ( */}
                          {priorityCustomerPhonebook.map((btn) => (
                            <MenuItem
                              // value={btn.description}
                              // key={btn.description}
                              value={btn.PHONE}
                              key={btn.PHONE}
                            >
                              {/* {btn.title} */}
                              {btn.NAME}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography>Empty phonebook</Typography>
                    )}
                  </Grid>
                )}
              </Fragment>
            )}
            {buttonsEnabled && !isAdmin && buttons.length > 0 && (
              <Fragment>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="forward-messages-webhook-buttons-label">
                      {localization.selectTitle}
                    </InputLabel>
                    <Select
                      labelId="forward-messages-webhook-buttons-label"
                      id="forward-messages-webhook-buttons"
                      value={selectedButton || ""}
                      label={localization.selectTitle}
                      onChange={(e) => handleSelectedCustomer(e.target.value)}
                    >
                      {buttons.map((btn) => (
                        <MenuItem value={btn.description} key={btn.description}>
                          {btn.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {selectedButton && (
                  <Grid item xs={12}>
                    {priorityCustomerPhonebook.length > 0 ? (
                      <FormControl fullWidth>
                        <InputLabel id="forward-messages-webhook-buttons-label">
                          Phonebook item
                        </InputLabel>
                        <Select
                          labelId="forward-messages-webhook-buttons-label"
                          id="forward-messages-webhook-buttons"
                          value={selectedPhonebookItem || ""}
                          label={"Phonebook item"}
                          onChange={(e) =>
                            setSelectedPhonebookItem(e.target.value)
                          }
                        >
                          {/* {buttons.map((btn) => ( */}
                          {priorityCustomerPhonebook.map((btn) => (
                            <MenuItem
                              // value={btn.description}
                              // key={btn.description}
                              value={btn.PHONE}
                              key={btn.PHONE}
                            >
                              {/* {btn.title} */}
                              {btn.NAME}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <Typography>Empty phonebook</Typography>
                    )}
                  </Grid>
                )}
              </Fragment>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={localization.description}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mt: 1, maxWidth: 500 }}
                multiline
                minRows={1}
                maxRows={5}
              />
            </Grid>
          </Grid>
        )}

        {/* <ChatMessagesPreview
          userConfig={userConfig}
          chatCustomizationSettings={chatCustomizationSettings}
          showMessagesList={messages}
          scrollToMessage={scrollToMessage}
          templatesList={templatesList}
          funnerUsers={funnerUsers}
          chatUsersGlobalRef={chatUsersGlobalRef}
          handleMediaPreview={handleMediaPreview}
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            closePopup();
            onSubmit(messages, {
              button: selectedButton,
              description,
              phoneNumber: selectedUserId,
            });
          }}
          disabled={!selectedButton && buttonsEnabled}
        >
          {localization.send}
        </Button>
        <Button variant="outlined" onClick={() => closePopup()}>
          {localization.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ForwardMessagesToWebhookPreview;
