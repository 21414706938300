import React, { createContext, useState, useContext } from "react";

import en from "../../localizations/en.json";
import he from "../../localizations/he.json";
import { getFromLocal, saveToLocal } from "../../hooks/localStorage";

const translations = {
  en,
  he,
};

const LocalizationContext = createContext();

export const LocalizationLanguageProvider = ({ children }) => {
  const getParams = new URLSearchParams(document.location.search);
  let langFromParams = getParams.get("lang");
  const savedLanguage = langFromParams ?? getFromLocal("appLanguage") ?? "he";
  const [currentLanguage, setCurrentLanguage] = useState(savedLanguage);
  const [languageDirection, setLanguageDirection] = useState(
    savedLanguage === "he" ? "rtl" : "ltr",
  );

  const switchLanguage = React.useCallback((language) => {
    if (translations[language]) {
      setCurrentLanguage(language);
      setLanguageDirection(language === "he" ? "rtl" : "ltr");
      if (language === "he") {
        if (!document.body.classList.contains("rtl"))
          document.body.classList.add("rtl");
      } else {
        if (document.body.classList.contains("rtl"))
          document.body.classList.remove("rtl");
      }
    } else {
      console.warn(`Language ${language} is not supported`);
    }
  }, []);

  React.useEffect(() => {
    if (savedLanguage !== "he") {
      document.body.classList.remove("rtl");
    }

    if (langFromParams) {
      saveToLocal("appLanguage", langFromParams);
    }
  }, []);

  const value = {
    currentLanguage,
    currentLocale: translations[currentLanguage],
    switchLanguage,
    languageDirection,
  };

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
