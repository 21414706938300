import React, { useEffect, useRef, useState } from "react";
import { UseFetchPost } from "../../hooks/fetchFunctions";
import { greenCheck } from "../../libs/images";
import AddUsersFromFile from "../../modules/add-users-from-file";
import phoneNumbersByCountries from "../../phoneNumbersByCountries.json";
import TemplatesMenu from "../Templates/TemplatesMenu";
import Tippy from "@tippyjs/react";
import { tippyPrefs } from "../../modules/modulesConfig";
import { getImplementedVars } from "../../libs/implementedVars";
import { Box, CircularProgress } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useLocalization } from "../Context/LocalizationContext";

// let templatesHolder = null;
const messageConfig = {
  messageType: null,
  reply: false,
  isTemplateMessage: false,
  replyId: null,
  templateName: null,
  defaultTemplateMessage: null,
  templateLanguage: null,
  areParamsSet: null,
  templateFileLink: null,
};
let isToggleNotActiveTemplatesOn = false;

function AddUsersChat({
  setShowUsersCreateChat,
  handleSetNotif,
  setUserCreationState,
  templatesList,
  globalID,
  fetchWhatsappTemplates,
  openChatByContact,
  getCompanyChatUsers,
  getCompanyChatUsersGlobal,
  initialAddType = null,
}) {
  const { currentLocale } = useLocalization();

  const { localization, taskLocalization, notifLocalization, implementedVars } =
    React.useMemo(() => {
      return {
        localization: currentLocale.chat.createUsers,
        taskLocalization: currentLocale.settingsPopup.massMessagingTasks,
        notifLocalization: currentLocale.chat.notifications.addUsers,
        implementedVars: getImplementedVars(currentLocale),
      };
    }, [currentLocale]);

  const [taskCreateUpdateStep, setTaskCreateUpdateStep] = useState("taskInfo");

  const [userData, setUserData] = useState({
    phoneNumber: "",
  });
  const [isValidateUserPhone, setIsValidateUserPhone] = useState(true);
  const [addUserType, setAddUserType] = useState(initialAddType);
  const [isManuallyFireberryChecked, setIsManuallyFireberryChecked] =
    useState(false);
  const [isByFileFireberryChecked, setIsByFileFireberryChecked] =
    useState(false);
  const [fileName, setFileName] = useState(
    localization.fromFile.noTemplate.fileName,
  );
  const [usersFromFileList, setUsersFromFileList] = useState([]);
  const [isUsersFromFileSendToSave, setIsUsersFromFileSendToSave] =
    useState(false);
  const [isSendTemplate, setIsSendTemplate] = useState(false);
  const [createdUser, setCreatedUser] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  const [showVars, setShowVars] = useState(false);
  const [isXlsxLoading, setIsXlsxLoading] = useState(false);
  const [isDynamicVars, setIsDynamicVars] = useState(false);

  const [selectedPhoneNumberType, setSelectedPhoneNumberType] = useState(
    phoneNumbersByCountries.countriesList.find(
      (country) => country.countryCode === "he",
    ),
  );

  const fileInputRef = useRef(null);
  const selectedTemplateRef = useRef({ selectedTemplate: null });
  const varsDataRef = useRef({ varsData: null });
  const selectedTaskRef = useRef(null);
  const templatesObjectRef = useRef(null);

  const handleUserData = (params) => {
    setUserData((prevData) => ({ ...prevData, ...params }));
  };

  const handleAddUserType = (type) => {
    setAddUserType(type);
    if (type === "by-file") {
      setSelectedTask({
        Name: "",
        templateObject: null,
      });
    } else {
      setSelectedTask(null);
    }
  };

  const handleRemoveUserType = () => {
    setAddUserType(null);
    setIsManuallyFireberryChecked(false);
    setIsByFileFireberryChecked(false);
    setFileName("file name");
    setUsersFromFileList([]);
    setIsUsersFromFileSendToSave(false);
  };

  const handleClosePopup = (e) => {
    if (!e.target.closest(".add-new-user-funner__content")) {
      setShowUsersCreateChat(false);
    }
  };

  const handleManuallyFireberryCheck = (e) => {
    setIsManuallyFireberryChecked(e.target.checked);
  };
  const handleByFileFireberryCheck = (e) => {
    setIsByFileFireberryChecked(e.target.checked);
  };
  const handleIsSendTemplate = (e) => {
    setIsSendTemplate(e.target.checked);
  };
  const handleTaskParams = (param, value) => {
    setSelectedTask((prevObj) => {
      const newObj = { ...prevObj };
      newObj[param] = value;
      return newObj;
    });
  };
  const toggleShowVars = () => {
    const prevState = showVars;
    setShowVars(!prevState);
  };

  function handleUploadFileTemplate(filePath) {
    handleTaskParams("filePath", filePath);
  }

  const validateFullPhoneNumber = (input) => {
    const phoneNumberInput = input;
    const phoneNumber = phoneNumberInput.value.replace(/\D/g, "");

    // if (/^05\d{8}$/.test(phoneNumber)) {
    if (new RegExp(selectedPhoneNumberType.validRegex).test(phoneNumber)) {
      clearError(phoneNumberInput);
      return true;
    } else {
      const errorText =
        phoneNumberInput.getAttribute &&
        phoneNumberInput.getAttribute("data-error-text")
          ? phoneNumberInput.getAttribute("data-error-text")
          : "tel is invalid";
      setError(phoneNumberInput, errorText);
      return false;
    }
  };
  const validateTextInput = (input, charsCount, wordsCount = null) => {
    const textInput = input;
    if (textInput?.value?.length < charsCount) {
      const errorText = textInput.getAttribute("data-error-text")
        ? textInput.getAttribute("data-error-text")
        : "input text is invalid";
      setError(textInput, errorText);
      return false;
    } else {
      if (
        wordsCount &&
        (input.value.split(" ").length !== wordsCount ||
          (input.value.split(" ").length === wordsCount &&
            input.value.split(" ")[wordsCount - 1] === ""))
      ) {
        const errorText = textInput.getAttribute("data-error-text")
          ? textInput.getAttribute("data-error-text")
          : "there must be 2 words";
        setError(textInput, errorText);
        return false;
      } else {
        clearError(textInput);
        return true;
      }
    }
  };
  const inputMaxCount = (input, count) => {
    let value = input.value;
    if (value.length > count) value = value.slice(0, count);
    input.value = value;
  };
  const setError = (el, text) => {
    const error = el.parentElement.querySelector(".error");
    if (error) {
      error.innerText = text;
    }
    if (
      el.closest("label") &&
      !el.closest("label").classList.contains("input-error")
    ) {
      el.closest("label").classList.add("input-error");
    } else if (
      el.closest(".label") &&
      !el.closest(".label").classList.contains("input-error")
    ) {
      el.closest(".label").classList.add("input-error");
    }
  };
  const clearError = (el) => {
    const error = el.parentElement.querySelector(".error");
    if (error) {
      error.innerText = "";
    }
    if (el.closest("label")) {
      if (el.closest("label").classList.contains("input-error")) {
        el.closest("label").classList.remove("input-error");
      }
    } else if (el.closest(".label")) {
      if (el.closest(".label").classList.contains("input-error")) {
        el.closest(".label").classList.remove("input-error");
      }
    }
  };
  const handlePhoneNumberType = (option) => {
    setSelectedPhoneNumberType(
      phoneNumbersByCountries.countriesList.find(
        (country) => country.countryCode === option.value,
      ),
    );
  };

  async function submitManuallyCreateUser(e) {
    e.preventDefault();

    const form = e.target;
    const firstNameInput = form["add-manually-new-user-funner__first-name"];
    const lastNameInput = form["add-manually-new-user-funner__last-name"];
    const phoneInput = form["add-manually-new-user-funner__phone"];

    const isFirstName = validateTextInput(firstNameInput, 1);
    const isLastName = validateTextInput(lastNameInput, 1);
    // const isPhone = validateFullPhoneNumber(phoneInput);
    const isPhone = isValidateUserPhone
      ? validateFullPhoneNumber(phoneInput)
      : userData.phoneNumber.length > 0;

    const isFormValid = isFirstName && isLastName && isPhone;
    if (isFormValid) {
      const params = {
        firstName: firstNameInput.value,
        lastName: lastNameInput.value,
        phone: phoneInput.value,
        isFireberry: isManuallyFireberryChecked,
        phoneType: selectedPhoneNumberType,
      };
      const result = await UseFetchPost(
        "/api/services/CreateChatUser",
        params,
        "config",
      );
      // const result = {
      //   data: {
      //     UserId: 10,
      //   },
      // };
      if (result.data) {
        if (result.data.UserDisplayPhoneNumber) {
          getCompanyChatUsers();
          getCompanyChatUsersGlobal();
          // alert("User successfully created!");
          handleSetNotif(notifLocalization.manually.success, "success");
          // updateChatUsersList();
          // setUserCreationState(Math.random());
          const user = { ...result.data, isRestricted24: false };
          if (isSendTemplate) {
            setCreatedUser(user);
            setAddUserType("selectTemplate");
          } else {
            setShowUsersCreateChat(false);
            openChatByContact(user);
          }
        } else {
          handleSetNotif(result.data.message, "error");
        }
      }
    }
  }

  async function handleUploadUsersFile(e) {
    const file = e.target.files[0];
    const thisFileName = file.name;
    setFileName(thisFileName);

    const res = await importChatUsersViaXlsx(file, false, false);
    console.log(res);
    if (res) {
      setUsersFromFileList([...res]);
    }
    e.target.value = null;
  }
  async function saveCurrentResult() {
    const fileInput = fileInputRef.current;
    const file = fileInput.files[0];

    const res = await importChatUsersViaXlsx(
      file,
      true,
      isByFileFireberryChecked,
    );
    console.log(res);
    if (res.length) {
      setIsUsersFromFileSendToSave(true);
      setUsersFromFileList([...res]);
      handleSetNotif(notifLocalization.file.creationResultSuccess, "success");
      // setUserCreationState(Math.random());
      // updateChatUsersList();
    }
  }

  async function sendTemplateSingleContact() {
    try {
      const noWhatsappErr = "No phone number WhatsApp account";
      const response = await UseFetchPost(
        "/api/whatsapp/SendWhatsappTemplateMessage",
        templatesObjectRef.current,
        "messages",
      );
      if (!response.data.result) {
        if (response.data.message === noWhatsappErr) {
          handleSetNotif(notifLocalization.sendTemplate.error, "error");
        }
        throw new Error("Error: " + response.data.message);
      }
      if (response.data.result) {
        handleSetNotif(notifLocalization.sendTemplate.success, "success");
        setShowUsersCreateChat(false);
        openChatByContact(createdUser);
      }
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  }

  async function importChatUsersViaXlsx(file, isSave, isFireberry) {
    setIsXlsxLoading(true);
    const warningNotif = enqueueSnackbar(
      localization.fromFile.waitForFileLoadNotif,
      {
        variant: "warning",
        persist: true,
      },
    );
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("isSave", isSave);
      formData.append("isFireberry", isFireberry);
      formData.append("dynamicVars", isDynamicVars);

      const result = await UseFetchPost(
        "/api/services/importChatUsersViaXlsx",
        formData,
        "config",
      );
      if (result.data) {
        if (result.data.newImportedChatUsers?.length) {
          return result.data.newImportedChatUsers;
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.log("importChatUsersViaXlsx err: ", error);
      return null;
    } finally {
      setIsXlsxLoading(false);
      closeSnackbar(warningNotif);
    }
  }
  async function submitNewTask(e) {
    e.preventDefault();

    setSelectedTask((prevObj) => {
      return { ...prevObj, templateObject: templatesObjectRef.current };
    });
    selectedTaskRef.current.templateObject = templatesObjectRef.current;

    const createGroupRes = await createMassMessagingTaskFile();
    if (createGroupRes) {
      handleSetNotif(notifLocalization.createTask.success, "success");
      setShowUsersCreateChat(false);
    } else {
      handleSetNotif(notifLocalization.createTask.error, "error");
    }
  }
  async function createMassMessagingTaskFile() {
    try {
      // const formData = new FormData();

      // formData.append("name", selectedTaskRef.current.Name);
      // formData.append("description", selectedTaskRef.current.Description);
      // formData.append("file", selectedTaskRef.current.File);
      // formData.append("sendDate", selectedTaskRef.current.SendDate);
      // formData.append("templateObject", selectedTaskRef.current.templateObject);

      const params = {
        name: selectedTaskRef.current.Name,
        description: "",
        // massMessagingGroupId: selectedTaskRef.current.MassMessagingGroupId,
        filePath: selectedTaskRef.current.filePath,
        // sendDate: "",
        templateObject: selectedTaskRef.current.templateObject,
      };

      const res = await UseFetchPost(
        `/api/massMessaging/createUpdateFunnerMassMessagingTaskFile/null`,
        params,
        "config",
      );
      console.log(res);
      if (res?.data?.result) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  useEffect(() => {
    // if (
    //   addUserType === "selectTemplate" ||
    //   (addUserType === "by-file" &&
    //     isSendTemplate &&
    //     taskCreateUpdateStep === "taskTemplate")
    // ) {
    //   const parentTemplatesElement = document.querySelector(
    //     ".add-users-chat__add-new-user",
    //   );
    //   createSelectElementLang(
    //     whatsappTemplatesList?.languages,
    //     "he",
    //     filterTemplatesByLang,
    //     handleLangSelect,
    //     parentTemplatesElement,
    //   );
    //   isToggleNotActiveTemplatesOn = false;
    //   templatesInitialFilter(parentTemplatesElement);
    //   addEventTemplateSearch(parentTemplatesElement);
    // }
  }, [addUserType, taskCreateUpdateStep]);

  useEffect(() => {
    selectedTaskRef.current = { ...selectedTask };
  }, [selectedTask]);

  return (
    <>
      {/* <div
        className="add-users-chat__add-new-user add-new-user-funner whatsappPage"
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
          zIndex: 10000000000000000000,
          padding: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        onClick={handleClosePopup}
      > */}
      <div
        className="add-new-user-funner__content"
        style={{
          overflow: "auto",
          padding: "60px 20px 40px",
          height: "75vh",
          boxSizing: "border-box",
          maxWidth: "100%",
          width: "100%",
          position: "relative",
          borderRadius: 4,
        }}
      >
        <div
          className="add-new-user-funner__close"
          onClick={() => setShowUsersCreateChat(false)}
          style={{ zIndex: 10000000 }}
        ></div>
        {addUserType && addUserType !== initialAddType && (
          <div
            className="add-new-user-funner__prev-btn"
            onClick={handleRemoveUserType}
          >
            <svg
              width={24}
              height={25}
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.49003 20.6299L8.27003 22.3999L18.16 12.4999L8.26003 2.5999L6.49003 4.3699L14.62 12.4999L6.49003 20.6299Z"
                fill="#2a3847"
              />
            </svg>
          </div>
        )}
        {!addUserType && (
          <div className="add-new-user-funner__add-type add-type-new-user-funner">
            <div className="add-type-new-user-funner__buttons">
              <h3
                className="main-popup-content-title"
                style={{ marginBottom: 20 }}
              >
                {localization.mainTitle}
              </h3>
              <button
                className="add-type-new-user-funner__add add-type-new-user-funner__add--manually popup__blue-btn"
                onClick={() => {
                  handleAddUserType("manually");
                  setIsDynamicVars(false);
                }}
              >
                {localization.creationType.mannualy}
              </button>
              <button
                className="add-type-new-user-funner__add add-type-new-user-funner__add--from-file popup__blue-btn"
                onClick={() => {
                  handleAddUserType("by-file");
                  setIsDynamicVars(false);
                }}
              >
                {localization.creationType.fromFile}
              </button>
              <button
                className="add-type-new-user-funner__add add-type-new-user-funner__add--from-file popup__blue-btn"
                onClick={() => {
                  handleAddUserType("by-file");
                  setIsDynamicVars(true);
                }}
              >
                {localization.creationType.fromFileDynamicVars}
              </button>

              <div
                className="form-get-token-popup__gray-check"
                style={{ flex: "0 0 auto", marginTop: 10 }}
              >
                <input
                  onChange={handleIsSendTemplate}
                  name="add-manually-new-user-funner__is-fireberry"
                  id="add-manually-new-user-funner__is-fireberry"
                  type="checkbox"
                  checked={isSendTemplate}
                  className="confirm-policy-checkbox"
                />
                <label
                  htmlFor="add-manually-new-user-funner__is-fireberry"
                  className={`form-get-token-popup__checkbox`}
                >
                  <img src={greenCheck} className="valid-icon" alt="" />
                  <span className="_check-text">
                    {localization.creationType.templateCheckboxText}
                  </span>
                </label>
              </div>
            </div>
          </div>
        )}
        {addUserType === "manually" && (
          <div className="add-manually-new-user-funner">
            <div className="add-manually-new-user-funner__form-wrapper">
              <form
                action="#"
                onSubmit={submitManuallyCreateUser}
                className="add-manually-new-user-funner__form"
              >
                <label
                  htmlFor="add-manually-new-user-funner__first-name"
                  className="form-get-token-popup__input-wrapper"
                >
                  <span className="input-title">
                    {localization.manually.firstNameLabel}
                  </span>
                  <input
                    type="text"
                    id="add-manually-new-user-funner__first-name"
                    name="add-manually-new-user-funner__first-name"
                    className="form-get-token-popup__input"
                    onInput={(e) => {
                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        validateTextInput(e.target, 1);
                      }
                    }}
                  />
                </label>
                <label
                  htmlFor="add-manually-new-user-funner__last-name"
                  className="form-get-token-popup__input-wrapper"
                >
                  <span className="input-title">
                    {localization.manually.lastNameLabel}
                  </span>
                  <input
                    type="text"
                    id="add-manually-new-user-funner__last-name"
                    name="add-manually-new-user-funner__last-name"
                    className="form-get-token-popup__input"
                    onInput={(e) => {
                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        validateTextInput(e.target, 1);
                      }
                    }}
                  />
                </label>
                <label
                  htmlFor="add-manually-new-user-funner__phone"
                  className="form-get-token-popup__input-wrapper"
                >
                  <span className="input-title">
                    {localization.manually.phoneLabel}
                  </span>
                  <input
                    type="tel"
                    id="add-manually-new-user-funner__phone"
                    name="add-manually-new-user-funner__phone"
                    className="form-get-token-popup__input"
                    value={userData.phoneNumber}
                    onChange={(e) => {
                      handleUserData({
                        phoneNumber: e.target.value.replace(/\D/g, ""),
                      });
                      if (
                        e.target
                          .closest("label")
                          .classList.contains("input-error")
                      ) {
                        validateFullPhoneNumber(e.target);
                      }
                    }}
                    // onInput={(e) => {
                    //   if (
                    //     e.target
                    //       .closest("label")
                    //       .classList.contains("input-error")
                    //   ) {
                    //     validateFullPhoneNumber(e.target);
                    //   }
                    //   // inputMaxCount(e.target, 10);
                    // }}
                  />
                </label>
                <div className="form-get-token-popup__gray-check">
                  <input
                    onChange={(e) => setIsValidateUserPhone(e.target.checked)}
                    name="add-manually-new-user-funner__is-validate"
                    id="add-manually-new-user-funner__is-validate"
                    type="checkbox"
                    checked={isValidateUserPhone}
                    className="confirm-policy-checkbox"
                  />
                  <label
                    htmlFor="add-manually-new-user-funner__is-validate"
                    className={`form-get-token-popup__checkbox`}
                  >
                    <img src={greenCheck} className="valid-icon" alt="" />
                    <span className="_check-text">מספר נייד ישראלי</span>
                  </label>
                </div>

                {/* <ReactSelect
                    options={phoneNumbersByCountries.countriesList.map(
                      (item) => {
                        return { value: item.countryCode, label: item.country };
                      },
                    )}
                    isClearable={false}
                    isSearchable={true}
                    isRtl={true}
                    value={{
                      value: selectedPhoneNumberType.countryCode,
                      label: selectedPhoneNumberType.country,
                    }}
                    onChange={
                      (e) => handlePhoneNumberType(e)
                      // handleTaskParams("MassMessagingGroupId", e.value)
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: "rgba(0, 0, 0, 0.23)",
                        marginBottom: 15,
                      }),
                    }}
                  /> */}

                {/* <div className="form-get-token-popup__gray-check">
                  <input
                    onChange={handleManuallyFireberryCheck}
                    name="add-manually-new-user-funner__is-fireberry"
                    id="add-manually-new-user-funner__is-fireberry"
                    type="checkbox"
                    checked={isManuallyFireberryChecked}
                    className="confirm-policy-checkbox"
                  />
                  <label
                    htmlFor="add-manually-new-user-funner__is-fireberry"
                    className={`form-get-token-popup__checkbox`}
                  >
                    <img src={greenCheck} className="valid-icon" alt="" />
                    <span className="_check-text">
                      {localization.manually.isFireberryCheck}
                    </span>
                  </label>
                </div> */}

                <div className="form-get-token-popup__button-wrap">
                  <button
                    type="submit"
                    className={`get-token-popup__main-btn popup__blue-btn`}
                  >
                    {localization.manually.createBtnText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {addUserType === "by-file" && (
          <>
            {isXlsxLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  bgcolor: "rgba(255, 255, 255, 0.75)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 10,
                }}
              >
                <CircularProgress size={60} color="secondary" />
              </Box>
            )}
            {!isSendTemplate && (
              <div className="add-by-file-new-user-funner">
                <div className="add-by-file-new-user-funner__content">
                  {!isUsersFromFileSendToSave && (
                    <>
                      <label
                        className="add-by-file-new-user-funner__upload-file-btn popup__blue-btn"
                        htmlFor="add-by-file-new-user-funner__file"
                      >
                        <input
                          type="file"
                          name="file"
                          ref={fileInputRef}
                          onChange={handleUploadUsersFile}
                          id="add-by-file-new-user-funner__file"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                        {localization.fromFile.noTemplate.uploadBtnText}
                      </label>

                      <div className="add-by-file-new-user-funner__file-name">
                        {fileName}
                      </div>
                    </>
                  )}

                  {!usersFromFileList.length && (
                    <a
                      href="https://docs.google.com/spreadsheets/d/1tSEOP82YO7CxIMGrJ3Nu_6efiGyIo2VJ/edit#gid=1788392530"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {localization.fromFile.noTemplate.xlsxExampleFile}
                    </a>
                  )}

                  {usersFromFileList && usersFromFileList.length > 0 && (
                    <>
                      <div className="add-by-file-new-user-funner__list-wrapper">
                        <div className="add-by-file-new-user-funner__list-tooltip">
                          <svg
                            role="presentation"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 300"
                            height={42}
                            width={42}
                          >
                            <rect
                              className="tooltip-horizontal-scroll-icon_card"
                              x={480}
                              width={200}
                              height={200}
                              rx={5}
                              fill="rgba(190,190,190,0.3)"
                            />
                            <rect
                              className="tooltip-horizontal-scroll-icon_card"
                              y={0}
                              width={200}
                              height={200}
                              rx={5}
                              fill="rgba(190,190,190,0.3)"
                            />
                            <rect
                              className="tooltip-horizontal-scroll-icon_card"
                              x={240}
                              width={200}
                              height={200}
                              rx={5}
                              fill="rgba(190,190,190,0.3)"
                            />
                            <path
                              className="tooltip-horizontal-scroll-icon_hand"
                              d="M78.9579 285.7C78.9579 285.7 37.8579 212.5 20.5579 180.8C-2.44209 138.6 -6.2422 120.8 9.6579 112C19.5579 106.5 33.2579 108.8 41.6579 123.4L61.2579 154.6V32.3C61.2579 32.3 60.0579 0 83.0579 0C107.558 0 105.458 32.3 105.458 32.3V91.7C105.458 91.7 118.358 82.4 133.458 86.6C141.158 88.7 150.158 92.4 154.958 104.6C154.958 104.6 185.658 89.7 200.958 121.4C200.958 121.4 236.358 114.4 236.358 151.1C236.358 187.8 192.158 285.7 192.158 285.7H78.9579Z"
                              fill="rgba(190,190,190,1)"
                            />
                            <style
                              dangerouslySetInnerHTML={{
                                __html:
                                  ".tooltip-horizontal-scroll-icon_hand {\nanimation: tooltip-horizontal-scroll-icon_anim-scroll-hand 2s infinite\n}\n.tooltip-horizontal-scroll-icon_card {\nanimation: tooltip-horizontal-scroll-icon_anim-scroll-card 2s infinite\n}\n@keyframes tooltip-horizontal-scroll-icon_anim-scroll-hand {\n0% { transform: translateX(80px) scale(1); opacity: 0 }\n10% { transform: translateX(80px) scale(1); opacity: 1 }\n20%,60% { transform: translateX(175px) scale(.6); opacity: 1 }\n80% { transform: translateX(5px) scale(.6); opacity: 1 } to { transform: translateX(5px) scale(.6); opacity: 0 }\n}\n@keyframes tooltip-horizontal-scroll-icon_anim-scroll-card {\n0%,60% { transform: translateX(0) }\n80%,to { transform: translateX(-240px) }\n}",
                              }}
                            />
                          </svg>
                        </div>

                        <div className="add-by-file-new-user-funner__list">
                          <div className="add-by-file-new-user-funner__list-header">
                            <div className="add-by-file-new-user-funner__list-row">
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  N/o
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  Status
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  First Name
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  Last Name
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  Fullname
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  Phone number
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  Email
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  Fireberry Account Id
                                </div>
                              </div>
                              <div className="add-by-file-new-user-funner__list-item">
                                <div className="add-by-file-new-user-funner__list-item-text">
                                  Fireberry Contact Id
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="add-by-file-new-user-funner__list-body">
                            {usersFromFileList.map((item, index) => (
                              <div className="add-by-file-new-user-funner__list-row">
                                <div className="add-by-file-new-user-funner__list-item">
                                  <div className="add-by-file-new-user-funner__list-item-text">
                                    {index + 1}
                                  </div>
                                </div>
                                <div className="add-by-file-new-user-funner__list-item">
                                  <ul className="add-by-file-new-user-funner__errors-list">
                                    {isUsersFromFileSendToSave && (
                                      <>
                                        {item.created ? (
                                          <li className="_accept _create">
                                            Created
                                          </li>
                                        ) : (
                                          <>
                                            <li className="_err _create">
                                              Didn't created
                                            </li>
                                            {item.errors
                                              .split(" - ")
                                              .slice(1)
                                              .map((error, index) => (
                                                <li
                                                  className="_err"
                                                  key={index}
                                                >
                                                  {error}
                                                </li>
                                              ))}
                                          </>
                                        )}
                                      </>
                                    )}
                                    {!isUsersFromFileSendToSave && (
                                      <>
                                        {!item.valid ? (
                                          item.errors
                                            .split(" - ")
                                            .slice(1)
                                            .map((error, index) => (
                                              <li className="_err" key={index}>
                                                {error}
                                              </li>
                                            ))
                                        ) : (
                                          <li className="_accept">
                                            All is Ok!
                                          </li>
                                        )}
                                      </>
                                    )}
                                  </ul>
                                </div>
                                <div className="add-by-file-new-user-funner__list-item">
                                  <div className="add-by-file-new-user-funner__list-item-text">
                                    {item.firstName}
                                  </div>
                                </div>
                                <div className="add-by-file-new-user-funner__list-item">
                                  <div className="add-by-file-new-user-funner__list-item-text">
                                    {item.lastName}
                                  </div>
                                </div>
                                <div className="add-by-file-new-user-funner__list-item">
                                  <div className="add-by-file-new-user-funner__list-item-text">
                                    {item.fullName}
                                  </div>
                                </div>
                                <div className="add-by-file-new-user-funner__list-item">
                                  <div className="add-by-file-new-user-funner__list-item-text">
                                    {item.phone}
                                  </div>
                                </div>
                                <div className="add-by-file-new-user-funner__list-item">
                                  <div className="add-by-file-new-user-funner__list-item-text">
                                    {item.email}
                                  </div>
                                </div>
                                <div className="add-by-file-new-user-funner__list-item">
                                  <div className="add-by-file-new-user-funner__list-item-text">
                                    {item.fireberryAccountId}
                                  </div>
                                </div>
                                <div className="add-by-file-new-user-funner__list-item">
                                  <div className="add-by-file-new-user-funner__list-item-text">
                                    {item.fireberryContactId}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      {!isUsersFromFileSendToSave && (
                        <>
                          <div className="form-get-token-popup__gray-check">
                            <input
                              onChange={handleByFileFireberryCheck}
                              name="add-by-file-new-user-funner__is-fireberry"
                              id="add-by-file-new-user-funner__is-fireberry"
                              type="checkbox"
                              checked={isByFileFireberryChecked}
                              className="confirm-policy-checkbox"
                            />
                            <label
                              htmlFor="add-by-file-new-user-funner__is-fireberry"
                              className={`form-get-token-popup__checkbox`}
                            >
                              <img
                                src={greenCheck}
                                className="valid-icon"
                                alt=""
                              />
                              <span className="_check-text">
                                {
                                  localization.fromFile.noTemplate
                                    .isFireBerryCheck
                                }
                              </span>
                            </label>
                          </div>

                          <button
                            type="button"
                            onClick={saveCurrentResult}
                            className="add-by-file-new-user-funner__save-btn popup__blue-btn"
                          >
                            {localization.fromFile.noTemplate.saveResultBtn}
                          </button>
                        </>
                      )}
                      {isUsersFromFileSendToSave && (
                        <div
                          className="popup__blue-btn"
                          style={{ maxWidth: "max-content" }}
                          onClick={() => setShowUsersCreateChat(false)}
                        >
                          {localization.fromFile.noTemplate.allIsDoneBtn}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {isSendTemplate && (
              <>
                {taskCreateUpdateStep === "taskInfo" && (
                  <>
                    <div
                      className="create-new-task-messaging__input-wrapper"
                      style={{
                        maxWidth: 400,
                        marginBottom: 10,
                        marginTop: 30,
                      }}
                    >
                      <p className="main-popup-content-title">
                        {localization.fromFile.withTemplate.taskNameLabel}
                      </p>
                      <input
                        type="text"
                        className="admin-tab__search main-search"
                        value={selectedTask.Name}
                        onChange={(e) =>
                          handleTaskParams("Name", e.target.value)
                        }
                        style={{ borderRadius: 4 }}
                      />
                    </div>

                    <div
                      className="create-new-task-messaging__buttons-wrapper"
                      style={{ display: "flex", gap: 10, marginTop: 16 }}
                    >
                      {selectedTask.Name && selectedTask.Name !== "" && (
                        <button
                          type="button"
                          className="popup__blue-btn"
                          onClick={() => setTaskCreateUpdateStep("targetUsers")}
                          style={{ margin: 0 }}
                        >
                          {localization.fromFile.withTemplate.goNextBtnText}
                        </button>
                      )}
                    </div>
                  </>
                )}
                {taskCreateUpdateStep === "targetUsers" && (
                  <>
                    <AddUsersFromFile
                      handleSetNotif={handleSetNotif}
                      parentHandleUploadFunction={handleUploadFileTemplate}
                      buttonRight={true}
                      style={{
                        position: "relative",
                        top: 0,
                        right: 0,
                        marginTop: 30,
                      }}
                      contentStyle={{ paddingRight: 0 }}
                    />

                    <div
                      className="create-new-task-messaging__buttons-wrapper"
                      style={{ display: "flex", gap: 10, marginTop: 25 }}
                    >
                      {selectedTask.filePath && (
                        <button
                          type="button"
                          className="popup__blue-btn"
                          onClick={() =>
                            setTaskCreateUpdateStep("taskTemplate")
                          }
                          style={{ margin: 0 }}
                        >
                          {localization.fromFile.withTemplate.goNextBtnText}
                        </button>
                      )}

                      <button
                        type="button"
                        className="popup__blue-btn"
                        onClick={() => setTaskCreateUpdateStep("taskInfo")}
                        style={{ margin: 0 }}
                      >
                        {localization.fromFile.withTemplate.goBackBtnText}
                      </button>
                    </div>
                  </>
                )}
                {taskCreateUpdateStep === "taskTemplate" && (
                  <>
                    <div
                      className="sticky-table-header"
                      style={{
                        zIndex: 100000,
                        top: -60,
                        width: "calc(100% + 40px)",
                        margin: "0 -20px",
                        paddingLeft: 20,
                        paddingRight: 20,
                      }}
                    >
                      <div
                        className="create-new-task-messaging__buttons-wrapper"
                        style={{
                          display: "flex",
                          gap: 10,
                          maxWidth: 400,
                          marginBottom: 10,
                        }}
                      >
                        <button
                          type="button"
                          className="popup__blue-btn"
                          onClick={submitNewTask}
                          style={{ margin: 0 }}
                        >
                          {localization.fromFile.withTemplate.submitBtnText}
                        </button>
                        <button
                          type="button"
                          className="popup__blue-btn"
                          onClick={() => setTaskCreateUpdateStep("targetUsers")}
                          style={{ margin: 0 }}
                        >
                          {localization.fromFile.withTemplate.goBackBtnText}
                        </button>
                      </div>
                    </div>

                    <div className="create-new-task-messaging__variables">
                      <div
                        className="create-new-task-messaging__variables-header"
                        onClick={toggleShowVars}
                      >
                        <div className="create-new-task-messaging__variables-header-text">
                          {
                            localization.fromFile.withTemplate
                              .implementedVarsTitle
                          }
                        </div>
                        <div className="create-new-task-messaging__variables-header-plus"></div>
                      </div>
                      {showVars && implementedVars?.length && (
                        <div className="create-new-task-messaging__variables-list">
                          {implementedVars.map((variable, index) => (
                            <div
                              className="create-new-task-messaging__variable"
                              key={variable.varName}
                            >
                              {variable.varName}
                              <Tippy
                                content={<p>{variable.tooltipContent}</p>}
                                {...tippyPrefs}
                              >
                                <div className="create-new-task-messaging__variable-tooltip">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width={100}
                                    height={100}
                                    viewBox="0 0 50 50"
                                  >
                                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                                  </svg>
                                </div>
                              </Tippy>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <div
                      className="create-new-task-messaging__choose-template chat-accordion"
                      style={{ paddingTop: 16 }}
                    >
                      <TemplatesMenu
                        templatesLoading={false}
                        templatesList={templatesList.templates}
                        whatsappTemplatesList={templatesList.whatsappTemplates}
                        languages={templatesList.languages}
                        closeAccordion={() => {}}
                        chatUserName={""}
                        currentContact={""}
                        globalID={globalID}
                        handleSetNotif={handleSetNotif}
                        setTextareaValue={() => {}}
                        textareaRef={null}
                        resetTextArea={() => {}}
                        templatesWithSend={false}
                        noSendHandleFunc={(templatesObj) => {
                          templatesObjectRef.current = templatesObj.sendObject;
                        }}
                        fetchWhatsappTemplates={fetchWhatsappTemplates}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        {addUserType === "selectTemplate" && (
          <div
            className="add-new-user-funner__choose-template chat-accordion"
            style={{
              paddingTop: 25,
              display: "flex",
              flexDirection: "column",
              maxHeight: "100%",
            }}
          >
            <p className="main-popup-content-title" style={{ marginBottom: 8 }}>
              Select template
            </p>
            <div
              className="popup__blue-btn"
              ref={(el) => {
                el && el.style.setProperty("margin", "8px 0", "important");
              }}
              onClick={sendTemplateSingleContact}
            >
              Send
            </div>

            <TemplatesMenu
              templatesLoading={false}
              templatesList={templatesList.templates}
              whatsappTemplatesList={templatesList.whatsappTemplates}
              languages={templatesList.languages}
              closeAccordion={() => {}}
              chatUserName={""}
              currentContact={""}
              globalID={globalID}
              handleSetNotif={handleSetNotif}
              setTextareaValue={() => {}}
              textareaRef={null}
              resetTextArea={() => {}}
              templatesWithSend={false}
              noSendHandleFunc={(templatesObj) => {
                templatesObjectRef.current = templatesObj.sendObject;
              }}
              fetchWhatsappTemplates={fetchWhatsappTemplates}
            />
          </div>
        )}
      </div>
      {/* </div> */}
    </>
  );

  // return <div className="add-users-from-chat">
  //   <div className="add-users-from-chat__close" onClick={() => setShowUsersCreateChat(false)}></div>
  //   <div className="add-users-from-chat__content">

  //   </div>
  // </div>;
}

export default AddUsersChat;
